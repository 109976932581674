<template>
  <div>
    <div
      class="modal fade"
      id="StockConsumptionDetailsModal"
      tabindex="-1"
      aria-labelledby="StockConsumptionDetailsModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-fullscreen">
        <div class="modal-content d-flex flex-column">
          <div class="modal-header d-flex justify-content-between">
            <h5
              class="modal-title text-decoration-underline"
              id="StockConsumptionDetailsModalLabel"
              style="visibility: hidden"
            >
              Goods Received Note
            </h5>
            <div class="d-flex align-content-center">
              <!-- <img
                src="../../assets/icons/pinkDownold.png"
                data-toggle="tooltip"
                data-placement="top-start"
                title="downold"
                alt=""
                style="width: 23px; height: 23px; cursor: pointer; padding-bottom: 3px"
                @click="downloadPDF"
              /> -->

              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                data-toggle="tooltip"
                data-placement="top-start"
                title="close"
              ></button>
            </div>
          </div>
          <div
            class="modal-body d-flex flex-column StockConsumptionDetailsModal"
            ref="printContent"
          >
            <div class="content-section flex-grow-1">
              <p
                class="text-center text-decoration-underline"
                style="font-size: 18px; font-weight: 600"
              >
                Stock Consumption Entry Note
              </p>
              <section style="margin-top: 20px">
                <div class="table-responsive GRNHeaderTable overflow-hidden">
                  <table class="table" style="border: none">
                    <caption class="d-none">
                      Grn Header
                    </caption>
                    <thead class="d-none">
                      <tr>
                        <th id="voucherNumberHeader" scope="col">Voucher Number</th>
                        <th id="dateHeader" scope="col">Date</th>
                      </tr>
                      <tr>
                        <th id="createdByHeader" scope="col">Created By</th>
                        <th id="departmentHeader" scope="col">Department</th>
                      </tr>
                      <tr>
                        <th id="fromStoreHeader" scope="col">From Store</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr class="row">
                        <td class="cell">
                          <div class="label-value-pair">
                            <span class="label">Voucher Number:</span>
                            <span class="value">{{ GRN.stockNumber }}</span>
                          </div>
                        </td>
                        <td class="cell">
                          <div class="label-value-pair">
                            <span class="label">Date:</span>
                            <span class="value">{{ this.$formatDateDMY(GRN.date) }}</span>
                          </div>
                        </td>
                      </tr>
                      <tr class="row">
                        <td class="cell">
                          <div class="label-value-pair">
                            <span class="label">Created By:</span>
                            <span class="value">{{ GRN.firstName }}</span>
                          </div>
                        </td>
                        <td class="cell">
                          <div class="label-value-pair">
                            <span class="label">Department:</span>
                            <span class="value">Onsite</span>
                          </div>
                        </td>
                      </tr>
                      <tr class="row">
                        <td class="cell">
                          <div class="label-value-pair">
                            <span class="label">From Store:</span>
                            <span class="value">{{ GRN.projectName }}</span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="table-responsive p-2">
                  <table class="table table-bordered">
                    <caption class="d-none">
                      Product details
                    </caption>
                    <thead>
                      <tr>
                        <th>Sl.No</th>
                        <th>Product Name(Description)</th>
                        <th>Qty</th>
                        <th>Unit Type</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in lineItems" :key="item.id">
                        <td>{{ index + 1 }}</td>
                        <td>{{ item.productName }} ({{ item.description }})</td>

                        <td class="text-end">{{ item.quantity }}</td>
                        <td class="text-center">{{ item.unitType }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </div>
            <div class="footer-section d-flex justify-content-between mt-auto">
              <span class="text-start">Receiver Signature</span>
              <span class="text-end">Department Signature</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import html2pdf from "html2pdf.js";

export default {
  name: "StockConsumptionDetailsModal",
  props: {
    StockNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      CompanyLogo: null,
      lineItems: [],
      GRN: {},
      CompanyData: {},
      isDataFetched: false, // Flag to indicate if data fetching is complete
    };
  },
  watch: {
    StockNumber(newVal) {
      this.fetchGRNById(newVal);
      console.log(newVal);
    },
  },

  methods: {
    fetchCompanyLogo(CompanyId) {
      const PayLoad = {
        entityId: CompanyId,
        fileCategory: "logo",
        moduleName: "company",
      };

      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
          this.CompanyLogo = Object.values(response.data)[0];
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    fetchCompanyData() {
      const companyId = this.$LocalUser.user.companyId;
      axios
        .get(`${this.$apiEndPoint}/company/${companyId}`)
        .then((response) => {
          this.CompanyData = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    async fetchGRNById(Id) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/stockConsumption/${Id}`);
        console.log(response.data);
        this.lineItems = response.data.productList;
        this.GRN = response.data;
        this.isDataFetched = true; // Set the flag to true after data is fetched
      } catch (error) {
        this.$handleError(error);
      }
    },
    downloadPDF() {
      if (!this.isDataFetched) {
        console.warn("Data not fetched yet. Please wait.");
        return;
      }

      // Delay the download slightly to ensure all rendering is complete
      setTimeout(() => {
        const contentToPrint = document.querySelector(".StockConsumptionDetailsModal");

        // Store the original styles before modification
        const originalWidth = contentToPrint.style.width;
        const originalMargin = contentToPrint.style.margin;
        const originalBorder = contentToPrint.style.border;
        const originalPadding = contentToPrint.style.padding;

        // Temporarily change the styles for PDF generation
        contentToPrint.style.width = "100%";
        contentToPrint.style.margin = "0%";
        contentToPrint.style.border = "none";
        contentToPrint.style.padding = "0%";

        const fileName = `StockConsumption_${this.GRN.stockNumber}.pdf`;

        html2pdf()
          .from(contentToPrint)
          .set({
            filename: fileName,
            margin: [10, 10, 10, 10], // top, left, bottom, right
            html2canvas: { scale: 2 },
            jsPDF: { format: "a4", orientation: "portrait" },
          })
          .save()
          .then(() => {
            // Restore the original styles after PDF generation
            contentToPrint.style.width = originalWidth;
            contentToPrint.style.margin = originalMargin;
            contentToPrint.style.border = originalBorder;
            contentToPrint.style.padding = originalPadding;
          });
      }, 0);
    },
  },
};
</script>

<style scoped>
.modal-content {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.modal-fullscreen {
  margin-left: 0%;
  width: 100%;
}
.modal-body {
  width: 60%;
  margin: 0 20%;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  border: 1px solid #000000;
}

.content-section {
  flex-grow: 1;
}

.table-bordered th {
  background-color: #ccc;
}
.table-bordered {
  border: 1px solid #000000 !important;
}

pre {
  display: block;
  margin: 10px;
  text-align: left;
  width: 80%;
  min-height: 100px;
  max-height: auto;
  border: 1px solid #000;
  border-radius: 10px;
  padding: 10px;
  word-break: break-all;
  white-space: pre-wrap;
}

#companylogoInput {
  height: 100px;
  width: auto;
  border: 1px solid #ccc;
  padding: 5px;
  border-radius: 5px;
  max-width: 250px;
}

.mt-auto {
  margin-top: auto;
}

.footer-section {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  margin-top: auto; /* Pushes the footer to the bottom */
  width: 100%;
  box-sizing: border-box;
  border-top: 1px solid #ccc; /* Optional: to create separation from content */
}
.cell {
  flex: 1;
  padding: 5px;
  margin-left: 20px;
  border: none !important;
}

.label-value-pair {
  display: flex;
  align-items: center;
}

.label {
  font-weight: bold;
  min-width: 150px; /* Adjust as needed */
}

.value {
  flex: 1;
  padding-left: 5px;
}

@media screen and (max-width: 768px) {
  .modal-fullscreen {
    margin-left: 0%;
    width: 100%;
  }
  .modal-content {
    width: 100%;
  }
  .modal-dialog {
    width: 100%;
  }
  .modal-body {
    padding: 0px;
  }
}
</style>
