<template>
  <div>
    <div
      class="modal fade"
      id="pettyCashReportModal"
      tabindex="-1"
      aria-labelledby="pettyCashReportModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="pettyCashReportModalLabel">Report Data</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitReport">
              <div class="mb-3">
                <label for="fromDate" class="form-label">From Date</label>
                <input
                  type="date"
                  v-model="fromDate"
                  class="form-control"
                  id="fromDate"
                  required
                />
              </div>
              <div class="mb-3">
                <label for="toDate" class="form-label">To Date</label>
                <input
                  type="date"
                  v-model="toDate"
                  class="form-control"
                  id="toDate"
                  required
                />
              </div>
            </form>
            <div
              class="d-flex justify-content-center align-items-center"
              style="padding-left: 50%"
            >
              <button type="submit" class="btn btn-primary">Submit</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "PettyCashReportModal",
  data() {
    return {
      fromDate: "",
      toDate: "",
    };
  },
  methods: {
    submitReport() {
      const reportData = {
        fromDate: this.fromDate,
        toDate: this.toDate,
      };
      axios
        .post(`${this.$apiEndPoint}/pettyCash/pettyCashReport`, reportData)
        .then((response) => {
          console.log(response.data);
          // this.$emit("fetchData");
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
  },
};
</script>

<style scoped>
/* Add any additional styling if needed */
</style>
