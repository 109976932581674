<template>
  <LeadFilterModal
    @apply-filters="fetchFilterData"
    :filterOptions="['Source', 'Status', 'Priority', 'Follow-up Date']"
  />
  <LeadDetailsModal
    :defaultSourceId="sourceIdFromParentPage"
    :selectedLeadId="selectedLeadId"
    @fetchData="fetchData()"
  />
  <Header />
  <div class="content-Container">
    <Nav />
    <div class="containerChild">
      <div class="FormatTable" v-if="showUnclaimedList">
        <div class="row headerOfTable d-flex">
          <div class="col-md-12 d-flex align-content-center p-1">
            <div class="col-md-6 d-flex justify-content-between ms-3">
              <div class="button-container" style="display: flex; gap: 20px">
                <button
                  type="button"
                  class="btn btn-sm btn-outline-primary"
                  style="display: flex; align-items: center"
                  @click="toggleListviewList()"
                >
                  <img
                    src="../../../assets/icons/clarity_clipboard-outline-badged.png"
                    alt="Share"
                    class="button-icon"
                  />
                  List View
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-outline-secondary"
                  style="display: flex; align-items: center"
                  @click="toggleBoardviewList()"
                >
                  <img
                    src="../../../assets/icons/clarity_clipboard-outline-badged.png"
                    alt="Delete"
                    class="button-icon"
                  />
                  Board View
                </button>
              </div>

              <div class="d-flex align-items-center" v-if="$isManager('Sales')">
                <label
                  for="input1"
                  class="form-label text-muted text-nowrap mt-1"
                  style="font-size: 14px"
                >
                  Report:
                </label>
                <select
                  class="form-select form-control ms-2"
                  v-model="selectedEmployeeId"
                  @change="setSelectedEmployee"
                  style="
                    cursor: pointer;
                    font-size: 12px;
                    height: 29px;
                    min-width: 150px;
                    width: fit-content;
                  "
                >
                  <option value="All">Team</option>
                  <option
                    v-for="employee in employees"
                    :key="employee['employees.id']"
                    :value="employee['employees.id']"
                  >
                    {{ employee["employees.firstName"] }}
                    {{ employee["employees.lastName"] }}
                  </option>
                </select>
              </div>

              <div class="col-md-2" style="visibility: hidden">
                <img
                  src="../../../assets/icons/blueAddicon.png"
                  alt=""
                  class="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                  data-bs-whatever="@mdo"
                  id="addicon"
                />
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <div class="col-md-3 ImportExport text-nowrap" style="visibility: hidden">
                <div class="row">
                  <div class="col-md-7 tableTextEvenRow">Import</div>
                </div>
              </div>
              <div class="col-md-3 ImportExport"></div>

              <div class="col-md-1">
                <img
                  src="../../../assets/icons/archive.png"
                  alt=""
                  id="ArchiveIcon"
                  @click="handleArchiveClick('UnClaimList')"
                />
              </div>
              <div class="col-md-4 searchboxOfTable">
                <div class="search-box">
                  <img
                    src="../../../assets/icons/search.png"
                    alt="Search Icon"
                    id="SerchIconOfTable"
                  />
                  <input
                    type="text"
                    name="search"
                    id=""
                    v-model="searchString"
                    @input="searchLeads"
                    v-if="leadView === 'myLeads'"
                  />
                  <input
                    type="text"
                    name="search"
                    id=""
                    v-model="searchString"
                    @input="searchAllLeads"
                    v-else
                  />
                </div>
                <span>
                  <img
                    src="../../../assets/icons/filter.png"
                    alt="Search Icon"
                    id="FilterIconOfTable"
                    data-bs-toggle="modal"
                    data-bs-target="#LeadFilterModal"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="table-wrapper StandardScroller">
          <table class="tableFormat">
            <caption class="d-none">
              Lead unArchive Table
            </caption>
            <thead>
              <tr>
                <th class="">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectAll"
                      @change="selectAllRows"
                    />
                  </div>
                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu ms-3 text-black p-1"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="ArchiveLeads('Archive')">Archive</p>
                    <p @click="deleteLead('ArchiveDelete')">Delete</p>
                  </div>
                </th>
                <th class="tableHeaderHeading">Name</th>
                <th class="tableHeaderHeading">Follow-Up Date</th>
                
                <th class="tableHeaderHeading">Contact No</th>
                <th class="tableHeaderHeading">Email ID</th>
                <th class="tableHeaderHeading">Property Address</th>
                <th>Source</th>
                <th class="tableHeaderHeading" v-if="leadView === 'allLeads'">
                  Claimed By
                </th>
              
                <th class="tableHeaderHeading">Status</th>
                <th class="tableHeaderHeading">Priority</th>
                <th class="tableHeaderHeading">Entry Date</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in displayedData" :key="index">
                <td class="bodyText">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectedCustomers"
                      :value="data.id"
                    />
                  </div>

                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu ms-3 text-black p-2"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="createEstimate(data)">Create Estimate</p>
                    <p
                      @click="ArchiveLead(data, index)"
                      @mouseenter="hideEmployeeDropdown('null')"
                    >
                      Archive
                    </p>

                    <p
                      @click="UnclaimLead(data, index)"
                      v-if="leadView === 'myLeads'"
                      @mouseenter="hideEmployeeDropdown('null')"
                    >
                      Unclaim
                    </p>
                    <p @mouseenter="showEmployeeDropdown(index)">Assigned To</p>
                    <section
                      v-if="activeDropdownIndex === index"
                      class="employee-dropdown card p-1"
                    >
                      <p
                        v-for="employee in employees"
                        :key="employee.employeeId"
                        @click="assignEmployee(data.id, employee.employeeId)"
                      >
                        {{ employee["employees.firstName"] }}
                        {{ employee["employees.lastName"] }}
                      </p>
                    </section>
                  </div>
                </td>

                <td
                  @click="openModal(data.id)"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                  data-bs-whatever="@mdo"
                >
                  {{ data.name }}
                </td>
                <td>
                  <input
                    type="date"
                    name=""
                    id="LeadFollowDate"
                    placeholder="Enter FollowUpdate"
                    class="w-75 border-0"
                    v-model="data.followUpdate"
                    @change="updateFollowUpDate(data.id, data.followUpdate)"
                    style="cursor: pointer"
                  />
                </td>
                <td  @click="openModal(data.id)"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
                data-bs-whatever="@mdo">{{ data.contactNo }}</td>
                <td  @click="openModal(data.id)"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
                data-bs-whatever="@mdo">{{ data.email }}</td>
                <td  @click="openModal(data.id)"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
                data-bs-whatever="@mdo">
                  {{
                    data["leadAddresses.address"]
                      ? data["leadAddresses.address"]
                      : data["addresses.address"]
                  }}
                </td>

                <td  @click="openModal(data.id)"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
                data-bs-whatever="@mdo">{{ data["sources.name"] }}</td>

                <td  @click="openModal(data.id)"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
                data-bs-whatever="@mdo" v-if="leadView === 'allLeads'">
                  {{ data["employees.firstName"] }} {{ data["employees.lastName"] }}
                </td>
               
              

                <td  @click="openModal(data.id)"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
                data-bs-whatever="@mdo">{{ data.status }}</td>
                <td  @click="openModal(data.id)"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
                data-bs-whatever="@mdo">
                  <span
                    :class="priorityButtonClass(data)"
                    class="btn btn-sm"
                    style="width: 100px"
                    >{{ data.priority }}</span
                  >
                </td>
                <td  @click="openModal(data.id)"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal2"
                data-bs-whatever="@mdo">{{ data.date }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paginationOfTable" style="width: calc(100% - 74px)">
          <div class="pageControlsTable">
            <img
              src="../../../assets/icons/blueLeftarrow.png"
              @click="goToPreviousPage"
              :disabled="!hasPreviousPage"
              alt=""
            />
            <div class="page-numbers">
              <span
                v-for="pageNumber in pagination"
                :key="pageNumber"
                :class="{
                  'page-number': true,
                  'current-page': pageNumber === currentPage,
                }"
                @click="goToPage(pageNumber)"
                >{{ pageNumber }}</span
              >
            </div>

            <img
              src="../../../assets/icons/blueRightarrow.png"
              @click="goToNextPage"
              :disabled="!hasNextPage"
              alt=""
            />
          </div>

          <div class="entries-info">
            <span class="entries-text">Entries: {{ displayedData.length }} Rows</span>
          </div>
        </div>
      </div>
      <div class="FormatTable" v-if="showArchiveList">
        <div class="row headerOfTable d-flex">
          <div class="col-md-12 d-flex align-content-center">
            <div class="col-md-6 d-flex">
              <div
                class="col-md-3 ms-2 text-nowrap subHeadingHeader tableTextEvenRow p-1"
              >
                <h5>Archived Leads</h5>
              </div>
              <div class="col-md-1 m-1">
                <img
                  src="../../../assets/icons/unarchive.png"
                  alt=""
                  id="ArchiveIcon"
                  @click="handleArchiveClick('ArchiveList')"
                />
              </div>
              <div class="d-flex align-items-center" v-if="$isManager('Sales')">
                <label
                  for="input1"
                  class="form-label text-muted text-nowrap mt-1"
                  style="font-size: 14px"
                >
                  Report:
                </label>
                <select
                  class="form-select form-control ms-2"
                  v-model="selectedEmployeeId"
                  @change="setSelectedEmployee"
                  style="
                    cursor: pointer;
                    font-size: 12px;
                    height: 29px;
                    min-width: 150px;
                    width: fit-content;
                  "
                >
                  <option value="All">Team</option>
                  <option
                    v-for="employee in employees"
                    :key="employee['employees.id']"
                    :value="employee['employees.id']"
                  >
                    {{ employee["employees.firstName"] }}
                    {{ employee["employees.lastName"] }}
                  </option>
                </select>
              </div>
              <div class="col-md-2" style="visibility: hidden">
                <img
                  src="../../../assets/icons/blueAddicon.png"
                  alt=""
                  class="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                  data-bs-whatever="@mdo"
                  id="addicon"
                />
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <div class="col-md-3 ImportExport">
                <div class="row" style="visibility: hidden">
                  <div class="col-md-4 tableTextEvenRow">Import</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/import.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>
              <div class="col-md-3 ImportExport" style="visibility: hidden">
                <div class="row" style="cursor: pointer">
                  <div class="col-md-4 tableTextEvenRow" @click="exportToCSV">Export</div>
                  <div class="col-md-3 tableTextEvenRow">
                    <img src="../../../assets/icons/export.png" alt="" id="ImportIcon" />
                  </div>
                </div>
              </div>

              <div class="col-md-1" style="visibility: hidden">
                <img
                  src="../../../assets/icons/archive.png"
                  alt=""
                  id="ArchiveIcon"
                  @click="handleArchiveClick('ArchiveList')"
                />
              </div>
              <div class="col-md-4 searchboxOfTable">
                <div class="search-box">
                  <img
                    src="../../../assets/icons/search.png"
                    alt="Search Icon"
                    id="SerchIconOfTable"
                  />
                  <input
                    type="text"
                    name="search"
                    id=""
                    v-model="searchString"
                    @input="searchArchiveLeads"
                    v-if="leadView === 'myLeads'"
                  />
                  <input
                    type="text"
                    name="search"
                    id=""
                    v-model="searchString"
                    @input="searchAllArchiveLeads"
                    v-else
                  />
                </div>
                <span>
                  <img
                    src="../../../assets/icons/filter.png"
                    alt="Search Icon"
                    id="FilterIconOfTable"
                    data-bs-toggle="modal"
                    data-bs-target="#LeadFilterModal"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="table-wrapper StandardScroller">
          <table class="tableFormat">
            <caption class="d-none">
              Archive Lead table
            </caption>
            <thead>
              <tr>
                <th class="">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectAll"
                      @change="selectAllArchiveRows"
                    />
                  </div>
                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu ms-3 text-black p-2"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="UnclaimLeads('UnArchive')">Unclaim</p>
                    <p>Export</p>
                    <p @click="ArchiveLeads('UnArchive')">UnArchive</p>
                    <p @click="deleteLead('UnArchiveDelete')">Delete</p>
                  </div>
                </th>
                <th class="tableHeaderHeading">Name</th>
                <th class="tableHeaderHeading">Date</th>
                <th class="tableHeaderHeading">Contact No</th>
                <th class="tableHeaderHeading">Email ID</th>
                <th class="tableHeaderHeading">Property Address</th>
                <th>Source</th>
                <th class="tableHeaderHeading" v-if="leadView === 'allLeads'">
                  Claimed By
                </th>

                <th class="tableHeaderHeading">Status</th>
                <th class="tableHeaderHeading">Priority</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data, index) in displayedArchiveData" :key="index">
                <td class="bodyText">
                  <div class="custom-checkbox">
                    <input
                      type="checkbox"
                      id="checkbox1"
                      v-model="selectedCustomers"
                      :value="data.id"
                    />
                  </div>
                  <span
                    id="btnGroupDrop1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <img
                      src="../../../assets/icons/threeDot.jpg"
                      alt=""
                      class="dropdown-toggle"
                      aria-expanded="false"
                      id="blackThreeDots"
                    />
                  </span>
                  <div
                    class="dropdown-menu ms-3 text-black p-2"
                    aria-labelledby="btnGroupDrop1"
                  >
                    <p @click="UnclaimLead(data, index)" v-if="leadView === 'myLeads'">
                      Unclaim
                    </p>

                    <p>Export</p>
                    <p @click="ArchiveLead(data, index)">UnArchive</p>
                    <p @click="DeleteLead(data, index)">Delete</p>
                  </div>
                </td>
                <td
                  @click="openModal(data.id)"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal2"
                  data-bs-whatever="@mdo"
                >
                  {{ data.name }}
                </td>
                <td>{{ data.date }}</td>
                <td>{{ data.contactNo }}</td>
                <td>{{ data.email }}</td>
                <td>
                  {{
                    data["leadAddresses.address"]
                      ? data["leadAddresses.address"]
                      : data["addresses.address"]
                  }}
                </td>
                <td>{{ data["sources.name"] }}</td>

                <td v-if="leadView === 'allLeads'">
                  {{ data["employees.firstName"] }} {{ data["employees.lastName"] }}
                </td>

                <td>{{ data.status }}</td>
                <td>
                  <span
                    :class="priorityButtonClass(data)"
                    class="btn btn-sm"
                    style="width: 100px"
                    >{{ data.priority }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="paginationOfTable">
          <div class="pageControlsTable">
            <img
              src="../../../assets/icons/blueLeftarrow.png"
              @click="goToArchivePreviousPage"
              :disabled="!hasArchivePreviousPage"
              alt=""
            />
            <div class="page-numbers">
              <span
                v-for="pageNumber in paginationArchive"
                :key="pageNumber"
                :class="{
                  'page-number': true,
                  'current-page': pageNumber === currentPage,
                }"
                @click="goToPage(pageNumber)"
                >{{ pageNumber }}</span
              >
            </div>

            <img
              src="../../../assets/icons/blueRightarrow.png"
              @click="goToArchiveNextPage"
              :disabled="!hasArchiveNextPage"
              alt=""
            />
          </div>

          <div class="entries-info">
            <span class="entries-text"
              >Entries: {{ displayedArchiveData.length }} Rows</span
            >
          </div>
        </div>
      </div>
      <div class="FormatTable" v-if="showBoardview">
        <div class="row headerOfTable d-flex">
          <div class="col-md-12 d-flex align-content-center p-1">
            <div class="col-md-6 d-flex justify-content-between ms-3">
              <div class="button-container" style="display: flex; gap: 20px">
                <button
                  type="button"
                  class="btn btn-sm btn-outline-secondary"
                  style="display: flex; align-items: center"
                  @click="toggleListviewList()"
                >
                  <img
                    src="../../../assets/icons/clarity_clipboard-outline-badged.png"
                    alt="Share"
                    class="button-icon"
                  />
                  List View
                </button>
                <button
                  type="button"
                  class="btn btn-sm btn-outline-primary"
                  style="display: flex; align-items: center"
                  @click="toggleBoardviewList()"
                >
                  <img
                    src="../../../assets/icons/clarity_clipboard-outline-badged.png"
                    alt="Delete"
                    class="button-icon"
                  />
                  Board View
                </button>
              </div>

              <div class="d-flex align-items-center" v-if="$isManager('Sales')">
                <label
                  for="input1"
                  class="form-label text-muted text-nowrap mt-1"
                  style="font-size: 14px"
                >
                  Report:
                </label>
                <select
                  class="form-select form-control ms-2"
                  v-model="selectedEmployeeId"
                  @change="setSelectedEmployee"
                  style="
                    cursor: pointer;
                    font-size: 12px;
                    height: 29px;
                    min-width: 150px;
                    width: fit-content;
                  "
                >
                  <option value="All">Team</option>
                  <option
                    v-for="employee in employees"
                    :key="employee['employees.id']"
                    :value="employee['employees.id']"
                  >
                    {{ employee["employees.firstName"] }}
                    {{ employee["employees.lastName"] }}
                  </option>
                </select>
              </div>

              <div class="col-md-2" style="visibility: hidden">
                <img
                  src="../../../assets/icons/blueAddicon.png"
                  alt=""
                  class="btn"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal1"
                  data-bs-whatever="@mdo"
                  id="addicon"
                />
              </div>
            </div>
            <div class="col-md-6 d-flex align-items-center">
              <div class="col-md-3 ImportExport text-nowrap" style="visibility: hidden">
                <div class="row">
                  <div class="col-md-7 tableTextEvenRow">Import</div>
                </div>
              </div>
              <div class="col-md-3 ImportExport"></div>

              <div class="col-md-1">
                <img
                  src="../../../assets/icons/archive.png"
                  alt=""
                  id="ArchiveIcon"
                  style=" visibility:hidden;"
                  @click="handleArchiveClick('BoardList')"
                />
              </div>
              <div class="col-md-4 searchboxOfTable">
                <div class="search-box">
                  <img
                    src="../../../assets/icons/search.png"
                    alt="Search Icon"
                    id="SerchIconOfTable"
                  />
                  <input
                    type="text"
                    name="search"
                    id=""
                    v-model="searchString"
                    @input="searchLeads"
                    v-if="leadView === 'myLeads'"
                  
                   
                  />
                  <input
                    type="text"
                    name="search"
                    id=""
                    v-model="searchString"
                    @input="searchAllLeads"
                    v-else
                  />
                </div>
                <span>
                  <img
                    src="../../../assets/icons/filter.png"
                    alt="Search Icon"
                    id="FilterIconOfTable"
                      data-bs-toggle="modal"
                    data-bs-target="#LeadFilterModal"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="scrollable-container">
          <div class="row DesignStageContainer">
            <!-- Iterate over each stage and display data -->
            <div class="cardThings" v-for="(stage, index) in stages" :key="index">
              <div
                class="p-1 mt-1 z-3 card top-0 d-flex flex-row align-items-center headerOfCard"
                :style="{ zIndex: 10 - index }"
              >
                <span class="dot" :style="{}"></span>
                <span class="text-left">{{ stage.title }}</span>
                <span class="text-muted ms-2" style="font-size: 14px"
                  >({{ stage.items.length }})</span
                >
              </div>
              <div
                class="card-container mt-2 bodytext"
                style="background-color: #f8f8f8"
                :id="'stage-' + index"
                @dragover.prevent
                @drop="dropItem(index)"
              >
                <!-- Iterate over each item in the stage -->
                <div
                  class="draggable-item card m-1"
                  v-for="(item, itemIndex) in stage.items"
                  :key="itemIndex"
                  draggable="true"
                  @dragstart="dragStart(item, stage, itemIndex)"
                >
                  <div class="card-body Profile" style="background-color: #eee">
                    <div class="row">
                      <div class="col-12 m-2">
                        <h5
                          style="font-size: 14px; font-weight: bolder"
                          class="d-flex justify-content-between"
                        >
                          <span
                            @click="openModal(item.id)"
                            data-bs-toggle="modal"
                            data-bs-target="#exampleModal2"
                            data-bs-whatever="@mdo"
                          >
                            {{ item.name }}</span
                          >
                          <span>
                            <div
                              class="d-flex d-inline-flex align-items-center justify-content-around"
                              style="margin-right: 5px"
                            >
                              <button
                                type="button"
                                :class="priorityButtonClass(item)"
                                class="btn btn-sm"
                                style="
                                  width: 50px !important;
                                  height: 20px !important;
                                  font-size: 10px;
                                  padding: 3px;
                                "
                              >
                                {{ item.priority }}
                              </button>

                              <img
                                class="ms-2"
                                src="../../../assets/icons/phoneCall.png"
                                alt=""
                                @click="makePhoneCall(item.contactNo)"
                                style="width: 18px; height: 18px"
                              />
                              <img
                                @click="hideEmployeeDropdown('null')"
                                id="btnGroupDrop1"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                                class="ms-1"
                                src="../../../assets/icons/threeDot.jpg"
                                alt=""
                                style="
                                  width: 15px;
                                  height: 14px;
                                  margin-top: 1.5px;
                                  margin-right: 5px;
                                "
                              />
                              <div
                                class="dropdown-menu ms-3 text-black p-2 mt-1"
                                aria-labelledby="btnGroupDrop1"
                              >
                                <p
                                  @click="createEstimate(item)"
                                  @mouseenter="hideEmployeeDropdown('null')"
                                >
                                  Create Estimate
                                </p>
                                <p @mouseenter="showEmployeeDropdown(index)">
                                  Assigned To
                                </p>
                                <section
                                  v-if="activeDropdownIndex === index"
                                  class="employee-dropdown card p-1"
                                >
                                  <p
                                    v-for="employee in employees"
                                    :key="employee.employeeId"
                                    @click="
                                      assignEmployee(
                                        item.id,
                                        employee['employees.id'],
                                        stage
                                      )
                                    "
                                  >
                                    {{ employee["employees.firstName"] }}
                                    {{ employee["employees.lastName"] }}
                                  </p>
                                </section>
                              </div>
                            </div>
                          </span>
                        </h5>
                        <span
                          @click="openModal(item.id)"
                          data-bs-toggle="modal"
                          data-bs-target="#exampleModal2"
                          data-bs-whatever="@mdo"
                        >
                          <h6
                            class="card-subtitle mb-2 mt-1 text-muted"
                            style="font-size: 12px"
                          >
                            {{ item.email }}
                          </h6>
                          <h6
                            class="card-subtitle mb-2 mt-1 text-muted"
                            style="font-size: 12px"
                          >
                            {{ item.contactNo }}
                          </h6>
                          <h6
                            class="card-subtitle text-muted mt-1 mb-2"
                            style="font-size: 12px"
                          >
                            {{ item["leadAddresses.address"] }}
                          </h6>
                          <h6
                            class="card-subtitle text-muted mt-1 mb-2"
                            style="font-size: 12px"
                          >
                            Claimed By
                            <span
                              style="margin-left: 1px; font-weight: 500; color: #000000"
                            >
                              : {{ item["claimedBy.firstName"] }}
                              {{ item["claimedBy.lastName"] }}</span
                            >
                          </h6>
                          <h6
                            class="card-subtitle text-muted mt-1"
                            style="font-size: 12px"
                            v-if="item.assignTo"
                          >
                            Assignee &nbsp; &nbsp;
                            <span
                              style="margin-left: 1px; font-weight: 500; color: #000000"
                            >
                              : {{ item["assignee.firstName"] }}
                              {{ item["assignee.lastName"] }}</span
                            >
                          </h6>
                          <h6
                            class="card-subtitle text-muted mt-1"
                            style="font-size: 12px"
                            v-if="item['sources.name']"
                          >
                            Source &nbsp; &nbsp; &nbsp; &nbsp;
                            <span
                              style="margin-left: 1px; font-weight: 500; color: #000000"
                            >
                              : {{ item["sources.name"] }}
                            </span>
                          </h6>
                          <h6
                            class="card-subtitle text-muted mt-1"
                            style="font-size: 12px"
                            v-if="item.followUpdate"
                          >
                            Follow-up &nbsp;
                            <span
                              style="margin-left: 2px; font-weight: 500; color: #000000"
                            >
                              : {{ item.followUpdate }}
                            </span>
                          </h6>
                        </span>
                      </div>
                    </div>
                  </div>

                  <!-- End of item iteration -->
                </div>
              </div>
              <!-- End of stage iteration -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";
import LeadDetailsModal from "@/components/Modal/LeadDetailsModal.vue";
import LeadFilterModal from "@/components/Modal/LeadFilterModal.vue";

export default {
  name: "PropertyList",
  components: {
    Header,
    Nav,
    LeadDetailsModal,
    LeadFilterModal,
  },
  data() {
    return {
      filter: {},
      draggedItem: null,
      drag: true,
      stages: [
        { title: "Claimed", items: [] },
        { title: "RNR", items: [] },
        { title: "ContactMode", items: [] },
        { title: "Meeting", items: [] },
        { title: "Estimate", items: [] },
        { title: "Negotiation", items: [] },
        { title: "Sold", items: [] },
        { title: "Lost", items: [] },
        { title: "Trash", items: [] },
      ],
      SourceObject: {
        id: 0,
      },
      showExecative: false,
      selectedEmployeeId: this.$LocalUser.user.employeeId,
      activeDropdownIndex: null,
      leadView: "myLeads",
      searchString: "",
      leadDropdownStates: {},
      tabledropdownEdits: false,
      showClaimedList: false,
      showBoardview: false,
      selectAll: false,
      TableData: [],
      ArchiveData: [],
      selectedCustomers: [],
      pageSize: 50,
      currentPage: 1,
      showUnclaimedList: true,
      showArchiveList: false,
      selectedEmployees: [],
      searchResults: [],
      leadTypePage: true,
      dropdownOpen: false,
      statusDropdownOpen: false,
      selectedLeadId: null,
      employees: [],
      // showEmployeeDropdown: false, // to control the visibility of employee dropdown
    };
  },
  computed: {
    priorityButtonClass() {
      return (data) => {
        // Determine which class to apply based on data.priority
        switch (data.priority) {
          case "Low":
            return "btn-priority-low"; // Define this class in your CSS
          case "Medium":
            return "btn-priority-medium"; // Define this class in your CSS
          case "High":
            return "btn-priority-high"; // Define this class in your CSS
          default:
            return ""; // Default class if no priority is set or unknown priority
        }
      };
    },
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.TableData.length / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.ArchiveData.length / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.TableData.length / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.ArchiveData.length / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },
    displayedData() {
      // Use search results for displayed data
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.TableData.slice(startIndex, endIndex);
    },
    displayedArchiveData() {
      // Use search results for displayed archive data
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return this.ArchiveData.slice(startIndex, endIndex);
    },
  },
  mounted() {
    this.fetchEmployees();
    this.fetchData();
    this.fetchAllStageData();
  },
  methods: {
    
    fetchFilterData(filterData) {
      this.filter = filterData;
      if (this.showUnclaimedList ||  this.showBoardview ) {
        this.searchLeads();
      } else {
        this.searchArchiveLeads();
      }

      console.log(this.filter); // Now it should properly log the filter data
    },
    dragStart(item, sourceStage, sourceItemIndex) {
      console.log(this.drag);
      if (this.drag === true) {
        // Store the dragged item and its source stage
        this.draggedItem = item;
        this.sourceStage = sourceStage;
        this.sourceItemIndex = sourceItemIndex;
      } else {
        console.log(this.drag);
      }
    },

    setSelectedEmployee() {
      console.log(this.selectedEmployeeId);
      this.fetchData();
      this.fetchAllStageData();
      this.fetchArchiveData();
    },
    toggleBoardviewList() {
      this.showArchiveList = false;
      this.showUnclaimedList = false;
      this.showBoardview = true;
    },
    toggleListviewList() {
      this.showArchiveList = false;

      this.showBoardview = false;
      this.showUnclaimedList = true;
    },
    async fetchAllStageData() {
      // const promises = this.stages.map((stage) => this.fetchStageData(stage));
      // await Promise.all(promises);
    },
    async fetchStageData(stage) {
      console.log(stage);
      // try {
      //   const response = await axios.get(
      //     `${this.$apiEndPoint}/leads/byStatusAndSourceId/${encodeURIComponent(
      //       stage.title
      //     )}/All/${this.selectedEmployeeId}`
      //   );
      //   stage.items = response.data;
      //   this.drag = true;
      //   console.log(this.drag);
      // } catch (error) {
      //   this.$handleError(error);
      // }
    },
    async updateStatus(stage, itemId) {
      console.log(" New Stage " + stage.title);
      try {
        // Make an API call to update the status
        await axios.put(`${this.$apiEndPoint}/leads/changeStatus/${itemId}`, {
          status: stage.title,
        });
        this.fetchData();
        this.fetchStageData(stage); // Fetch updated data for the stage
      } catch (error) {
        this.$handleError(error);
      }
    },
    dropItem(targetStageIndex) {
      if (this.drag === true) {
        const targetStage = this.stages[targetStageIndex];

        if (targetStage.title === "Sold") {
          this.currentleadId = this.draggedItem.id;
          if (this.draggedItem.estimateApprovalStatus > 0) {
            this.showTransactionDetails = true;
          } else {
            alert("To Sold Lead  The Approved Estimate of Lead Should Prasent");
          }
        }

        if (this.sourceStage !== targetStage && targetStage.title !== "Sold") {
          this.sourceStage.items.splice(this.sourceItemIndex, 1);
          targetStage.items.push(this.draggedItem);
          this.drag = false;
          // Make an API call to update the status
          this.updateStatus(targetStage, this.draggedItem.id); // Pass the item id
        }
      }
    },
    handleRowClick(item) {
      const encodedId = btoa(item.id.toString()); // Encode the ID
      this.$router.push({
        name: "PropertyList",
        params: {
          Id: encodedId,
          name: item.name,
        },
      });
    },
    async assignEmployee(leadId, employeeId) {
      try {
        await axios.put(`${this.$apiEndPoint}/leads/${leadId}`, {
          assignTo: employeeId,
        });

        this.activeDropdownIndex = null;
        this.$toast(`Lead assigned successfully.`, "success");
        this.fetchAllStageData();
      } catch (error) {
        this.$handleError(error);
      }
    },
    showEmployeeDropdown(index) {
      this.activeDropdownIndex = index;
    },
    hideEmployeeDropdown(index) {
      this.activeDropdownIndex = index;
    },
    fetchEmployees() {
      axios
        .get(`${this.$apiEndPoint}/employee/myTeam/Sales`) // replace with your API endpoint
        .then((response) => {
          this.employees = response.data;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    isSalesManager() {
      return this.$LocalUser.employeeRoles.some(
        (role) => role.team === "Sales" && role.role === "Manager"
      );
    },
    setLeadView(view) {
      this.leadView = view;
      if (this.leadView === "allLeads") {
        this.fetchAllLeads();
        this.fetchAllLeadsArchiveData();
      } else {
        this.fetchData();
      }
      // You can add further logic here based on the selected view
    },
    changeLeadView() {
      if (this.leadView === "allLeads") {
        this.fetchAllLeads();
        this.fetchAllLeadsArchiveData();
      } else {
        this.fetchData();
      }
    },
    async fetchData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/leads/${this.selectedEmployeeId}`
        );
        this.TableData = response.data;
        this.drag = true;
        this.fetchAllStageData();
        this.filterLeadsByStage();
        console.log(this.TableData);
      } catch (error) {
        this.$handleError(error);
      }
    },
    filterLeadsByStage() {
      this.stages.forEach(stage => {
        // Filter leads with a status matching the stage title
        stage.items = this.TableData.filter(
          lead => lead.status === stage.title
        );
      });
    },
    // Method to fetch "All Leads" data
    async fetchAllLeads() {
      try {
        // Make an API call to fetch "All Leads" data
        const response = await axios.get(`${this.$apiEndPoint}/leads/getAllLeads/`);
        this.TableData = response.data; // Update displayed data
      } catch (error) {
        this.$handleError(error);
      }
    },

    goToPreviousPage() {
      if (this.hasPreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToNextPage() {
      if (this.hasNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    goToArchivePreviousPage() {
      if (this.hasArchivePreviousPage) {
        this.changePage(-1); // Go to the previous page
      }
    },
    goToArchiveNextPage() {
      if (this.hasArchiveNextPage) {
        this.changePage(1); // Go to the next page
      }
    },
    SigleLeadThingstd(lead) {
      this.selectedLead = lead;
      this.leadDropdownStates[lead.id] = !this.leadDropdownStates[lead.id];
    },
    toggletabledrowpdown() {
      this.tabledropdownEdits = !this.tabledropdownEdits;
    },

    openModal(leadId) {
      this.selectedLeadId = leadId;
    },
    handleArchiveClick(ListType) {
      this.fetchArchiveData();

      // Reset all list visibility
      this.showBoardview = false;
      this.showUnclaimedList = false;
      this.showArchiveList = false;

      // Set the visibility based on ListType
      if (ListType === "UnClaimList") {
        this.showArchiveList = true;
      } else if (ListType === "ArchiveList") {
        this.showUnclaimedList = true;
      } else if (ListType === "BoardList") {
        this.showArchiveList = true;
      }
    },

    async fetchArchiveData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/leads/${this.selectedEmployeeId}/archived`
        );
        this.ArchiveData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchAllLeadsArchiveData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/leads/getAllLeads/archived`
        );
        this.ArchiveData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    searchArchiveLeads() {
      let searchData = {
        searchString: this.searchString,
        source: this.filter.source?.length ? this.filter.source : [], // Use optional chaining
        status: this.filter.status?.length ? this.filter.status : [], // Use optional chaining
        priority: this.filter.priority?.length ? this.filter.priority : [], // Use optional chaining
        followupDate: this.filter.followupDate?.fromDate
          ? this.filter.followupDate
          : { fromDate: null, toDate: null }, // Default to null dates if not set
      };

      axios
        .post(
          `${this.$apiEndPoint}/leads/searchMyLead/${this.selectedEmployeeId}/archived`,
          searchData
        )

        .then((response) => {
          this.ArchiveData = response.data; // Ensure it's an array or provide default
          //console.log(this.ArchiveData);
          // Update the displayedLeads based on currentPage and rowsPerPage
          const startIndex = (this.currentPage - 1) * this.pageSize;
          this.displayedData = this.ArchiveData.slice(
            startIndex,
            startIndex + this.pageSize
          );
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //for Serch api for Unclaimleads
    searchLeads() {
      let searchData = {
        searchString: this.searchString,
        source: this.filter.source?.length ? this.filter.source : [], // Use optional chaining
        status: this.filter.status?.length ? this.filter.status : [], // Use optional chaining
        priority: this.filter.priority?.length ? this.filter.priority : [], // Use optional chaining
        followupDate: this.filter.followupDate?.fromDate
          ? this.filter.followupDate
          : { fromDate: null, toDate: null }, // Default to null dates if not set
      };

      console.log(searchData);
      axios
        .post(
          `${this.$apiEndPoint}/leads/searchMyLead/${this.selectedEmployeeId}`,
          searchData
        )
        .then((response) => {
          // Filter out null entries from response data
          const filteredData = response.data.filter((item) => item !== null);
          this.TableData = filteredData;
this.filterLeadsByStage();
          this.currentPage = 1;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    searchAllLeads() {
      let searchData = {
        searchString: this.searchString,
      };
      axios
        .post(`${this.$apiEndPoint}/leads/searchTeamLead/`, searchData)

        .then((response) => {
          this.TableData = response.data;

          // Reset current page to 1 after search
          this.currentPage = 1;
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    //footer pagination
    goToPage(pageNumber) {
      this.currentPage = pageNumber;
    },
    changePage(delta) {
      this.currentPage += delta;
    },
    //select and unselect checkbox function
    selectAllRows() {
      if (this.selectAll) {
        // If the selectAll checkbox is checked, select all displayed leads
        this.selectedCustomers = this.displayedData.map((customer) => customer.id);
      } else {
        // If the selectAll checkbox is unchecked, deselect all leads
        this.selectedCustomers = [];
      }
    },
    selectAllArchiveRows() {
      if (this.selectAll) {
        this.selectedCustomers = this.displayedArchiveData.map((customer) => customer.id);
      } else {
        this.selectedCustomers = [];
      }
    },
    // Method to delete the selected lead
    UnclaimLead(data, index) {
      this.TableData.splice(index, 1);
      this.ArchiveData.splice(index, 1);
      axios
        .put(`${this.$apiEndPoint}/leads/unClaimLead`, { id: [data.id] })
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch(() => {});
    },

    // Method to delete the selected lead
    DeleteLead(data, index) {
      this.TableData.splice(index, 1);
      this.ArchiveData.splice(index, 1);
      axios
        .delete(`${this.$apiEndPoint}/leads/deleteLeads`, {
          data: { id: [data.id] },
        })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch(() => {});
    },

    ArchiveLead(data, index) {
      this.TableData.splice(index, 1);
      this.ArchiveData.splice(index, 1);
      axios
        .put(`${this.$apiEndPoint}/leads/archive`, { id: [data.id] })
        // Pass an array with a single ID to match the API's expected format
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.leadDropdownStates[data.id] = false; // Hide dropdown after action
        })
        .catch(() => {});
    },

    //for all selected lead Unclaim and delete api

    UnclaimLeads(action) {
      if (this.selectedCustomers.length === 0) {
        return;
      }
      if (action === "Archive") {
        this.TableData = this.TableData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      } else if (action === "UnArchive") {
        this.ArchiveData = this.ArchiveData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      }
      const payload = {
        id: this.selectedCustomers,
        // Assuming salesPersonId needs to be provided
      };

      axios
        .put(`${this.$apiEndPoint}/leads/unClaimLead`, payload)
        .then(() => {
          this.fetchData();
          this.fetchArchiveData();
          this.selectAll = false;
          this.tabledropdownEdits = false; // Close the dropdown after action
          this.selectedCustomers = []; // Clear selected customers
        })
        .catch(() => {});
    },
    ArchiveLeads(action) {
      if (this.selectedCustomers.length === 0) {
        return;
      }
      if (action === "Archive") {
        this.TableData = this.TableData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      } else if (action === "UnArchive") {
        this.ArchiveData = this.ArchiveData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      }
      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .put(`${this.$apiEndPoint}/leads/archive`, payload)
        .then(() => {
          this.selectedCustomers = [];
          this.selectAll = false;
          this.fetchData();
          this.fetchArchiveData();
          this.tabledropdownEdits = false;
        })
        .catch(() => {});
    },

    deleteLead(action) {
      if (this.selectedCustomers.length === 0) {
        return;
      }
      if (action === "Archive") {
        this.TableData = this.TableData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      } else if (action === "UnArchive") {
        this.ArchiveData = this.ArchiveData.filter(
          (customer) => !this.selectedCustomers.includes(customer.id)
        );
        this.selectAll = false;
      }
      const payload = {
        id: this.selectedCustomers,
      };

      axios
        .delete(`${this.$apiEndPoint}/leads/deleteLeads`, { data: payload })
        .then(() => {
          this.selectedCustomers = [];
          this.fetchData();
          this.fetchArchiveData();
          this.selectAll = false;
          this.tabledropdownEdits = false;
        })
        .catch(() => {});
    },

    //featch all Unclaim lead Data

    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();
      return `${day}/${month}/${year}`;
    },
    createEstimate(selectedLead) {
      this.$router.push({
        name: "CreateEstimate",
        params: {
          Clientname: selectedLead.name,
          PropertyId: selectedLead.propertyId,
          ContactNo: selectedLead.contactNo,
          Email: selectedLead.email,
          leadId: selectedLead.id,
          address: `${selectedLead["leadAddresses.address"]}, ${selectedLead["leadAddresses.city"]}, ${selectedLead["leadAddresses.state"]}`,
          propertyName: selectedLead["property.name"],
        },
      });
    },

    toggleStatusDropdown() {
      this.statusDropdownOpen = !this.statusDropdownOpen;
    },
    changeStatus(status) {
      this.selectedStatus = status;
      this.statusDropdownOpen = false;
      this.dropdownOpen = true;
    },
    async updateFollowUpDate(leadId, followUpDate) {
      try {
        // Update follow-up date for the lead
        await axios.put(`${this.$apiEndPoint}/leads/updateFollowUpdate/${leadId}`, {
          followUpdate: followUpDate,
        });
        const lead = this.TableData.find((lead) => lead.id === leadId);
        const taskTitle = `Follow up with ${lead.name} (${lead.contactNo})`;

        // Get current date and time
        const currentDateTime = new Date();

        // Format current time as HH:mm
        const currentTime = currentDateTime.toLocaleTimeString([], {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
        console.log(this.$LocalUser.user.id);
        const taskData = {
          title: taskTitle,
          date: followUpDate,
          fromTime: currentTime,
          assignedTo: this.$LocalUser.user.id,
        };

        // Send a request to the backend API to create the task
        await axios.post(`${this.$apiEndPoint}/tasks/`, taskData);
        this.$toast(`follow-up Date  updated successfully.`, "success");
      } catch (error) {
        this.$handleError(error);
      }
    },

    exportToCSV() {
      // Combine displayedData and displayedArchiveData based on your current view
      const allData = this.showArchiveList ? this.ArchiveData : this.TableData;

      // Extract only the fields you want to include in the CSV
      const filteredData = allData.map((data) => ({
        Name: data.name || "N/A",
        Date: data.date || "N/A",
        "Contact No": data.contactNo || "N/A",
        "Email ID": data.email || "N/A",
        "Property Address": this.formatAddress(data["addresses.address"]) || "N/A",
        Source: data["sources.name"] || "N/A",
        ClaimedBy: `${data["employees.firstName"] || "N/A"} ${
          data["employees.lastName"] || "N/A"
        }`,
        Status: data.status || "N/A",
        Priority: data.priority || "N/A",
      }));

      // Convert filtered data to CSV format
      const csvContent = this.convertToCSV(filteredData);

      // Trigger download
      this.downloadCSV(csvContent);
    },
    formatAddress(address) {
      // Replace newline characters with commas
      return address ? address.replace(/\n/g, ", ") : "N/A";
    },

    convertToCSV(data) {
      const headers = Object.keys(data[0]).join(",") + "\n";
      const csvData = data.map((item) => Object.values(item).join(",")).join("\n");
      return headers + csvData;
    },

    downloadCSV(csvContent) {
      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "table_data.csv");
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style scoped>
select:focus {
  outline: none;
  box-shadow: none;
}

.btn-priority-low {
  background-color: #f4e8fb;
  color: #ba69e2;
}

.btn-priority-medium {
  background-color: #ecebec;
  color: #7e7882;
}

.btn-priority-high {
  background-color: #feabab;
  color: #fd0000;
}

.btn-priority-Normal {
  background-color: #eaf3f1;
  color: #4b9b86;
}

#LeadFollowDate {
  color: rgb(107, 60, 60);
}
.FormatTable {
  height: calc(100% - 74px);
  width: calc(100% - 74px);
  table-layout: fixed;
  position: fixed;
  display: flex;
  flex-direction: column;
}

.tableFormat:nth-child(2) {
  cursor: pointer;
}

.headerOfTable {
  width: calc(100% - 74px);
  padding: 0.5% 1%;
}

.table-wrapper {
  margin-left: 1%;
  margin-right: 1%;
  height: calc(97% - 74px);
  width: calc(100% - 24px);
  overflow-x: auto;
  overflow-y: auto;
  padding-bottom: 20px;
}

.tableFormat {
  width: 100%;
  border-collapse: collapse;
}
.tableFormat th:nth-child(1),
.tableFormat td:nth-child(1) {
  background-color: #ffffff;
  display: inline-flex;
}
.tableFormat thead {
  background-color: #bcbfc6;
  position: sticky;
  z-index: 99;
  top: 0;
}

.tableFormat thead tr th:not(:first-child) {
  background-color: #bcbfc6;
}

#blackThreeDots {
  width: 15px;
  height: 14px;
  margin-left: 30%;
  position: relative;
  cursor: pointer;
  margin-bottom: 5px;
}

.tableFormat th,
.tableFormat td {
  padding: 8px;
  text-align: left;
  white-space: nowrap; /* Prevent text wrapping */
}

.tableHeaderCheckbox,
.bodyCheckbox {
  width: 200px; /* Adjust width as needed */
}

.tableHeaderHeading {
  min-width: 150px; /* Minimum width to ensure readability */
  text-align: center; /* Center align headers */
}

.bodyText {
  text-align: center; /* Center align body text */
}

.custom-checkbox input[type="checkbox"] {
  margin-right: 5px;
}

.dropdown-toggle {
  cursor: pointer;
}

.dropdown-menu {
  position: absolute;
}
.table-wrapper
  .tableFormat
  tbody
  tr:not(:first-child):nth-child(even)
  td:not(:first-child) {
  background-color: #f8f8f8;
}
.ImportExport {
  visibility: hidden;
}

#addicon {
  width: 30px;
}

#ImportIcon {
  width: 20px;
  cursor: pointer;
}

#ArchiveIcon {
  width: 15px;
  cursor: pointer;
}

#SerchIconOfTable {
  width: 6%;
  margin-right: 5%;
}

.search-box {
  border-bottom: 2px solid #000;
  padding-bottom: 3px;
  display: flex;
  align-items: center;
}

.searchboxOfTable {
  width: 100%;
  display: flex;
  align-items: center;
}

#FilterIconOfTable {
  width: 20%;
  height: 5%;
  margin-left: 10%;
}

input[type="text"] {
  border: none;
  outline: none;
  font-size: 16px;
  width: 100%;
  text-align: left;
}
.button-container button {
  font-size: 12px;
}
.button-icon {
  width: 14px; /* Adjust size as needed */
  height: 14px; /* Adjust size as needed */
  margin-right: 5px; /* Space between image and text */
  vertical-align: middle; /* Aligns the image with the text */
}

/* Style for horizontal scrolling container */
.scrollable-container {
  max-width: 98%;
  margin: 0% 1%;
  padding: 0 0.5%;
  height: 88%;
  overflow-x: auto;
  z-index: 9;
  /* Enable horizontal scrolling */
  overflow-y: hidden;
  /* Disable vertical scrolling */
}

.headerOfCard {
  z-index: 999;
  position: sticky;
  top: 10%;
  background-color: #ffffff;
}

/* Style for stage container */
.DesignStageContainer {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  z-index: 9;
  background-color: #ffffff;
}

/* Hide the scrollbar for the stage container */
.DesignStageContainer::-webkit-scrollbar {
  display: none;
}

/* Style for individual stage cards */
.cardThings {
  width: 330px;
  z-index: 9;
}

/* Ensure the left section of the card (images) retains proper sizing */
.LeadCardLeft img {
  width: 80%;
}

.LeadCardLeft img:nth-child(1) {
  width: 120%;
}

/* Style for the dot in the card header */
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
}

/* Style for the card container */
.card-container {
  min-height: 90%;
  max-height: 90%;
  margin-bottom: 2vh;
  max-width: 320px;
  z-index: 1;
  border: none;
  background-color: #ffffff;
  overflow-y: auto;
  overflow-x: hidden;
  /* Changed to auto for automatic scrollbar when needed */
}

/* Style for the card body */
.Profile {
  border-radius: 10px;

  padding: 5px;
}

.container-Board {
  width: 100%;
  height: 87%;
  overflow-x: scroll;
  position: relative;
}

.container-Board::-webkit-scrollbar {
  display: none;
}

.ClaimedlistBoard {
  max-height: 100%;
  max-width: 97%;
  position: relative;
}

.LeadListCard::-webkit-scrollbar,
.ClaimedlistBoard::-webkit-scrollbar {
  display: none;
}
/*
.employee-dropdown {
  position: absolute;
  top: 90%;
  left: 95%; 
  transform: translateX(5px);
  z-index: 9999; 
}*/

@media screen and (max-width: 768px) {
  .tableFormat th,
  .tableFormat td {
    font-size: 12px; /* Adjust font size for smaller screens */
  }

  .tableHeaderCheckbox,
  .bodyCheckbox {
    width: 150px; /* Adjust width for smaller screens */
  }
  #addicon {
    width: 100%;
  }

  .table-wrapper {
    max-height: 90%;
    /* Adjust as needed */
    overflow-y: auto;
  }

  .headerOfTable {
    width: 100%;
    overflow: scroll;
  }

  #ArchiveIcon {
    width: 30%;
  }

  .ImportExport {
    display: none;
  }

  .paginationOfTable {
    bottom: 0.5%;
  }
}
</style>
