<template>
  <CustomerDetailsModal
  :defaultSourceId="sourceIdFromParentPage"
  :selectedLeadId="selectedLeadId"
  :projectId="selectedProjectId"
  @fetchData="fetchData()"
/>
    <div>
      <Header />
      <div class="content-Container">
        <Nav />
        <div class="containerChild">
          <div class="FormatTable" v-if="showUnclaimedList">
            <div class="row headerOfTable d-flex">
              <div class="col-md-12 d-flex justify-content-between align-content-center">
                <div class="col-md-6 d-flex">
                  <div class="col-md-3 text-nowrap active-SubHeading ms-2">
                    <h5 v-if="statusName === 'Completed' && !Projects">
                      Completed Projects
                    </h5>
                    <h5 v-else-if="statusName === 'In Progress'">Active Projects</h5>
                    <h5 v-else>{{ statusName }} Projects</h5>
                  </div>
  
                  <div class="col-md-2">
                    <img
                      v-if="statusName === 'New'"
                      src="../../assets/icons/blueAddicon.png"
                      alt=""
                      data-bs-toggle="modal"
                      data-bs-target="#CreateProjectModal"
                      data-bs-whatever="@mdo"
                      id="addicon"
                      style="cursor: pointer"
                    />
                  </div>
                </div>
                <div class="col-md-6 d-flex align-items-center">
                  <div class="col-md-3 ImportExport" style="visibility: hidden">
                    <div class="row">
                      <div class="col-md-4 tableTextEvenRow">Import</div>
                      <div class="col-md-3 tableTextEvenRow">
                        <img src="../../assets/icons/import.png" alt="" id="ImportIcon" />
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 ImportExport" style="visibility: hidden">
                    <div class="row">
                      <div class="col-md-4 tableTextEvenRow">Export</div>
                      <div class="col-md-3 tableTextEvenRow">
                        <img src="../../assets/icons/export.png" alt="" id="ImportIcon" />
                      </div>
                    </div>
                  </div>
  
                  <div class="col-md-1" style="visibility: hidden">
                    <img
                      src="../../assets/icons/archive.png"
                      alt=""
                      id="ArchiveIcon"
                      @click="handleArchiveClick"
                    />
                  </div>
                  <div class="col-md-4 searchboxOfTable w-100">
                    <div class="search-box">
                      <img
                        src="../../assets/icons/search.png"
                        alt="Search Icon"
                        id="SerchIconOfTable"
                      />
                      <input
                        class="border-0 w-100"
                        style="outline: 0"
                        type="text"
                        name="search"
                        id=""
                        v-model="searchString"
                        @input="searchLeads"
                      />
                    </div>
                    <span>
                      <img
                        src="../../assets/icons/filter.png"
                        alt="Search Icon"
                        class="FilterIconOfTable"
                        id="dropdownMenuButton"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        style="cursor: pointer"
                      />
  
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <div
                          v-for="(filter, index) in filters"
                          :key="index"
                          class="d-flex p-2 text-start"
                        >
                          <div class="custom-checkbox">
                            <input
                              type="checkbox"
                              :id="filter.value"
                              :value="filter.value"
                              v-model="selectedFilters"
                            />
                          </div>
                          <label class="d-flex ms-2 text-start" :for="filter.value">{{
                            filter.name
                          }}</label>
                        </div>
                      </div>
                    </span>
                    <span
                      v-if="this.filter.length > 0"
                      class="ms-2 mb-2 text-info"
                      style="font-size: 10px"
                    >
                      {{ this.filter.length }}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="table-wrapper">
              <table class="tableFormat">
                <caption class="d-none">
                  Table by Status
                </caption>
                <thead>
                  <tr>
                    <th>Project Name</th>
                    <th>Customer Name</th>
                    <th>Customer Id</th>
                    <th>Project Status</th>
                    <th>Contact No</th>
                    <th>Email Id</th>
                    <!-- <th>Property Address</th> -->
                  </tr>
                </thead>
  
                <tbody v-for="(data, index) in displayedData" :key="index" >
                  <tr     @click="openModal(data)"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal3"
                  data-bs-whatever="@mdo">
                    <td  style="cursor: pointer">
                      {{ data.projectName }}
                    </td>
                    <td>{{ data["customers.name"] }}</td>
                    <td>{{ data["customers.customerNumber"] }}</td>
                    <td>{{ data.projectStatus }}</td>
                    <td>{{ data["customers.contactNo"] }}</td>
                    <td>{{ data["customers.email"] }}</td>
                    <!-- <td>{{ data["addresses.address"] }}{{ data["addresses.city"] }}</td> -->
                  
  
                 
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="paginationOfTable">
              <div class="pageControlsTable">
                <img
                  src="../../assets/icons/blueLeftarrow.png"
                  @click="changePage(-1)"
                  :disabled="currentPage === 1"
                  alt="Prev"
                />
                <div class="page-numbers">
                  <span
                    v-for="pageNumber in pagination"
                    :key="pageNumber"
                    :class="{
                      'page-number': true,
                      'current-page': pageNumber === currentPage,
                    }"
                    @click="goToPage(pageNumber)"
                    >{{ pageNumber }}</span
                  >
                </div>
  
                <img
                  src="../../assets/icons/blueRightarrow.png"
                  @click="changePage(1)"
                  :disabled="currentPage === pagination.length"
                  alt="Next"
                />
              </div>
  
              <div class="entries-info">
                <span class="entries-text">Entries: {{ displayedData.length }} Rows</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

   
  </template>
  
  <script>
  import axios from "axios";
  import Header from "../Header.vue";
  import Nav from "../Nav.vue";
  import CustomerDetailsModal from "../Modal/CustomerDetailsModal.vue";
  let pageFlag = true;
  export default {
    name: "ProjectListByStatus",
    components: {
      Header,
      Nav,
      CustomerDetailsModal
    
    },
    data() {
      return {
        totalCount: null,
        projectName: null,
        AssignedProjectId: null,
        showModal: false,
        searchString: "",
        filter: [],
        leadDropdownStates: {},
        tabledropdownEdits: false,
        showClaimedList: false,
        selectAll: false,
        TableData: [],
        ArchiveData: [],
        selectedCustomers: [],
        pageSize: 50,
        currentPage: 1,
        showUnclaimedList: true,
        showArchiveList: false,
        selectedEmployees: [],
        searchResults: [],
        leadTypePage: true,
        dropdownOpen: false,
        statusDropdownOpen: false,
        selectedLeadId: null,
        statusName: "",
        selectedProjectId: null, // New data property for the selected project ID
      };
    },
    watch: {
      selectedFilters(newFilters) {
        if (newFilters.includes("All")) {
          this.selectedFilters = ["All"];
          this.filter = []; // Clear filter if "All" is selected
        } else {
          const index = newFilters.indexOf("All");
          if (index > -1) {
            newFilters.splice(index, 1); // Remove 'All' if other filters are selected
          }
          this.filter = [...newFilters]; // Save filter as an array
        }
        this.searchLeads();
      },
    },
    computed: {
    hasPreviousPage() {
      return this.currentPage > 1;
    },
    hasNextPage() {
      return this.currentPage < Math.ceil(this.totalCount / this.pageSize);
    },
    hasArchivePreviousPage() {
      return this.currentPage > 1;
    },
    hasArchiveNextPage() {
      return this.currentPage < Math.ceil(this.totalArchiveCount / this.pageSize);
    },

    pagination() {
      const pageCount = Math.ceil(this.totalCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },

    paginationArchive() {
      const pageCount = Math.ceil(this.totalArchiveCount / this.pageSize);
      const maxVisiblePages = 5;
      let pages = [];

      if (pageCount <= maxVisiblePages) {
        pages = Array.from({ length: pageCount }, (_, index) => index + 1);
      } else {
        const startPage = Math.max(2, this.currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(
          pageCount - 1,
          this.currentPage + Math.floor(maxVisiblePages / 2)
        );

        pages = [1];

        if (startPage > 2) {
          pages.push("...");
        }

        pages.push(
          ...Array.from(
            { length: endPage - startPage + 1 },
            (_, index) => startPage + index
          )
        );

        if (endPage < pageCount - 1) {
          pages.push("...");
        }

        pages.push(pageCount);
      }

      return pages;
    },
    displayedData() {
      return this.TableData;
    },
    displayedArchiveData() {
      return this.ArchiveData;
    },
  },
    mounted() {
      this.statusName = this.$route.params.statusName;
      this.selectedEmployeeId = this.$route.params.selectedEmployeeId;
      this.fetchData();
    },
    methods: {
      
      async AcceptProject() {
        try {
          const response = await axios.put(
            `${this.$apiEndPoint}/designProjects/${this.AssignedProjectId}`,
            {
              designStage: "Discovery Session",
              projectStatus: "In Progress",
            }
          );
  
          if (response.status === 200) {
            this.$router.push({
              name: "DesignProjectProfile",
              params: {
                Id: this.AssignedProjectId,
              },
            });
            this.fetchData();
            // Handle successful response
  
            this.$toast("Document approved successfully", "success");
          }
          this.showModal = false;
        } catch (error) {
          this.$handleError(error);
        }
      },
      openApprovalModal(Id) {
        this.AssignedProjectId = Id;
        const project = this.TableData.find(
          (project) => project.id === this.AssignedProjectId
        );
  
        if (project) {
          this.projectName = project.projectName;
        }
        this.showModal = true;
      },
  
      downloadFile(url, fileName) {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url);
        xhr.responseType = "blob";
  
        xhr.onload = function () {
          if (xhr.status === 200) {
            const blob = xhr.response;
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
  
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          } else {
            console.error("Error downloading file:", xhr.statusText);
          }
        };
  
        xhr.onerror = function () {
          console.error("Error downloading file:", xhr.statusText);
        };
  
        xhr.send();
      },
      acceptProject(projectId) {
        this.selectedProjectId = projectId;
      },
      navigateToProjectProfile(data) {
        // Check if statusName is not "New" and not "Assigned"
        if (this.statusName !== "New" && this.statusName !== "Assigned") {
          // Navigate to DesignProjectProfile with project ID and name as parameters
          this.$router.push({
            name: "DesignProjectProfile",
            params: {
              Id: data.id,
              projectName: data.projectName,
            },
          });
        } else {
          // Log a message for when navigation doesn't happen
          console.log("Navigation is not allowed for 'New' or 'Assigned' statuses.");
        }
      },
  
      SigleLeadThingstd(lead) {
        this.selectedLead = lead;
        this.leadDropdownStates[lead.id] = !this.leadDropdownStates[lead.id];
      },
      toggletabledrowpdown() {
        this.tabledropdownEdits = !this.tabledropdownEdits;
      },
      openModal(data) {
        console.log(this.selectedLeadId);
        this.selectedLeadId = data['customers.id'];
        this.selectedProjectId=data.id
        console.log(this.selectedLeadId); // Set the selected lead ID when a lead is clicked
      },
      
      handleArchiveClick() {
        this.fetchArchiveData();
        this.showUnclaimedList = !this.showUnclaimedList;
        this.showArchiveList = !this.showArchiveList;
      },
      async fetchData() {
        this.selectedLeadId = null;
      let searchData = {
        searchString: this.searchString,
      };

      console.log(searchData);

      const hasSearchData = searchData.searchString;

      if (hasSearchData && pageFlag === true) {
        this.currentPage = 1;
        pageFlag = false;
        console.log(pageFlag);
      }
        try {
          const response = await axios.post(
            `${this.$apiEndPoint}/projects/${this.statusName}/${this.currentPage}/${this.selectedEmployeeId}`,
            searchData
          );
          this.totalCount = response.data.count;
          this.TableData = response.data.rows;
        } catch (error) {
          this.$handleError(error);
        }
      },
      async fetchArchiveData() {
        try {
          const response = await axios.get(
            `${this.$apiEndPoint}/designProjects/projectStatus/${this.statusName}/${this.selectedEmployeeId}/archived`
          );
          this.ArchiveData = response.data;
        } catch (error) {
          this.$handleError(error);
        }
      },
      //select and unselect checkbox function
      selectAllRows() {
        if (this.selectAll) {
          this.selectedCustomers = this.displayedData.map((customer) => customer.id);
        } else {
          this.selectedCustomers = [];
        }
      },
      selectAllArchiveRows() {
        if (this.selectAll) {
          this.selectedCustomers = this.displayedArchiveData.map((customer) => customer.id);
        } else {
          this.selectedCustomers = [];
        }
      },
  
      // Method to delete the selected lead
      DeleteLead(data) {
        axios
          .delete(`${this.$apiEndPoint}/customer/deleteCustomer`, {
            data: { id: [data.id] },
          })
          // Pass an array with a single ID to match the API's expected format
          .then((response) => {
            console.log("Lead deleted successfully:", response.data);
            this.fetchData();
            this.fetchArchiveData();
            this.leadDropdownStates[data.id] = false; // Hide dropdown after action
          })
          .catch((error) => {
            this.$handleError(error);
          });
      },
  
      ArchiveLead(data) {
        axios
          .put(`${this.$apiEndPoint}/customer/archive`, { id: [data.id] })
          // Pass an array with a single ID to match the API's expected format
          .then((response) => {
            console.log("Lead deleted successfully:", response.data);
            this.fetchData();
            this.fetchArchiveData();
            this.leadDropdownStates[data.id] = false; // Hide dropdown after action
          })
          .catch((error) => {
            this.$handleError(error);
          });
      },
      searchArchiveLeads() {
        let searchData = {
          searchString: this.searchString,
        };
  
        axios
          .post(
            `${this.$apiEndPoint}/designProjects/searchStatus/${this.statusName}/archived`,
            searchData
          )
  
          .then((response) => {
            this.ArchiveData = response.data; // Ensure it's an array or provide default
            console.log(this.ArchiveData);
            // Update the displayedLeads based on currentPage and rowsPerPage
            const startIndex = (this.currentPage - 1) * this.pageSize;
            this.displayedData = this.ArchiveData.slice(
              startIndex,
              startIndex + this.pageSize
            );
          })
          .catch((error) => {
            this.$handleError(error);
          });
      },
      //for Serch api for Unclaimleads
      searchLeads() {
      pageFlag = true;
      this.fetchData();
    },
  
      //for all selected lead Unclaim and delete api
  
      ArchiveLeads() {
        if (this.selectedCustomers.length === 0) {
          return;
        }
  
        const payload = {
          id: this.selectedCustomers,
        };
  
        axios
          .put(`${this.$apiEndPoint}/customers/archive`, payload)
          .then((response) => {
            console.log("Leads deleted successfully:", response.data);
            this.selectedCustomers = [];
            this.fetchData();
            this.fetchArchiveData();
            this.tabledropdownEdits = false;
          })
          .catch((error) => {
            this.$handleError(error);
          });
      },
  
      deleteLead() {
        if (this.selectedCustomers.length === 0) {
          return;
        }
  
        const payload = {
          id: this.selectedCustomers,
        };
  
        axios
          .delete(`${this.$apiEndPoint}/customer/deleteCustomer`, { data: payload })
          .then((response) => {
            console.log("Leads deleted successfully:", response.data);
            this.selectedCustomers = [];
            this.fetchData();
            this.fetchArchiveData();
            this.tabledropdownEdits = false;
          })
          .catch((error) => {
            this.$handleError(error);
          });
      },
  
      //footer pagination
      goToPage(pageNumber) {
      this.selectAll = false;
      this.currentPage = pageNumber;
      console.log(this.currentPage);
      this.fetchData();
     
      this.selectedCustomers = [];
    },
    changePage(delta) {
      this.selectAll = false;
      this.lastPages = this.currentPage;
      this.currentPage += delta;
      this.fetchData();
 
      this.selectedCustomers = [];
    },
      //featch all Unclaim lead Data
  
      formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, "0");
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const year = date.getFullYear().toString();
        return `${day}/${month}/${year}`;
      },
      createEstimate(selectedLead) {
        this.$router.push({
          name: "CreateEstimate",
          params: {
            Clientname: selectedLead.name,
            PropertyId: selectedLead.propertyId,
            ContactNo: selectedLead.contactNo,
            Email: selectedLead.email,
            leadId: selectedLead.id,
            address: selectedLead.address,
            propertyName: selectedLead["property.name"],
          },
        });
      },
  
      toggleStatusDropdown() {
        this.statusDropdownOpen = !this.statusDropdownOpen;
      },
      changeStatus(status) {
        this.selectedStatus = status;
        this.statusDropdownOpen = false;
        this.dropdownOpen = true;
      },
    },
  };
  </script>
  
  <style scoped>
  .FormatTable {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .tableFormat:nth-child(2) {
    cursor: pointer;
  }
  
  .headerOfTable {
    width: 96%;
    padding: 0.5% 1%;
  }
  
  .table-wrapper {
    max-width: 99%;
    margin: 0% 1%;
    padding: 0 0.5%;
    height: 87%;
    overflow-x: scroll;
    overflow-y: scroll;
    z-index: 99;
  }
  
  .tableFormat {
    width: 100%;
    border-collapse: collapse;
  }
  
  .tableFormat thead {
    background-color: #bcbfc6;
    position: sticky;
    z-index: 99;
    top: 0;
  }
  
  .tableFormat thead tr th {
    background-color: #bcbfc6;
  }
  .tableFormat th,
  .tableFormat td {
    padding: 8px 18px;
    text-align: left;
    white-space: nowrap; /* Prevent text wrapping */
  }
  
  #blackThreeDots {
    width: 15px;
    height: 14px;
    margin-left: 30%;
    position: relative;
    cursor: pointer;
  }
  
  .tableHeaderCheckbox,
  .bodyCheckbox {
    width: 200px; /* Adjust width as needed */
  }
  
  .tableHeaderHeading {
    min-width: 150px; /* Minimum width to ensure readability */
    text-align: center; /* Center align headers */
  }
  
  .bodyText {
    text-align: center; /* Center align body text */
  }
  
  .custom-checkbox input[type="checkbox"] {
    margin-right: 5px;
  }
  
  .dropdown-toggle {
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Enhanced shadow */
    transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
  }
  .dropdown-menu:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
    cursor: pointer;
  }
  .table-wrapper .tableFormat tbody tr:not(:first-child):nth-child(even) {
    background-color: #f8f8f8;
  }
  .ImportExport {
    visibility: hidden;
  }
  
  #addicon {
    width: 20px;
    height: 20px;
  }
  
  #ImportIcon {
    width: 20px;
    cursor: pointer;
  }
  
  #ArchiveIcon {
    width: 15px;
    cursor: pointer;
  }
  
  #SerchIconOfTable {
    width: 6%;
    margin-right: 5%;
  }
  
  .search-box {
    border-bottom: 2px solid #000;
    padding-bottom: 3px;
    display: flex;
    align-items: center;
  }
  
  .searchboxOfTable {
    width: 100%;
    display: flex;
    align-items: center;
  }
  
  .FilterIconOfTable {
    width: 20px;
    height: 15px;
    margin-left: 10%;
  }
  
  @media screen and (max-width: 768px) {
    .tableFormat th,
    .tableFormat td {
      font-size: 12px; /* Adjust font size for smaller screens */
    }
  
    .tableHeaderCheckbox,
    .bodyCheckbox {
      width: 150px; /* Adjust width for smaller screens */
    }
    #addicon {
      width: 100%;
    }
  
    .table-wrapper {
      max-height: 90%;
      /* Adjust as needed */
      overflow-y: auto;
    }
  
    .headerOfTable {
      width: 100%;
      overflow: scroll;
    }
  
    #ArchiveIcon {
      width: 30%;
    }
  
    .ImportExport {
      display: none;
    }
  
    .paginationOfTable {
      bottom: 0.5%;
    }
  }
  </style>