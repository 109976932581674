<template>
  <div>
    <SourceCreate @sourceCreated="fetchSource" />
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="SalesSource col-12">
          <div class="row h-10 p-1 SalesSourceHeader align-items-center d-flex">
            <div class="col-12 d-flex align-content-center">
              <div class="row text-size-responsive mt-2">
                <div class="col-6">
                  <h3 class="mainHeading ms-3">Source</h3>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-1 col-12" v-if="isSalesManager()">
            <div class="col-md-10"></div>
            <div class="col-md-2 text-lg-right text-nowrap createSourcebutton">
              <button
                type="button"
                class="browse"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >
                Create Source +
              </button>
            </div>
          </div>
          <div class="row mt-1 col-12" v-else style="visibility: hidden">
            <div class="col-md-10"></div>
            <div class="col-md-2 text-lg-right text-nowrap createSourcebutton">
              <button
                type="button"
                class="browse"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >
                Create Source +
              </button>
            </div>
          </div>
          <div style="margin: 0 3%">
            <!-- Table with scrollable content -->
            <div class=" ">
              <table class="tableFormat">
                <caption class="d-none">
                  Table
                </caption>
                <thead>
                  <tr class="text-center">
                    <th>S/N</th>
                    <th>Source Name</th>
                    <th>Collaborator</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  <!-- Loop through the array to populate rows -->
                  <tr
                    v-for="(item, index) in tableData"
                    :key="index"
                    class="text-center"
                    @mouseenter="setDeleteVisible(item.id, true)"
                    @mouseleave="setDeleteVisible(item.id, false)"
                  >
                    <td>{{ index + 1 }}</td>
                    <td @click="handleRowClick(item)">{{ item.name }}</td>

                    <td>
                      <div
                        class="collaborator-avatar"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <span
                          v-for="(employee, employeeIndex) in item.salesPersonSource ||
                          []"
                          :key="employeeIndex"
                          class="initials"
                        >
                          <img
                            v-if="employee['employees.signedUrl'] !== null"
                            :src="employee['employees.signedUrl']"
                            style="
                              width: 27px;
                              height: 27px;
                              border-radius: 50%;
                              background-color: none;
                              padding-left: 1px;
                            "
                            class="mb-2 ms-1"
                            alt=""
                          />
                          <span v-else>{{ employee["employees.initials"] }}</span>
                        </span>
                      </div>

                      <!-- Dropdown menu -->
                      <ul
                        class="dropdown-menu"
                        aria-labelledby="collaboratorDropdown"
                        style="z-index: 99999; max-height: 200px; overflow: auto"
                      >
                        <!-- Loop through collaborators -->
                        <li
                          v-for="(employee, employeeIndex) in item.salesPersonSource"
                          :key="employeeIndex"
                          class="p-2"
                        >
                          <!-- Display collaborator initials -->
                          <span
                            >{{ employee["employees.firstName"] }}
                            {{ employee["employees.lastName"] }}</span
                          >
                        </li>
                      </ul>
                    </td>
                    <td style="cursor: pointer">
                      <div
                        class="d-flex align-content-center justify-content-left text-nowrap progressImage"
                      >
                        <img
                          v-if="item.status === 'Completed'"
                          src="../../assets/icons/completed.png"
                          alt="Completed"
                        />
                        <img
                          v-else-if="item.status === 'On Hold'"
                          src="../../assets/icons/onHold.png"
                          alt="On Hold"
                        />
                        <img
                          v-else-if="item.status === 'In Progress'"
                          src="../../assets/icons/inProgress.png"
                          alt="In Progress"
                        />

                        <h6
                          class="bodyText mt-1"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          {{ item.status }}
                        </h6>
                        <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                          <button
                            class="dropdown-item"
                            @click="changeStatus(item.id, index, 'In Progress')"
                          >
                            In Progress
                          </button>
                          <button
                            class="dropdown-item"
                            @click="changeStatus(item.id, index, 'Completed')"
                          >
                            Completed
                          </button>
                          <button
                            class="dropdown-item"
                            @click="changeStatus(item.id, index, 'On Hold')"
                          >
                            On Hold
                          </button>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Delete Confirmation Modal -->
    <div
      class="modal fade"
      id="deleteConfirmationModal"
      tabindex="-1"
      aria-labelledby="deleteConfirmationModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="deleteConfirmationModalLabel">
              Confirm Deletion
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">Are you sure you want to delete this item?</div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-bs-dismiss="modal"
              @click="cancelDelete"
            >
              Cancel
            </button>
            <button type="button" class="btn btn-primary" @click="confirmDelete">
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import SourceCreate from "../Modal/SourceCreate.vue";
import axios from "axios";

export default {
  name: "SalesSource",
  components: {
    Header,
    Nav,
    SourceCreate,
  },
  data() {
    return {
      modalOpen: false,
      showModalSales: false,
      tableData: [],
      showDelete: {}, // Object to keep track of delete icon visibility for each row
      selectedItemId: null,
      showDeleteConfirmationModal: false,
    };
  },
  mounted() {
    this.fetchSource();
  },
  methods: {
    isSalesManager() {
      return this.$LocalUser.employeeRoles.some(
        (role) => role.team === "Sales" && role.role === "Manager"
      );
    },
    toggleModal() {
      this.showModalSales = !this.showModalSales;
    },
    showModal() {
      this.modalOpen = true;
    },
    closeModal() {
      this.modalOpen = false;
    },
    async fetchSource() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/source/`);
        this.tableData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    handleRowClick(item) {
      const encodedId = btoa(item.id.toString()); // Encode the ID
      this.$router.push({
        name: "PropertyList",
        params: {
          Id: encodedId,
          name: item.name,
        },
      });
    },
    setDeleteVisible(id, visible) {
      // Assuming `showDelete` is already declared in the data
      this.showDelete[id] = visible;
    },
    async changeStatus(sourceId, index, status) {
      this.tableData[index].status = status;
      try {
        await axios.put(`${this.$apiEndPoint}/source/${sourceId}`, { status });
        // Assuming you have a method to fetch source after status change
        this.fetchSource();
      } catch (error) {
        this.$handleError(error);
      }
    },

    async deleteItem(id) {
      // Set the selected item for deletion and show the confirmation modal
      this.selectedItemId = id;
      this.showDeleteConfirmationModal = true;
    },
    async confirmDelete() {
      try {
        // Perform the deletion
        await axios.delete(`${this.$apiEndPoint}/source/${this.selectedItemId}`);
        // Hide the confirmation modal
        this.showDeleteConfirmationModal = false;
        // Refresh data after deletion
        this.fetchSource();
      } catch (error) {
        this.$handleError(error);
      }
    },
    cancelDelete() {
      // Hide the confirmation modal without performing deletion
      this.showDeleteConfirmationModal = false;
    },
  },
};
</script>
<style scoped>
.SalesSource {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.tableFormat td {
  border: 1px solid #edeae9;
}

/* Style for collaborator avatars */
.collaborator-avatar {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-right: -5px;

  /* Adjust margin to control the overlap */
}

/* Style for initials */
.initials {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #4884f7; /* Blue background color */
  color: #ffffff; /* White text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  cursor: pointer;
  z-index: 1;
  margin-right: -4px; /* Adjust margin to control the overlap */
  position: relative; /* Ensure z-index works correctly */
}

.SalesSourceHeaderImage {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.tableFormat tbody img {
  width: 12px;
  height: 12px;
  margin: 7px 5px 2px 0;
}

/* Header styling */
.SalesSourceHeader {
  border-bottom: 5px solid #bcbfc6;
}
.table thead tr.subHeaderHeadingBgColor th {
  background-color: #bcbfc6;
}
.additional-count {
  width: 15px;
  /* Adjust size as needed */
  height: 15px;
  /* Adjust size as needed */
  border-radius: 50%;
  /* Make the container circular */
  background-color: #b1c5df;
  /* Provide a background color */
  color: #000000;
  /* Set text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  /* Adjust font size as needed */
  cursor: pointer;

  z-index: 2;
}
/* Button styling 
.CreateSourceButton {
  background-color: blue;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 5px;
  position: relative;
}*/

.text-size-responsive {
  font-size: clamp(
    16px,
    2.5vw,
    20px
  ); /* Adjust the minimum and maximum font sizes for desktop */
}

.tableFormat {
  width: 100%;
  height: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}

/* Sticky thead */
.tableFormat thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ccc; /* Background color for sticky header */
}

/* Set a fixed height for the tbody */
.tableFormat tbody {
  display: block;
  max-height: 500px; /* Adjust as per your layout */
  overflow-y: auto; /* Make the tbody scrollable */
}

/* Even rows background color */
table tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}
/* Sticky header */
.tableFormat thead {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #ccc; /* Background color for sticky header */
}

/* Table header and cell styling */
.tableFormat thead th {
  text-align: left;
  padding: 6px 20px;
  font-size: 16px;
  font-weight: 500;
}

.tableFormat tbody td {
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  padding: 6px 20px;
}

/* Set scrollable height for tbody */
.table-wrapper {
  height: calc(85vh - 50px); /* Adjust height to leave room for other elements */
  overflow-y: auto; /* Make tbody scrollable */
}

/* Ensure thead stays in place and columns align */
.tableFormat thead tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* Ensure columns are fixed width */
}

/* Ensure tbody rows take the full width */
.tableFormat tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed; /* Ensure columns are fixed width */
}

/* Style for table rows and hover effect */
.tableFormat tbody tr:hover {
  background-color: #edeae9 !important;
  cursor: pointer;
}

/* Even rows background color */
.tableFormat tbody tr:nth-child(even) {
  background-color: #f8f8f8;
}

/* Column width for SL.No */
.tableFormat thead th:nth-child(1),
.tableFormat tbody td:nth-child(1) {
  width: 60px; /* Adjust width of the first column */
  text-align: center;
}

@media (max-width: 768px) {
  .CreateSourceButton {
    font-size: 0.8rem;
    padding: 3px 8px;
  }

  .table-wrapper {
    max-height: 85%;
    overflow-y: auto;
  }
  .createSourcebutton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

/* Additional media queries for desktop version can be added here */
</style>
