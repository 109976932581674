<template>
    <div class="dashboardcard-container cardsOfDesign">
        <div class="card dashboardcard" >
          <div class="d-flex justify-content-between flex-row p-1 m-2">
            
            <span  style="visibility:hidden;">
              <img src="../../assets/icons/newProjects.png" alt="" />
            </span>
          </div>
          <div class="ms-2 p-1">
            <span id="textColorOfCard"  style="visibility:hidden;">New Projects</span>
          </div>
        </div>
        <div class="card dashboardcard">
          <div class="d-flex justify-content-between flex-row p-1 m-2">
            
            <span  style="visibility:hidden;">
              <img src="../../assets/icons/assignedProjects.png" alt="" />
            </span>
          </div>
          <div class="ms-2 p-1">
            <span id="textColorOfCard"  style="visibility:hidden;">Assigned Projects</span>
          </div>
        </div>
        <div
          class="card dashboardcard"
    
        >
          <div class="d-flex justify-content-between flex-row p-1 m-2">
        
            <span  style="visibility:hidden;">
              <img src="../../assets/icons/activeProjects.png" alt="" />
            </span>
          </div>
          <div class="ms-2 p-1">
            <span id="textColorOfCard  "  style="visibility:hidden;">Active Projects</span>
          </div>
        </div>
        <div class="card dashboardcard" >
          <div class="d-flex justify-content-between flex-row p-1 m-2">
           
            <span  style="visibility:hidden;">
              <img src="../../assets/icons/completedProjects.png" alt="" />
            </span>
          </div>
          <div class="ms-2 p-1">
            <span id="textColorOfCard "  style="visibility:hidden;">fhfgf</span>
          </div>
        </div>
      </div>
    <div class="section WhiteHeadSection">
        <div class="container-fluid contentOfWhiteHeadSection">
        <div class="container-fluid  contentOfWhiteHeadSection2  mb-2">
          <div
            class="container-fluid d-flex p-0"
            style="width: 98%; position: relative; gap: 3%; padding: 0 2%"
          >
            <div style="flex: 1">
              <div class="d-flex flex-column LeadChart2 p-2">
                <div class="row w-100 ms-1">
                  <p class="subHeadingHeader p-1 m-0">Today’s Task</p>
                  <hr />
                </div>
                <div
                  class="card card2 p-2 m-2"
                  v-for="task in todayTasks"
                  :key="task.id"
                  data-bs-toggle="modal"
                  data-bs-target="#taskModal"
                  @click="openTaskModal(task.id)"
                >
                  <div class="d-flex justify-content-between align-items-center">
                    <section class="d-flex align-items-center">
                      <img
                        v-if="task.status === Pending"
                        src="../../assets/icons/taskPending.png"
                        id="titleIcon"
                        alt=""
                        style="width: 17px; height: 17px"
                      />
                      <img
                        v-else
                        src="../../assets/icons/taskCompleted.png"
                        alt="Task Completed"
                        style="width: 17px; height: 17px"
                      />
                      <span class="ms-2" style="font-size: 14px">{{ task.title }}</span>
                    </section>
                    <section class="d-flex align-items-center">
                      <span class="d-flex align-items-center" style="padding: 0 10px">
                        <img
                          src="../../assets/icons/subtask.png"
                          alt="Subtask"
                          style="width: 15px; height: 15px"
                        />
                        <sup style="font-size: 10px; font-weight: 600" class="ms-1">
                          {{ task.subtaskCnt }}
                        </sup>
                      </span>
                      <span style="padding: 0 5px" v-if="task.priority">
                        <button
                          :class="priorityButtonClass(task)"
                          style="
                            width: 70px;
                            height: 25px;
                            font-size: 10px;
                            border: none;
                            border-radius: 5px;
                          "
                        >
                          {{ task.priority }}
                        </button>
                      </span>
                      <span style="padding: 0 5px">
                        <img
                          src="../../assets/icons/threeDot.jpg"
                          alt="More options"
                          style="width: 15px; height: 13px"
                        />
                      </span>
                    </section>
                  </div>
                  <div class="mt-1" v-if="task.subTaskCount">
                    <span
                      class="ms-3"
                      style="
                        padding: 0 10px;
                        background-color: rgba(0, 128, 0, 0.3);
                        border-radius: 5px;
                      "
                    >
                      <img
                        src="../../assets/icons/subtask.png"
                        alt="Subtask"
                        style="width: 15px; height: 15px"
                      />
                      <sup style="font-size: 10px"> {{ task.subTaskCount }}</sup>
                    </span>
                  </div>
                  <div class="mt-2 d-flex justify-content-between">
                    <section class="d-flex align-items-center">
                      <img
                        src="../../assets/icons/black_Calendar.png"
                        alt="Calendar"
                        style="width: 17px; height: 17px"
                      />
                      <span class="mt-1 ms-2 text-muted" style="font-size: 10px">
                        {{ reverseDateFormat(task.date) }}
                      </span>
                    </section>
                    <section>
                      <span class="collaborator-avatar">
                        <span
                          class="d-flex initials"
                          v-for="(employee, employeeIndex) in task.collaborator || []"
                          :key="employeeIndex"
                        >
                          <span>{{ employee.initials }}</span>
                        </span>
                      </span>
                    </section>
                  </div>
                </div>
              </div>
            </div>
            <div style="flex: 1">
              <div class="d-flex flex-column LeadChart2 p-2">
                <div class="row w-100 ms-1">
                  <p class="subHeadingHeader p-1 m-0">Today’s Schedules</p>
                  <hr />
                </div>
  
                <div
                  @click="changeView('day')"
                  class="card card3 p-2 m-2 border-0 blueactive-SubHeading2 justify-content-between"
                  v-for="event in todayEvents"
                  :key="event.id"
                >
                  <div class="m-1">
                    <p style="font-size: 14px; font-weight: 600" class="p-0 mb-1">
                      {{ event.title }}
                    </p>
                    <p style="font-size: 10px" v-if="event.description !== null">
                      {{ event.description }}
                    </p>
                  </div>
                  <div
                    class="mb-1 m-1"
                    style="font-size: 12px"
                    v-if="event.meetingLink !== null"
                  >
                    <span>
                      <a :href="'//' + event.meetingLink" target="_blank">{{
                        event.meetingLink
                      }}</a>
                    </span>
                  </div>
                  <div class="d-flex justify-content-between mt-1">
                    <section style="font-size: 12px">
                      <p class="p-0 m-1 d-flex align-items-center">
                        <img
                          src="../../assets/icons/time.png"
                          alt="Calendar"
                          class="mt-2"
                          style="width: 15px; height: 15px"
                        />
                        <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                          {{ convertTo12HourFormat(event.fromTime) }} To
                          {{ convertTo12HourFormat(event.toTime) }}
                        </span>
                      </p>
                      <p class="p-0 m-1 d-flex align-items-center">
                        <img
                          src="../../assets/icons/Calendar.png"
                          alt="Calendar"
                          class="mt-1"
                          style="width: 15px; height: 15px"
                        />
                        <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                          {{ reverseDateFormat(event.date) }}
                        </span>
                      </p>
                    </section>
                    <section class="d-flex align-items-center">
                      <span class="collaborator-avatar">
                        <span
                          class="d-flex initials"
                          v-for="(employee, employeeIndex) in event.eventParticipants || []"
                          :key="employeeIndex"
                        >
                          <span>{{ employee["users.initials"] }}</span>
                        </span>
                      </span>
                    </section>
                  </div>
                </div>
                <div
                  @click="changeView('day')"
                  class="card card3 p-2 m-2 border-0 Yellow-SubHeading2 justify-content-between"
                  v-for="item in todayReminders"
                  :key="item.id"
                >
                  <div class="m-1">
                    <p style="font-size: 14px; font-weight: 600" class="p-0 mb-1">
                      {{ item.title }}
                    </p>
                    <p style="font-size: 10px" v-if="item.description !== null">
                      {{ item.description }}
                    </p>
                  </div>
  
                  <div class="d-flex justify-content-between mt-1">
                    <section style="font-size: 12px">
                      <p class="p-0 m-1 d-flex align-items-center">
                        <img
                          src="../../assets/icons/time.png"
                          alt="Calendar"
                          class="mt-2"
                          style="width: 15px; height: 14px"
                        />
                        <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                          {{ convertTo12HourFormat(item.fromTime) }} To
                          {{ convertTo12HourFormat(item.toTime) }}
                        </span>
                      </p>
                      <p class="p-0 m-1 d-flex align-items-center">
                        <img
                          src="../../assets/icons/Calendar.png"
                          alt="Calendar"
                          class="mt-1"
                          style="width: 15px; height: 15px"
                        />
                        <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                          {{ reverseDateFormat(item.date) }}
                        </span>
                      </p>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </div>
      <!-- Content for section 2 -->
    </div>
  
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    name: "HRDashboard",
    props: {
      SelectedEmployeeId: {
        type: Number,
        required: true,
      },
    },
    components: {},
    data() {
      return {
      
        todayTasks: [],
        todayEvents: [],
        currentDay: "",
        formattedDate: "",
        fullName: "",
        greeting: "",
        Employees: [],
        selectedEmployeeId: this.SelectedEmployeeId,
        showRejectionModal: false,
     
      };
    },
    mounted() {
      this.selectedEmployeeId = this.SelectedEmployeeId;
      this.setCurrentDate();
      this.fetchTodayTasks();
      this.fetchTodayEvents();
      this.fetchTodayReminders();

    },
  
    watch: {
      SelectedEmployeeId(newVal) {
        this.selectedEmployeeId = newVal;
    
        this.fetchTodayTasks();
        this.fetchTodayReminders();
        this.fetchTodayEvents();
      },
    },
  
    computed: {
  
      priorityButtonClass() {
        return (task) => {
          // Determine which class to apply based on data.priority
          switch (task.priority) {
            case "Low":
              return "btn-priority-low"; // Define this class in your CSS
            case "Medium":
              return "btn-priority-medium"; // Define this class in your CSS
            case "High":
              return "btn-priority-high"; // Define this class in your CSS
            default:
              return ""; // Default class if no priority is set or unknown priority
          }
        };
      },
      titleBackgroundColorClass() {
        return (priority) => {
          if (priority === "Low") {
            return "priority-low";
          } else if (priority === "Medium") {
            return "priority-medium";
          } else if (priority === "High") {
            return "priority-high";
          }
          return "";
        };
      },
    },
  
    methods: {
  
      changeView(view) {
        this.currentView = view; // Update the currentView when changing the view
        if (view === "year") {
          // Navigate to the calendar page with the current year
          const currentYear = new Date().getFullYear();
          this.$router.push({ name: "Calendar", params: { year: currentYear } });
        } else if (view === "month") {
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth() + 1; // Month index starts from 0, so add 1
          this.$router.push({
            name: "Month",
            params: { year: currentYear, month: currentMonth },
          });
        } else if (view === "day") {
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth() + 1;
          const currentDay = currentDate.getDate();
          this.$router.push({
            name: "Day",
            params: { year: currentYear, month: currentMonth, day: currentDay },
          });
        }
      },
      async fetchTodayTasks() {
        try {
          const response = await axios.get(
            `${this.$apiEndPoint}/tasks/dashboardTasks/${this.selectedEmployeeId}`
          );
          this.todayTasks = response.data;
        } catch (error) {
          this.$handleError(error);
        }
      },
      openTaskModal(taskId) {
      this.$emit("task-selected", taskId);
      console.log(taskId);
    },
  
      async fetchTodayReminders() {
        try {
          const response = await axios.get(
            `${this.$apiEndPoint}/reminders/dashboardReminder/${this.selectedEmployeeId}`
          );
          this.todayReminders = response.data;
        } catch (error) {
          this.$handleError(error);
        }
      },
      async fetchTodayEvents() {
        try {
          const response = await axios.get(
            `${this.$apiEndPoint}/events/dashboardEvents/${this.selectedEmployeeId}`
          );
          this.todayEvents = response.data;
        } catch (error) {
          this.$handleError(error);
        }
      },
  
      convertTo12HourFormat(timeString) {
        // Split the time string into hours and minutes
        const [hours, minutes] = timeString.split(":");
  
        // Convert hours to a number
        let hour = parseInt(hours, 10);
  
        // Determine if it's AM or PM
        const period = hour >= 12 ? "PM" : "AM";
  
        // Convert hours to 12-hour format
        hour = hour % 12 || 12;
  
        // Return the formatted time string
        return `${hour.toString().padStart(2, "0")}:${minutes} ${period}`;
      },
  
    
  
      reverseDateFormat(date) {
        const [year, month, day] = date.split("-");
        return `${day}-${month}-${year}`;
      },
  
      setCurrentDate() {
        const date = new Date();
        this.currentDay = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
          date
        );
        const options = { day: "numeric", month: "long", year: "numeric" };
        this.formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
      },
  
    },
  };
  </script>
  <style scoped>
  </style>
  