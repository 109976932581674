<template>
  <div>
    <!-- Modal -->
    <div
      class="modal fade"
      id="AddOnSiteCategory"
      tabindex="-1"
      aria-labelledby="AddOnSiteLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-header text-center w-100">
            <h5 class="modal-title w-100" id="AddOnSiteLabel">Add New Category</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form @submit.prevent="submitForm">
              <div class="mb-3 row">
                <label for="workDescription" class="col-sm-4 col-form-label"
                  >Category Name</label
                >
                <div class="col-sm-8">
                  <input
                    v-model="form.categoryName"
                    type="text"
                    class="form-control"
                    id="workDescription"
                    placeholder="Enter work description"
                  />
                </div>
              </div>

              <div class="modal-footer d-flex justify-content-center">
                <button
                  v-if="!form.id"
                  type="submit"
                  class="btn btn-primary btn-sm"
                  @click="AddOnSite"
                  data-bs-dismiss="modal"
                >
                  Submit
                </button>
                <button
                  v-else
                  type="submit"
                  class="btn btn-primary btn-sm"
                  @click="UpdateActivity"
                  data-bs-dismiss="modal"
                >
                  Update
                </button>
                <button
                  v-if="form.id"
                  type="button"
                  class="btn btn-danger btn-sm"
                  @click="DeleteTimeLine"
                  data-bs-dismiss="modal"
                >
                  Delete
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "AddOnSiteModal",
  props: {
    categoryId: {
      type: Number,
      required: true,
    },
    contractorId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localQuotationId: null,
      onSiteQuotation: null,
      form: {
        id: null,
        categoryName: "",
      },
    };
  },
  mounted() {},
  watch: {
    categoryId(newValue) {
      this.localQuotationId = newValue;
      if (newValue !== null) {
        this.fetchCategoryById();
        console.log(this.localQuotationId);
      } else {
        this.form = {};
      }
    },
  },
  methods: {
    fetchCategoryById() {
      console.log(this.localQuotationId);
      if (this.localQuotationId !== "null") {
        axios
          .get(`${this.$apiEndPoint}/OnSiteCategory/${this.localQuotationId}`)
          .then((response) => {
            this.form = response.data;
            console.log(response.data);
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } else {
        this.form = {};
        console.log(this.form);
      }
    },

    async AddOnSite() {
      console.log(this.form);
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/OnSiteCategory/`,
          this.form
        );
        this.form.categoryName = " ";
        console.log(response.data);
        this.$emit("fetchCategories");
      } catch (error) {
        this.$handleError(error);
      }
    },
    async UpdateActivity() {
      try {
        await axios.put(
          `${this.$apiEndPoint}/OnSiteCategory/${this.localQuotationId}`,
          this.form
        );
        console.log(this.form);
        this.$emit("fetchCategories");
      } catch (error) {
        this.$handleError(error);
      }
    },
    async DeleteTimeLine() {
      try {
        await axios.delete(
          `${this.$apiEndPoint}/OnSiteCategory/${this.localQuotationId}`
        );
        this.$emit("fetchTimeLineData");
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}
label,
input {
  font-size: 13px;
}

.employee-dropdown {
  position: absolute;
  display: block;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  border-radius: 5px;
  overflow-y: auto;
  z-index: 1000;
  margin-left: 28%;
  /* Ensure the dropdown appears above other elements */
  width: calc(100% - 28%); /* Adjust width to match the input */
  color: #000000;
  top: 20%; /* Position the dropdown below the input */
  left: 0; /* Align the left edge of the dropdown with the left edge of the input */
}

.employee-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.employee-dropdown ul li {
  padding: 8px 10px;
  cursor: pointer;
}

.employee-dropdown ul li:hover {
  background-color: #f0f0f0;
}
</style>
