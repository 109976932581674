<template>
  <div
    class="modal fade"
    id="OnSiteOutwordEntriesModal"
    tabindex="-1"
    aria-labelledby="OnSiteOutwordEntriesModalLabel"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="min-width: 80%; overflow: auto"
    >
      <div class="modal-content">
        <div class="modal-header" style="margin-bottom: 0">
          <div class="d-flex justify-content-between align-items-center w-100 flex-wrap">
            <h6 class="modal-title">Inward Entry</h6>
            <div class="d-flex align-items-center mb-2 mb-md-0 me-2">
              <label for="date" class="col-form-label text-nowrap me-2">Date:</label>
              <input
                type="date"
                class="form-control p-1"
                v-model="form.date"
                style="max-width: 150px"
              />
            </div>
            <div class="d-flex align-items-center mb-2 mb-md-0">
              <label for="projectName" class="col-form-label text-nowrap me-2">
                From Store:
              </label>
              <input
                type="text"
                class="form-control p-1"
                v-model="form['fromStores.storeName']"
                style="max-width: 200px"
              />
            </div>
            <img
              src="../../assets/icons/cancel.png"
              alt="Close"
              style="width: 20px; height: 20px; cursor: pointer"
              data-bs-dismiss="modal"
            />
          </div>
        </div>
        <div class="modal-body" style="max-height: 500px; overflow: auto">
          <table
            class="table files-table table-responsive"
            style="background-color: #ccc"
          >
            <caption class="d-none">
              Product Details
            </caption>
            <thead>
              <tr>
                <th>S/N</th>
                <th>Product Name</th>
                <th>Sent Qty</th>
                <th>Received Qty</th>
                <th>Damaged Qty</th>
                <th>Missing Qty</th>
                <th>Remark</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, index) in Rows"
                :key="index"
                @mouseover="deleteItems = true"
              >
                <td>{{ index + 1 }}</td>
                <td>{{ item["products.productName"] }}</td>
                <td>
                  <input
                    type="checkbox"
                    v-model="item.checked"
                    @change="updateQuantities(index)"
                  />
                  {{ item.sentQuantity }}
                </td>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    v-model="item.receivedQuantity"
                    :disabled="item.checked"
                    @input="validateInput(index, 'receivedQuantity')"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    v-model="item.damagedQuantity"
                    :disabled="item.checked"
                    @input="validateInput(index, 'damagedQuantity')"
                  />
                </td>
                <td>
                  <input
                    class="form-control"
                    type="number"
                    v-model="item.missingQuantity"
                    :disabled="item.checked"
                    @input="validateInput(index, 'missingQuantity')"
                  />
                </td>
                <td>
                  <input
                    type="text"
                    v-model="item.remark"
                    :disabled="item.checked"
                    class="form-control"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            type="button"
            class="browse"
            @click="CreateOutWordEntry"
            data-bs-dismiss="modal"
          >
            Save
          </button>
          <button type="button" class="cancelButton" data-bs-dismiss="modal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OnSiteOutwordEntriesModal",
  props: {
    FromStore: {
      type: Number,
      required: true,
    },
    InwardEntryId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      localInwardEntryId: null,
      InwardEntrieStatus: null,
      Rows: [
        {
          productId: "",
          sentQuantity: 0,
          receivedQuantity: 0,
          damagedQuantity: 0,
          missingQuantity: 0,
          remark: "",
        },
      ],
      deleteItems: false,
      Projects: [],
      Products: [],
      form: {
        date: new Date().toISOString().slice(0, 10),
        fromStore: null,
        toStore: null,
        status: "Accepted",
      },
    };
  },
  mounted() {
    console.log(this.FromStore);
  },

  watch: {
    FromStore(newValue) {
      this.localQuotationId = newValue;
      console.log(this.localQuotationId);
      this.form.fromStore = this.localQuotationId;

      console.log(this.form.fromStore);
    },
    InwardEntryId(newValue) {
      this.localInwardEntryId = newValue;
      console.log(this.localInwardEntryId);
      this.featchEntriesById(this.localInwardEntryId);
      console.log(this.localInwardEntryId);
    },
  },
  methods: {
    updateQuantities(index) {
      if (this.Rows[index].checked) {
        this.Rows[index].receivedQuantity = this.Rows[index].sentQuantity;
        this.Rows[index].damagedQuantity = 0;
        this.Rows[index].missingQuantity = 0;
        this.Rows[index].remark = "";
      } else {
        // Ensure receivedQuantity does not exceed order sentQuantity
        if (this.Rows[index].receivedQuantity > this.Rows[index].sentQuantity) {
          this.Rows[index].receivedQuantity = this.Rows[index].sentQuantity;
        }

        // Ensure the sum of received, damaged, and missing quantities equals the order sentQuantity
        let totalQty = this.Rows[index].sentQuantity;
        let currentTotal =
          this.Rows[index].receivedQuantity +
          this.Rows[index].damagedQuantity +
          this.Rows[index].missingQuantity;

        if (currentTotal > totalQty) {
          // Adjust damagedQuantity and missingQuantity if the current total exceeds the order sentQuantity
          this.Rows[index].damagedQuantity = 0;
          this.Rows[index].missingQuantity = 0;
          currentTotal = this.Rows[index].receivedQuantity;

          if (currentTotal > totalQty) {
            this.Rows[index].receivedQuantity = totalQty;
          }
        }

        if (currentTotal < totalQty) {
          // Distribute the remaining sentQuantity to receivedQuantity
          this.Rows[index].receivedQuantity =
            totalQty -
            this.Rows[index].damagedQuantity -
            this.Rows[index].missingQuantity;
        }
      }
    },
    async featchEntriesById(InwardEntryId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/inwardOutwardEntries/${InwardEntryId}`
        );
        this.form = response.data;
        this.InwardEntrieStatus = response.data.status;
        console.log(this.InwardEntrieStatus);
        this.Rows = response.data.Lineitems;
        console.log(response.data.Lineitems);

        console.log(this.Rows);
      } catch (error) {
        this.$handleError(error);
      }
    },
    validateInput(index, field) {
      let item = this.Rows[index];
      let totalQty = item.sentQuantity;
      let receivedQuantity = item.receivedQuantity;
      let damagedQuantity = item.damagedQuantity;
      let missingQuantity = item.missingQuantity;

      // Ensure receivedQuantity does not exceed order sentQuantity
      if (field === "receivedQuantity" && receivedQuantity > totalQty) {
        item.receivedQuantity = totalQty;
        receivedQuantity = totalQty;
      }

      // Ensure the sum of received, damaged, and missing quantities does not exceed the order sentQuantity
      let sumQty = receivedQuantity + damagedQuantity + missingQuantity;
      if (sumQty > totalQty) {
        let diff = sumQty - totalQty;
        if (field === "receivedQuantity") {
          item.receivedQuantity -= diff;
        } else if (field === "damagedQuantity") {
          item.damagedQuantity -= diff;
        } else if (field === "missingQuantity") {
          item.missingQuantity -= diff;
        }
      }
    },

    CreateOutWordEntry() {
      let dataToSend = {
        date: this.form.date,
        fromStore: this.form.fromStore,
        toStore: this.form.toStore,
        status: "Accepted",
        Rows: this.Rows.map((item) => ({
          id: item.id,
          productId: item.productId,
          sentQuantity: item.sentQuantity,
          receivedQuantity: item.receivedQuantity,
          damagedQuantity: item.damagedQuantity,
          missingQuantity: item.missingQuantity,
          remark: item.remark,
        })),
      };
      console.log(dataToSend);
      if (this.InwardEntrieStatus === "Sent") {
        console.log(this.localInwardEntryId);
        // Send this.form to the server
        axios
          .put(
            `${this.$apiEndPoint}/inwardOutwardEntries/acceptInward/${this.localInwardEntryId}`,
            dataToSend
          )
          .then((response) => {
            this.$emit("fetchInwardEntries");
            console.log("Entry created successfully:", response.data);
            this.form = {};
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } else {
        axios
          .put(
            `${this.$apiEndPoint}/inwardOutwardEntries/acceptInward/${this.localInwardEntryId}`,
            dataToSend
          )
          .then((response) => {
            this.$emit("fetchInwardEntries");

            console.log("Entry Updated successfully:", response.data);
            this.form = {};
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
}
.selected-employee {
  margin-bottom: 5px;
}
.remove-employee {
  cursor: pointer;
  margin-left: 5px;
}
label {
  font-weight: 500;
  color: black;
}

.modal-body {
  max-height: 500px;
  overflow-y: auto;
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #dee2e6;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
}

.files-table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.files-table th,
.files-table td {
  text-align: left;
  padding: 6px;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
}

.files-table th {
  background-color: #bcbfc6;
}

.files-table tr:hover {
  background-color: #f5f5f5;
}

.files-table th:nth-child(2),
.files-table td:nth-child(2) {
  width: 30%;
  text-align: left;
}

.files-table th:nth-child(7),
.files-table td:nth-child(7) {
  width: 20%;
  text-align: left;
}

@media (max-width: 768px) {
  .modal-header .d-flex {
    flex-wrap: wrap;
    gap: 10px;
  }

  .modal-header label {
    white-space: nowrap;
  }
}
</style>
