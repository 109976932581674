<template>
    <div class="modal fade" id="bulkUploadPreviewModal"  aria-labelledby="bulkUploadPreviewModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-fullscreen">
        <div class="modal-content ">
          <div class="modal-header">
            <h5 class="modal-title" id="bulkUploadPreviewModalLabel">Bulk Leads Upload Preview</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <!-- Display preview data here -->
            <pre>{{ formattedData }}</pre>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="createBulkLeads">Done</button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: {
      data: Object
    },
    data() {
      return {
        formattedData: []
      };
    },
    mounted() {
      this.formatData();
    },
    methods: {
      formatData() {
        // Add any necessary formatting to the data before displaying
        // For example, you might want to stringify the object or format dates
        this.formattedData = JSON.stringify(this.data, null, 2);
      },
      createBulkLeads() {
        // Call the API to create bulk leads with this.data
        // Close the modal on success
        // Emit an event to inform the parent component about the success or failure
        // this.$emit('bulkLeadsCreated', /* Pass any relevant data here */);
        // $('#bulkUploadPreviewModal').modal('hide');
      }
    }
  };
  </script>
  