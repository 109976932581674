
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export const showToast = (message, type ) => {
  toast(message, {
    theme: 'auto',
    type: type,
    "autoClose": 3000,
    "position": "bottom-right",
    "transition": "bounce",
    dangerouslyHTMLString: true

  });
};
// https://vue3-toastify.js-bridge.com/ the Toast source code.