<template>
  <div
    class="modal fade my-4"
    id="exampleModal1"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="createLeadForm">
      <div class="modal-dialog modal-dialog-scrollable modal-lg">
        <!-- Change modal-dialog class to modal-lg for 50% width -->
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title btn btn-primary btn-sm" id="exampleModalLabel">
              Customer Details
            </h5>

            <img
              src="../../assets/icons/cancel.png"
              alt=""
              style="
                width: 20px;
                height: 20px;
                position: absolute;
                right: 10px;
                top: 2.5%;
                cursor: pointer;
              "
              data-bs-dismiss="modal"
              aria-label="Close"
            />
          </div>
          <div class="modal-body">
            <div class="row d-flex align-content-center">
              <div class="col-md-6">
                <div class="form-group row mt-1">
                  <label for="salutation" class="col-md-4 col-form-label text-nowrap"
                    >Name :</label
                  >
                  <div class="col-md-3">
                    <select
                      id="salutation"
                      class="form-control inputBg"
                      v-model="leadData.salutation"
                    >
                      <option value="">Select</option>
                      <option value="Mr.">Mr.</option>
                      <option value="Mrs.">Mrs.</option>
                      <option value="Mx.">Mx.</option>
                      <!-- Gender-neutral title for transgender individuals -->
                      <option value="Other">Other</option>
                    </select>
                  </div>
                  <div class="col-md-5">
                    <input
                      type="text"
                      id="name"
                      class="form-control inputBg"
                      v-model="leadData.name"
                    />
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label for="email" class="col-md-4 col-form-label text-nowrap"
                    >Email:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="email"
                      id="email"
                      class="form-control inputBg"
                      v-model="leadData.email"
                    />
                    <small
                      v-if="leadData.email && !isValidEmail(leadData.email)"
                      class="text-danger"
                      >Please enter a valid email address.</small
                    >
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label for="residentialAddress" class="col-md-4 col-form-label"
                    >Residential Address:</label
                  >
                  <div class="col-md-8">
                    <textarea
                      id="residentialAddress"
                      class="form-control p-2 inputBg"
                      style="min-height: 50px; vertical-align: top"
                      v-model="ResidentialData.address"
                    ></textarea>
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label for="residentialCity" class="col-md-4 col-form-label text-nowrap"
                    >City:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      id="residentialCity"
                      class="form-control inputBg"
                      v-model="ResidentialData.city"
                    />
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label
                    for="residentialState"
                    class="col-md-4 col-form-label text-nowrap"
                    >State:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      id="residentialState"
                      class="form-control inputBg"
                      v-model="ResidentialData.state"
                    />
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group row mt-1">
                  <label for="contactNo" class="col-md-4 col-form-label text-nowrap"
                    >Contact No:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="tel"
                      id="contactNo"
                      class="form-control inputBg"
                      v-model="leadData.contactNo"
                      pattern="[0-9]{10}"
                    />
                    <small
                      v-if="
                        leadData.contactNo && !isValidMobileNumber(leadData.contactNo)
                      "
                      class="text-danger"
                      >Please enter a valid 10-digit mobile number.</small
                    >
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label for="gender" class="col-md-4 col-form-label text-nowrap"
                    >Gender :</label
                  >
                  <div class="col-md-8">
                    <select
                      id="gender"
                      class="form-control inputBg"
                      v-model="leadData.gender"
                    >
                      <option value="">Select Gender</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </div>
                </div>

                <div class="form-group row mt-1">
                  <label for="propertyAddress" class="col-md-4 col-form-label"
                    >Property Address:</label
                  >
                  <div class="col-md-8">
                    <textarea
                      id="propertyAddress"
                      class="form-control p-2 inputBg"
                      style="min-height: 50px; vertical-align: top"
                      v-model="PropertyData.address"
                    ></textarea>
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label for="propertyCity" class="col-md-4 col-form-label text-nowrap"
                    >City:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      id="propertyCity"
                      class="form-control inputBg"
                      v-model="PropertyData.city"
                    />
                  </div>
                </div>
                <div class="form-group row mt-1">
                  <label for="propertyState" class="col-md-4 col-form-label text-nowrap"
                    >State:</label
                  >
                  <div class="col-md-8">
                    <input
                      type="text"
                      id="propertyState"
                      class="form-control inputBg"
                      v-model="PropertyData.state"
                    />
                  </div>
                </div>
                
              </div>
            </div>

            <section class="subHeadingsOfLeads">
              <div
                class="mt-2 mb-2 p-1 subHeaderHeadingBgColor subHeadingsOfLead"
                style="border-radius: 5px"
              >
                <p
                  class="ms-2"
                  :class="{
                    'active-SubHeading': showOtherDetails,
                    'inactive-SubHeading': !showOtherDetails,
                  }"
                  @click="showDetails('other')"
                >
                  Other Details
                </p>
                <p
                  :class="{
                    'active-SubHeading': showAddressDetails,
                    'inactive-SubHeading': !showAddressDetails,
                  }"
                  @click="showDetails('address')"
                >
                  Address
                </p>
                <p
                  :class="{
                    'active-SubHeading': showComments,
                    'inactive-SubHeading': !showComments,
                  }"
                  @click="showDetails('comments')"
                >
                  Comments
                </p>
              </div>
              <div class="subSectionOfLeads col-md-12">
                <div v-show="showOtherDetails" class="sectionOther col-md-12">
                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="dob"
                      >Date Of Birth:</label
                    >
                    <div class="col-md-6">
                      <input
                        type="date"
                        class="form-control w-100 text-black inputBg"
                        id="dob"
                        v-model="leadData.dob"
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="secondaryContactName"
                      >Secondary Contact Name:</label
                    >
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control w-100 inputBg"
                        id="secondaryContactName"
                        v-model="leadData.secondaryContactName"
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="secondaryContactNumber"
                      >Secondary Contact Number:</label
                    >
                    <div class="col-md-6">
                      <input
                        type="tel"
                        class="form-control w-100 inputBg"
                        id="secondaryContactNumber"
                        v-model="leadData.secondaryContactNumber"
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="secondaryContactEmail"
                      >Secondary Contact Email Id:</label
                    >
                    <div class="col-md-6">
                      <input
                        type="email"
                        class="form-control w-100 inputBg"
                        id="secondaryContactEmail"
                        v-model="leadData.secondaryContactEmail"
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="aadhaarNo"
                      >Aadhar Number:</label
                    >
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control inputBg"
                        id="aadhaarNo"
                        v-model="leadData.aadhaarNo"
                      />
                    </div>
                  </div>

                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="occupation"
                      >Occupation:</label
                    >
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control inputBg"
                        id="occupation"
                        v-model="leadData.occupation"
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="propertyType"
                      >Property Type:</label
                    >
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control inputBg"
                        id="propertyType"
                        v-model="leadData.propertyType"
                      />
                    </div>
                  </div>

                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="gstNumber">GST No:</label>
                    <div class="col-md-6">
                      <input
                        type="text"
                        class="form-control inputBg"
                        id="gstNumber"
                        v-model="leadData.GSTNumber"
                      />
                    </div>
                  </div>
                  <div class="form-group row mt-1">
                    <label class="col-md-4 col-form-label" for="floorPlan"
                      >Upload Floor Plan:</label
                    >
                    <div class="col-md-6">
                      <input
                        type="file"
                        class="form-control inputBg"
                        id="floorPlan"
                        @change="handleFileUpload(this.leadData.leadId)"
                      />
                      <a :href="floorPlan" target="_blank" class="mt-1">
                        {{ getDownloadFilename(floorPlanfileName) }}
                      </a>
                    </div>
                  </div>
                </div>
                <div v-show="showAddressDetails" class="sectionAddress row col-md-12">
                  <section class="col-md-6">
                    <h5 class="text-primary e-underline d-flex text-nowrap">
                      Billing Address
                    </h5>
                    <div class="billingForm">
                      <span>
                        <label for="billingCompanyName">Name:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="billingCompanyName"
                          v-model="billingAddressData.name"
                        />
                      </span>
                      <span>
                        <label for="billingAddress">Address:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="billingAddress"
                          v-model="billingAddressData.address"
                        />
                      </span>
                      <span>
                        <label for="billingState">State:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="billingState"
                          v-model="billingAddressData.state"
                        />
                      </span>
                      <span>
                        <label for="billingCity">City:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="billingCity"
                          v-model="billingAddressData.city"
                        />
                      </span>
                      <span>
                        <label for="billingPincode">Pincode:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="billingPincode"
                          v-model="billingAddressData.pincode"
                        />
                      </span>
                      <span>
                        <label for="billingPhoneNo">Phone No:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="billingPhoneNo"
                          v-model="billingAddressData.contactNo"
                        />
                      </span>
                      <span>
                        <label for="billingEmailId">Email ID:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="billingEmailId"
                          v-model="billingAddressData.email"
                        />
                      </span>
                    </div>
                  </section>
                  <section class="col-md-6">
                    <h5
                      class="text-primary e-underline d-flex align-content-center text-nowrap"
                    >
                      Shipping Address
                      <span style="font-size: 13px; margin-left: 10px">
                        <input
                          type="checkbox"
                          id="copyAddress"
                          class="form-check-input m-1"
                          v-model="copyAddress"
                          @change="copyBillingToShipping"
                        />
                        <label
                          class="form-check-label text-wrap text-black"
                          for="copyAddress"
                        >
                          Copy Address same as Billing Address
                        </label>
                      </span>
                    </h5>
                    <div class="shippingForm">
                      <span>
                        <label for="shippingCompanyName">Name:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="shippingCompanyName"
                          v-model="addressData.name"
                        />
                      </span>
                      <span>
                        <label for="shippingAddress">Address:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="shippingAddress"
                          v-model="addressData.address"
                        />
                      </span>
                      <span>
                        <label for="shippingState">State:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="shippingState"
                          v-model="addressData.state"
                        />
                      </span>
                      <span>
                        <label for="shippingCity">City:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="shippingCity"
                          v-model="addressData.city"
                        />
                      </span>
                      <span>
                        <label for="shippingPincode">Pincode:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="shippingPincode"
                          v-model="addressData.pincode"
                        />
                      </span>
                      <span>
                        <label for="shippingPhoneNo">Phone No:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="shippingPhoneNo"
                          v-model="addressData.contactNo"
                        />
                      </span>
                      <span>
                        <label for="shippingEmailId">Email ID:</label>
                        <input
                          type="text"
                          class="form-control inputBg"
                          id="shippingEmailId"
                          v-model="addressData.email"
                        />
                      </span>
                    </div>
                  </section>
                </div>
                <div v-show="showComments" class="sectionComments">
                  <section>
                    <label for="leadComments">Comments:</label>
                    <textarea
                      id="leadComments"
                      class="form-control inputBg"
                      cols="30"
                      rows="5"
                      placeholder="Add Text....."
                      v-model="leadData.comment"
                    ></textarea>
                  </section>
                </div>
              </div>
            </section>

            <footer class="mt-4 d-flex justify-content-center align-content-center">
              <button
                v-if="selectedLeadId"
                class="btn btn-primary"
                @click="submitLeadData"
                :disabled="!isFormValid()"
                data-bs-dismiss="modal"
              >
                Update
              </button>
              <button
                v-else
                class="btn btn-primary"
                @click="submitLeadData"
                :disabled="!isFormValid()"
                data-bs-dismiss="modal"
              >
                Save
              </button>
            </footer>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "CreateLead",
  props: {
    defaultSourceId: {
      type: String, // Change the type accordingly
      default: "", // Default value if not provided
    },
    selectedLeadId: {
      type: String, // Change the type according to your ID data type
      required: true,
    },
  },
  components: {},
  data() {
    return {
      SalesPersons: [],
      selectedSalesPerson: "",
      properties: [],
      selectedProperty: "",
      showDropdown1: false,
      showDropdown: false,
      showOtherDetails: true,
      showAddressDetails: false,
      showComments: false,
      source: ["Direct", "Agent"],
      leadData: {
        salutation: "",
        name: "",
        email: "",
        residentialAddress: "",
        residentialCity: "",
        residentialState: "",
        propertyAddress: "",
        propertyCity: "",
        propertyState: "",
        contactNo: "",
        priority: "",
        propertyName: "",
        gender: "",
        dob: null,
        secondaryContactName: "",
        secondaryContactNumber: "",
        secondaryContactEmail: "",
        aadhaarNo: "",
        occupation: "",
        propertyType: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        salesPersonId: null,
        propertyId: null,
        source: "",
        GSTNumber: "",
        comment: "",
        sourceId: "",
      },
      billingAddressData: {
        id: null,
        name: "", // Your initial companyName value
        email: "",
        address: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
        contactNo: "",
        addressType: "Billing", // Or use a variable if it varies
        entityId: this.SelectedLeadId,
        entityName: "Customer",
      },
      addressData: {
        id: null,
        name: "", // Your initial companyName value
        email: "",
        address: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
        contactNo: "",
        addressType: "Shipping",
        entityId: this.SelectedLeadId,
        entityName: "Customer",
      },
      PropertyData: {
        id: null,
        address: "",
        city: "",
        state: "",
        addressType: "Property",
        entityId: this.SelectedLeadId,
        entityName: "Customer",
      },
      ResidentialData: {
        id: null,
        address: "",
        city: "",
        state: "",
        addressType: "Residential",
        entityId: this.SelectedLeadId,
        entityName: "Customer",
      },
      copyAddress: false,
      SelectedLeadId: "",
    };
  },
  /*  computed: {
      filteredSalesPersons() {
        const searchTerm = this.selectedSalesPerson.toLowerCase();
        return this.SalesPersons.filter(salesPerson => {
          const fullName = `${salesPerson["users.firstname"]} ${salesPerson["users.lastname"]}`.toLowerCase();
          return fullName.includes(searchTerm);
        });
      },
      filteredProperties() {
        const searchTerm = this.selectedProperty.toLowerCase();
        return this.properties.filter(property => {
          return property.name.toLowerCase().includes(searchTerm);
        });
      },
    }, */

  mounted() {
    this.SelectedLeadId = this.selectedLeadId;

    this.leadData.sourceId = this.defaultSourceId;
    if (this.selectedLeadId !== null) {
      this.fetchLeadDetails();
    }
  },
  watch: {
    selectedLeadId(newValue) {
      if (newValue) {
        this.SelectedLeadId = newValue;
        this.fetchLeadDetails();
      }
    },
  },
  methods: {
    getDownloadFilename(url) {
      return url;
    },
    handleFileUpload(LeadId) {
      const file = document.getElementById("floorPlan").files[0]; // Get the selected file
      if (LeadId && file) {
        // Make API call to get signed URL for file upload
        axios
          .post(`${this.$apiEndPoint}/company/putFileSignedURL`, {
            fileName: file.name,
            fileSize: file.size,
            contentType: file.type,
            fileCategory: "floorPlan",
            moduleName: "sales",
            entityId: LeadId,
            entityName: "lead",
          })
          .then((response) => {
            const signedUrl = response.data.s3URL;

            // Use XMLHttpRequest to upload the file to the signed URL
            const xhr = new XMLHttpRequest();
            xhr.open("PUT", signedUrl, true);
            xhr.setRequestHeader("Content-Type", file.type);

            xhr.onreadystatechange = () => {
              if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                  this.getDownloadFilename(file.name);
                } else {
                  console.error("Error uploading profile photo to S3:", xhr.responseText);
                }
              }
            };

            // Send the file as binary data
            xhr.send(file);
          })
          .catch(() => {});
      }
    },
    featchLeadfloorPlan(LeadId) {
      const PayLoad = {
        entityId: LeadId,
        fileCategory: "floorPlan",
        moduleName: "sales",
      };

      // Make API request to fetch employee profile photo URL
      axios
        .post(`${this.$apiEndPoint}/company/getFileSignedURL/`, PayLoad)
        .then((response) => {
          this.floorPlan = Object.values(response.data)[0];
          this.floorPlanfileName = Object.keys(response.data)[0]; // Extract the key (filename) from the object
          this.getDownloadFilename(this.floorPlanfileName); // Pass the filename to your method
        })
        .catch(() => {});
    },
    isFormValid() {
      // Check if mobile number is valid
      const isMobileValid = this.leadData.contactNo
        ? this.isValidMobileNumber(this.leadData.contactNo)
        : false;

      return isMobileValid;
    },

    isValidMobileNumber(number) {
      const mobileRegex = /^\d{10}$/;
      return mobileRegex.test(number);
    },
    // Validate email
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    async updateLeadAddresses() {
      try {
        // Execute multiple API calls concurrently using Promise.all
        await Promise.all([
          // Update or create billing address
          this.updateOrCreateAddress("Billing", this.billingAddressData),
          // Update or create shipping address
          this.updateOrCreateAddress("Shipping", this.addressData),
          // Update or create property address
          this.updateOrCreateAddress("Property", this.PropertyData),
          // Update or create residential address
          this.updateOrCreateAddress("Residential", this.ResidentialData),
        ]);

        location.reload();
      } catch (error) {
        location.reload();
      }
    },

    async updateOrCreateAddress(addressType, addressData) {
      if (addressData.id != null) {
        // If the address ID exists, update the address
        await axios.put(
          `${this.$apiEndPoint}/address/${this.selectedLeadId}/Customer/${addressType}`,
          addressData
        );
      } else {
        // If the address ID does not exist (null), create a new address
        await axios.post(`${this.$apiEndPoint}/address`, {
          ...addressData,
          addressType: addressType,
          entityId: this.selectedLeadId,
          entityName: "Customer",
        });
      }
    },

    async fetchLeadDetails() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/customer/getCustomer/${this.SelectedLeadId}`
        );
        this.leadData = response.data;

        // Fetch lead floor plan and addresses in parallel
        await Promise.all([
          this.fetchLeadAddresses(this.leadData.id),
          this.featchLeadfloorPlan(this.leadData.leadId),
        ]);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchLeadAddresses(leadId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/address/Customer/${leadId}`
        );
        const addresses = response.data;

        const addressTypes = {
          Billing: "billingAddressData",
          Shipping: "addressData",
          Residential: "ResidentialData",
          Property: "PropertyData",
        };

        addresses.forEach((addr) => {
          const addressKey = addressTypes[addr.addressType];
          if (addressKey) {
            this[addressKey] = {
              ...this[addressKey],
              id: addr.id,
              name: addr.name || "",
              address: addr.address,
              city: addr.city,
              state: addr.state,
              country: addr.country || "",
              pincode: addr.pincode || "",
              contactNo: addr.contactNo || "",
              email: addr.email || "",
              entityId: addr.entityId,
            };
          }
        });
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchSalesPersons() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/employee`);
        this.SalesPersons = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    searchSalesPerson() {
      this.showDropdown1 = this.selectedSalesPerson.trim() !== "";
    },
    selectSalesPerson(salesPerson) {
      this.selectedSalesPerson = salesPerson["users.firstname"];
      this.leadData.salesPersonId = salesPerson.id;
      this.showDropdown1 = false;
    },

    searchProperties() {
      this.showDropdown = this.selectedProperty.trim() !== "";
    },
    selectProperty(property) {
      this.selectedProperty = property.name;
      this.leadData.propertyId = property.id;
      this.showDropdown = false;
    },

    addCustomSource() {
      const newSource = prompt("Enter new source:");
      if (newSource) {
        this.source.push(newSource);
      }
    },
    showDetails(section) {
      this.showOtherDetails = false;
      this.showAddressDetails = false;
      this.showComments = false;

      // Set the specific section to true based on the button clicked
      if (section === "other") {
        this.showOtherDetails = true;
      } else if (section === "address") {
        this.showAddressDetails = true;
      } else if (section === "comments") {
        this.showComments = true;
      }
    },
    async submitLeadData() {
      try {
        if (this.selectedLeadId) {
          // If selectedLeadId is present, update the lead
          await this.updateLead();
          await this.updateLeadAddresses();
        } else {
          // If selectedLeadId is not present, create a new lead
          await this.createLead();
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async updateLead() {
      try {
        await axios.put(
          `${this.$apiEndPoint}/customer/${this.selectedLeadId}`,
          this.leadData
        );
        this.$emit("fetchData");

        // Continue with other logic if needed
      } catch (error) {
        this.$handleError(error);
        this.$emit("fetchData");
      }
    },
    async createLead() {
      try {
        const response = await axios.post(`${this.$apiEndPoint}/leads/`, this.leadData);

        const leadId = response.data.id;

        this.billingAddressData.leadId = leadId;

        // Construct shipping address data with the obtained leadId
        this.addressData.leadId = leadId;

        // Post the billing address data
        await axios.post(`${this.$apiEndPoint}/leadsAddress/`, this.billingAddressData);

        // Post the shipping address data
        await axios.post(`${this.$apiEndPoint}/leadsAddress/`, this.addressData);
        // Close the modal after successful submission if needed
        this.$emit("fetchData");
        this.fetchData();
        this.resetLeadData();
      } catch (error) {
        this.$handleError(error);
        this.$emit("fetchData");
      }
    },

    resetLeadData() {
      this.leadData = {
        salutation: "",
        name: "",
        gender: "",
        dob: "",
        aadhaarNo: "",
        occupation: "",
        email: "",
        contactNo: "",
        propertyType: "",
        address: "",
        city: "",
        state: "",
        country: "",
        pincode: "",
        salesPersonId: "",
        propertyId: "",
        source: "",
      };

      this.billingAddressData = {
        companyId: "",
        name: "", // Your initial companyName value
        email: "",
        address: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
        contactNo: "",
        addressType: "", // Or use a variable if it varies
      };

      this.addressData = {
        companyId: "",
        name: "", // Your initial companyName value
        email: "",
        address: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
        contactNo: "",
        addressType: "",
      };
    },

    copyBillingToShipping() {
      if (this.copyAddress) {
        // Copy billing address to shipping address
        this.addressData.name = this.billingAddressData.name;
        this.addressData.address = this.billingAddressData.address;
        this.addressData.state = this.billingAddressData.state;
        this.addressData.city = this.billingAddressData.city;
        this.addressData.pincode = this.billingAddressData.pincode;
        this.addressData.contactNo = this.billingAddressData.contactNo;
        this.addressData.email = this.billingAddressData.email;
      } else {
        // Reset shipping address
        this.addressData.name = "";
        this.addressData.address = "";
        this.addressData.state = "";
        this.addressData.city = "";
        this.addressData.pincode = "";
        this.addressData.contactNo = "";
        this.addressData.email = "";
      }
    },
  },
};
</script>

<style scoped>
.modal-content {
  height: 100%; /* Ensure modal content fills the height */
}

input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}

label {
  font-weight: 500;
  color: black;
  font-size: 12px;
}
input,
textarea,
select {
  border: 1px solid #ccc;
  outline: none !important;
  font-size: 14px;
}

/*
.modal-dialog.modal-lg {
  max-width: 100%; Full width on large screens 
}*/
.createLeadForm {
  min-width: 100%;
  margin-left: 24%;
  font-size: 16px;
  color: #000000;

  height: 100%;
  overflow-y: scroll;
}
.subHeadingsOfLeads p {
  cursor: pointer;
}

.subHeadingsOfLeads p:nth-child(2) {
  margin: 0 10%;
}
.subHeadingsOfLead {
  display: flex;
  align-content: center;
}
.subHeadingsOfLead p {
  margin-bottom: 0 !important;
}
@media (max-width: 767px) {
  .createLeadForm {
    max-width: 100%;
    /* Full width on mobile */
    margin-left: 0;
    /* Reset margin for mobile */
  }
  .subHeadingsOfLeads p:nth-child(2) {
    margin: 0 3%;
  }
}
</style>
.modal { left: 100%; /* Initial position off-screen to the left */ top: 0; width: 100%;
min-height: 100%; z-index: 10500; /* Ensure it overlays other content */ transition: left
0.3s ease; /* Transition effect for left position */ } .modal.show { left: 0; /* Position
the modal to the left edge of the screen */ } .modal-dialog { position: relative; width:
100%; /* Adjust the width as needed */ } .createLeadForm { width: 100%; font-size: 14px;
font-family: "Inter", sans-serif; color: #000000; font-weight: lighter; height: 100%; /*
Set height to 100% */ overflow-y: auto; /* Enable vertical scrolling if content exceeds
viewport height */ } .modal-body { padding: 0; /* Remove default padding to avoid
unnecessary space */ } .modal-dialog.modal-lg { max-width: 100%; /* Full width on large
screens */ } .subHeadingsOfLeads h5:nth-child(2) { margin: 0 10%; } @media (max-width:
767px) { .createLeadForm { max-width: 100%; /* Full width on mobile */ margin-left: 0; /*
Reset margin for mobile */ } .subHeadingsOfLeads h5:nth-child(2) { margin: 0 3%; } }
