<template>
  
  <div class="dashboardcard-container cardsOfDesign">
    <div class="card dashboardcard" @click="goToDesignProjectTableByStatus('New')">
      <div class="d-flex justify-content-between flex-row p-1 m-2">
        <span style="font-size: 24px; font-weight: bold">
          {{ getStatusCount("New") }}</span
        >
        <span>
          <img src="../../assets/icons/newProjects.png" alt="" />
        </span>
      </div>
      <div class="ms-2 p-1">
        <span id="textColorOfCard">New Projects</span>
      </div>
    </div>
    <div class="card dashboardcard" @click="goToDesignProjectTableByStatus('Assigned')">
      <div class="d-flex justify-content-between flex-row p-1 m-2">
        <span style="font-size: 24px; font-weight: bold">
          {{ getStatusCount("Assigned") }}</span
        >
        <span>
          <img src="../../assets/icons/assignedProjects.png" alt="" />
        </span>
      </div>
      <div class="ms-2 p-1">
        <span id="textColorOfCard">Assigned Projects</span>
      </div>
    </div>
    <div
      class="card dashboardcard"
      @click="goToDesignProjectTableByStatus('In Progress')"
    >
      <div class="d-flex justify-content-between flex-row p-1 m-2">
        <span style="font-size: 24px; font-weight: bold">
          {{ getStatusCount("In Progress") }}</span
        >
        <span>
          <img src="../../assets/icons/activeProjects.png" alt="" />
        </span>
      </div>
      <div class="ms-2 p-1">
        <span id="textColorOfCard">Active Projects</span>
      </div>
    </div>
    <div class="card dashboardcard" @click="goToDesignProjectTableByStatus('Completed')">
      <div class="d-flex justify-content-between flex-row p-1 m-2">
        <span style="font-size: 24px; font-weight: bold">
          {{ getStatusCount("Completed") }}</span
        >
        <span>
          <img src="../../assets/icons/completedProjects.png" alt="" />
        </span>
      </div>
      <div class="ms-2 p-1">
        <span id="textColorOfCard">Sent for Execution</span>
      </div>
    </div>
  </div>
  <div class="section WhiteHeadSection">
    <div class="container-fluid contentOfWhiteHeadSection">
      <div
        class="container-fluid d-flex p-0"
        style="width: 96%; position: relative; gap: 3%; padding: 0 4%"
      >
        <div class="LeadChart p-2" style="flex: 1">
          <div class="col-md-12">
            <h6 class="ms-2">
              Active Projects (<span class="text-danger">{{
                this.designProjects.length
              }}</span
              >)
            </h6>
          </div>

          <div class="col-md-12 p-1" style="border-radius: 20px">
            <div
              class="table-responsive"
              style="height: 290px; overflow-y: auto; padding: 0 5px"
            >
              <table class="table">
                <caption class="d-none">
                  In Progress Projects
                </caption>
                <!-- In Progress Projects -->
                <thead class="position-sticky z-1 top-0">
                  <tr>
                    <th></th>
                    <th v-if="$isManager('Designing')">Designer</th>
                    <th>Project Name</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody class="text-nowrap bodyText">
                  <!-- Use v-for to iterate over your data and create rows -->
                  <tr v-for="project in designProjects" :key="project.id">
                    <td>
                      <img
                        v-if="$isManager('Designing')"
                        :src="
                          project['employees.signedUrl']
                            ? project['employees.signedUrl']
                            : '../../assets/icons/profile.png'
                        "
                        alt=""
                        id=""
                        style="width: 20px; height: 20px; border-radius: 50%"
                      />

                      <span v-else>
                        <img
                          v-if="project['designProjectTeam.favourite'] === 0"
                          src="../../assets/icons/blueDotIcon.png"
                          alt=""
                          style="width: 10px; height: 10px"
                        />
                        <img
                          v-else
                          src="../../assets/icons/pinkStarfilled.png"
                          alt=""
                          style="width: 12px; height: 12px"
                        />
                      </span>
                    </td>
                    <td
                      v-if="$isManager('Designing')"
                      @click="navigateToProjectProfile(project)"
                      style="cursor: pointer"
                    >
                      {{ project["employees.firstName"] }}
                    </td>
                    <td @click="navigateToProjectProfile(project)">
                      {{ project.projectName }}
                    </td>
                    <td>{{ project.designStage }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="LeadChart p-2" style="flex: 1">
          <div class="col-md-12">
            <h6 class="ms-2">
              Sign off Document Approval (<span class="text-danger">{{
                this.PendingdesignProjectsDocuments.length
              }}</span
              >)
            </h6>
          </div>
          <div class="col-md-12 p-1" style="border-radius: 20px">
            <div
              class="table-responsive"
              style="height: 290px; overflow-y: auto; padding: 0 5px"
            >
              <table class="table">
                <caption class="d-none">
                  Sign off Document Approval
                </caption>
                <!-- Sign off Document Approval  -->
                <thead class="position-sticky z-1 top-0 text-nowrap">
                  <tr>
                    <th v-if="$isManager('Designing')"></th>
                    <th v-if="$isManager('Designing')">Designer</th>
                    <th>Project Name</th>
                    <th>File Name</th>
                    <th v-if="$isManager('Designing')" class="text-center">Action</th>
                    <th v-if="$isExecutive('Designing')">status</th>
                  </tr>
                </thead>
                <tbody class="text-nowrap bodyText">
                  <!-- Use v-for to iterate over your data and create rows -->
                  <tr v-for="project in PendingdesignProjectsDocuments" :key="project.id">
                    <td v-if="$isManager('Designing')">
                      <img
                        :src="project['employees.signedUrl']"
                        alt=""
                        id=""
                        style="width: 20px; height: 20px; border-radius: 50%"
                      />
                    </td>
                    <td
                      @click="navigateToProjectProfilee(project)"
                      style="cursor: pointer"
                      v-if="$isManager('Designing')"
                    >
                      {{ project["employees.firstName"] }}
                    </td>
                    <td @click="navigateToProjectProfilee(project)">
                      {{ project["designProjects.projectName"] }}
                    </td>
                    <td>
                      {{ project["files.awsFiles.fileName"] }}
                    </td>
                    <td v-if="$isManager('Designing')" class="text-center">
                      <a
                        @click="
                          $viewFile(
                            project['files.awsFiles.awsFileName'],
                            project['files.awsFiles.fileName'],
                            'signOffDocument',
                            'design'
                          )
                        "
                      >
                        <img src="../../assets/icons/view.png" alt="" id="approve" />
                      </a>

                      <img
                        src="../../assets/icons/approve.png"
                        alt=""
                        id="approve"
                        @click="openApprovalModal(project.id)"
                      />
                      <img
                        src="../../assets/icons/reject.png"
                        alt=""
                        id="approve"
                        @click="openRejectModal(project.id)"
                      />
                    </td>
                    <td v-if="$isExecutive('Designing')">
                      {{ project.status }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid contentOfWhiteHeadSection2 mt-3 mb-2">
        <div
          class="container-fluid d-flex p-0"
          style="width: 98%; position: relative; gap: 3%; padding: 0 2%"
        >
          <div style="flex: 1">
            <div class="d-flex flex-column LeadChart2 p-2">
              <div class="row w-100 ms-1">
                <p class="subHeadingHeader p-1 m-0">Today’s Task</p>
                <hr />
              </div>
              <div
                class="card card2 p-2 m-2"
                v-for="task in todayTasks"
                :key="task.id"
                data-bs-toggle="modal"
                data-bs-target="#taskModal"
                @click="openTaskModal(task.id)"
              >
                <div class="d-flex justify-content-between align-items-center">
                  <section class="d-flex align-items-center">
                    <img
                      v-if="task.status === Pending"
                      src="../../assets/icons/taskPending.png"
                      id="titleIcon"
                      alt=""
                      style="width: 17px; height: 17px"
                    />
                    <img
                      v-else
                      src="../../assets/icons/taskCompleted.png"
                      alt="Task Completed"
                      style="width: 17px; height: 17px"
                    />
                    <span class="ms-2" style="font-size: 14px">{{ task.title }}</span>
                  </section>
                  <section class="d-flex align-items-center">
                    <span class="d-flex align-items-center" style="padding: 0 10px">
                      <img
                        src="../../assets/icons/subtask.png"
                        alt="Subtask"
                        style="width: 15px; height: 15px"
                      />
                      <sup style="font-size: 10px; font-weight: 600" class="ms-1">
                        {{ task.subtaskCnt }}
                      </sup>
                    </span>
                    <span style="padding: 0 5px" v-if="task.priority">
                      <button
                        :class="priorityButtonClass(task)"
                        style="
                          width: 70px;
                          height: 25px;
                          font-size: 10px;
                          border: none;
                          border-radius: 5px;
                        "
                      >
                        {{ task.priority }}
                      </button>
                    </span>
                    <span style="padding: 0 5px">
                      <img
                        src="../../assets/icons/threeDot.jpg"
                        alt="More options"
                        style="width: 15px; height: 13px"
                      />
                    </span>
                  </section>
                </div>
                <div class="mt-1" v-if="task.subTaskCount">
                  <span
                    class="ms-3"
                    style="
                      padding: 0 10px;
                      background-color: rgba(0, 128, 0, 0.3);
                      border-radius: 5px;
                    "
                  >
                    <img
                      src="../../assets/icons/subtask.png"
                      alt="Subtask"
                      style="width: 15px; height: 15px"
                    />
                    <sup style="font-size: 10px"> {{ task.subTaskCount }}</sup>
                  </span>
                </div>
                <div class="mt-2 d-flex justify-content-between">
                  <section class="d-flex align-items-center">
                    <img
                      src="../../assets/icons/black_Calendar.png"
                      alt="Calendar"
                      style="width: 17px; height: 17px"
                    />
                    <span class="mt-1 ms-2 text-muted" style="font-size: 10px">
                      {{ reverseDateFormat(task.date) }}
                    </span>
                  </section>
                  <section>
                    <span class="collaborator-avatar">
                      <span
                        class="d-flex initials"
                        v-for="(employee, employeeIndex) in task.collaborator || []"
                        :key="employeeIndex"
                      >
                        <span>{{ employee.initials }}</span>
                      </span>
                    </span>
                  </section>
                </div>
              </div>
            </div>
          </div>
          <div style="flex: 1">
            <div class="d-flex flex-column LeadChart2 p-2">
              <div class="row w-100 ms-1">
                <p class="subHeadingHeader p-1 m-0">Today’s Schedules</p>
                <hr />
              </div>

              <div
                @click="changeView('day')"
                class="card card3 p-2 m-2 border-0 blueactive-SubHeading2 justify-content-between"
                v-for="event in todayEvents"
                :key="event.id"
              >
                <div class="m-1">
                  <p style="font-size: 14px; font-weight: 600" class="p-0 mb-1">
                    {{ event.title }}
                  </p>
                  <p style="font-size: 10px" v-if="event.description !== null">
                    {{ event.description }}
                  </p>
                </div>
                <div
                  class="mb-1 m-1"
                  style="font-size: 12px"
                  v-if="event.meetingLink !== null"
                >
                  <span>
                    <a :href="'//' + event.meetingLink" target="_blank">{{
                      event.meetingLink
                    }}</a>
                  </span>
                </div>
                <div class="d-flex justify-content-between mt-1">
                  <section style="font-size: 12px">
                    <p class="p-0 m-1 d-flex align-items-center">
                      <img
                        src="../../assets/icons/time.png"
                        alt="Calendar"
                        class="mt-2"
                        style="width: 15px; height: 15px"
                      />
                      <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                        {{ convertTo12HourFormat(event.fromTime) }} To
                        {{ convertTo12HourFormat(event.toTime) }}
                      </span>
                    </p>
                    <p class="p-0 m-1 d-flex align-items-center">
                      <img
                        src="../../assets/icons/Calendar.png"
                        alt="Calendar"
                        class="mt-1"
                        style="width: 15px; height: 15px"
                      />
                      <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                        {{ reverseDateFormat(event.date) }}
                      </span>
                    </p>
                  </section>
                  <section class="d-flex align-items-center">
                    <span class="collaborator-avatar">
                      <span
                        class="d-flex initials"
                        v-for="(employee, employeeIndex) in event.eventParticipants || []"
                        :key="employeeIndex"
                      >
                        <span>{{ employee["users.initials"] }}</span>
                      </span>
                    </span>
                  </section>
                </div>
              </div>
              <div
                @click="changeView('day')"
                class="card card3 p-2 m-2 border-0 Yellow-SubHeading2 justify-content-between"
                v-for="item in todayReminders"
                :key="item.id"
              >
                <div class="m-1">
                  <p style="font-size: 14px; font-weight: 600" class="p-0 mb-1">
                    {{ item.title }}
                  </p>
                  <p style="font-size: 10px" v-if="item.description !== null">
                    {{ item.description }}
                  </p>
                </div>

                <div class="d-flex justify-content-between mt-1">
                  <section style="font-size: 12px">
                    <p class="p-0 m-1 d-flex align-items-center">
                      <img
                        src="../../assets/icons/time.png"
                        alt="Calendar"
                        class="mt-2"
                        style="width: 15px; height: 14px"
                      />
                      <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                        {{ convertTo12HourFormat(item.fromTime) }} To
                        {{ convertTo12HourFormat(item.toTime) }}
                      </span>
                    </p>
                    <p class="p-0 m-1 d-flex align-items-center">
                      <img
                        src="../../assets/icons/Calendar.png"
                        alt="Calendar"
                        class="mt-1"
                        style="width: 15px; height: 15px"
                      />
                      <span class="mt-2 ms-2 text-muted" style="font-size: 10px">
                        {{ reverseDateFormat(item.date) }}
                      </span>
                    </p>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <div v-if="showModal" class="modal-overlay" @click="openApprovalModal"></div>
    </transition>
    <transition name="slide">
      <div class="modal show d-block" v-if="showModal" style="z-index: 9999999">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <p class="modal-title">
                Are you sure you want to <strong>verified</strong> this sign off document
                file?
              </p>
            </div>

            <div class="modal-footer d-flex justify-content-center">
              <button
                type="button"
                class="btn btn-sm btn-primary"
                @click="approveDocument"
              >
                Yes
              </button>
              <button
                type="button"
                class="btn btn-sm btn-secondary"
                @click="showModal = false"
              >
                No
              </button>
            </div>
          </div>
        </div>
      </div>
    </transition>

    <div class="modal show d-block" v-if="showRejectionModal" tabindex="-1" role="dialog">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <p class="modal-title">
              Are you sure you want to reject this sign-off document file?
            </p>
          </div>
          <div class="modal-body">
            <h6 class="modal-title tableHeaderHeading">Remarks :-</h6>
            <textarea
              class="form-control mt-1"
              rows="6"
              v-model="rejectionRemarks"
              placeholder="Enter remarks"
            ></textarea>

            <!-- Attachment file button -->
            <div class="mt-3">
              <label class="form-label">Attach File:</label>
              <input type="file" class="form-control" @change="handleFileUpload" />
            </div>
          </div>
          <div class="modal-footer d-flex justify-content-center">
            <button type="button" class="btn btn-sm btn-primary" @click="rejectDocument">
              Yes
            </button>
            <button
              type="button"
              class="btn btn-sm btn-secondary"
              @click="showRejectionModal = false"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- Content for section 2 -->
  </div>

</template>

<script>
import axios from "axios";

export default {
  name: "DesignDashBoard",
  props: {
    SelectedEmployeeId: {
      type: Number,
      required: true,
    },
  },
  components: {},
  data() {
    return {
      attachedFile: null,
      rejectionRemarks: "",
      signOffDocumentId: null,
      todayTasks: [],
      todayEvents: [],
      currentDay: "",
      formattedDate: "",
      fullName: "",
      greeting: "",
      Employees: [],
      selectedEmployeeId: this.SelectedEmployeeId,
      showRejectionModal: false,
      showModal: false,
      designProjects: [],
      PendingdesignProjectsDocuments: [],
      projectStatusCounts: [],
      designStageCounts: [],
    };
  },
  mounted() {
    this.selectedEmployeeId = this.SelectedEmployeeId;
    this.setCurrentDate();
    this.fetchTodayTasks();
    this.fetchTodayEvents();
   
    this.featchDesignProjects();
    this.fetchCountsData();
    this.featchPendingDesignProjectsDocuments();
    this.featchDesignProjectTeamMembers();
    this.fetchTodayTasks();
    this.fetchTodayReminders();
    this.fetchTodayEvents();
  },

  watch: {
    SelectedEmployeeId(newVal) {
      this.selectedEmployeeId = newVal;
      this.featchDesignProjects();
      this.fetchCountsData();
      this.featchPendingDesignProjectsDocuments();
      this.featchDesignProjectTeamMembers();
      this.fetchTodayTasks();
      this.fetchTodayReminders();
      this.fetchTodayEvents();
    },
  },

  computed: {
    isDesignExecutive() {
      const roles = this.$LocalUser.employeeRoles;
      return roles.some((role) => role.team === "Designing" && role.role === "Executive");
    },
    priorityButtonClass() {
      return (task) => {
        // Determine which class to apply based on data.priority
        switch (task.priority) {
          case "Low":
            return "btn-priority-low"; // Define this class in your CSS
          case "Medium":
            return "btn-priority-medium"; // Define this class in your CSS
          case "High":
            return "btn-priority-high"; // Define this class in your CSS
          default:
            return ""; // Default class if no priority is set or unknown priority
        }
      };
    },
    titleBackgroundColorClass() {
      return (priority) => {
        if (priority === "Low") {
          return "priority-low";
        } else if (priority === "Medium") {
          return "priority-medium";
        } else if (priority === "High") {
          return "priority-high";
        }
        return "";
      };
    },
  },

  methods: {
    openTaskModal(taskId) {
      this.$emit("task-selected", taskId);
      console.log(taskId);
    },
    changeView(view) {
      this.currentView = view; // Update the currentView when changing the view
      if (view === "year") {
        // Navigate to the calendar page with the current year
        const currentYear = new Date().getFullYear();
        this.$router.push({ name: "Calendar", params: { year: currentYear } });
      } else if (view === "month") {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1; // Month index starts from 0, so add 1
        this.$router.push({
          name: "Month",
          params: { year: currentYear, month: currentMonth },
        });
      } else if (view === "day") {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const currentDay = currentDate.getDate();
        this.$router.push({
          name: "Day",
          params: { year: currentYear, month: currentMonth, day: currentDay },
        });
      }
    },
    async fetchTodayTasks() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/tasks/dashboardTasks/${this.selectedEmployeeId}`
        );
        this.todayTasks = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchTodayReminders() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/reminders/dashboardReminder/${this.selectedEmployeeId}`
        );
        this.todayReminders = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchTodayEvents() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/events/dashboardEvents/${this.selectedEmployeeId}`
        );
        this.todayEvents = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    convertTo12HourFormat(timeString) {
      // Split the time string into hours and minutes
      const [hours, minutes] = timeString.split(":");

      // Convert hours to a number
      let hour = parseInt(hours, 10);

      // Determine if it's AM or PM
      const period = hour >= 12 ? "PM" : "AM";

      // Convert hours to 12-hour format
      hour = hour % 12 || 12;

      // Return the formatted time string
      return `${hour.toString().padStart(2, "0")}:${minutes} ${period}`;
    },

    handleFileUpload(event) {
      const files = event.target.files;

      if (files.length === 0) {
        console.error("No files selected for upload.");
        return;
      }

      const file = files[0]; // Assuming only one file is uploaded
      const fileCategory = "signOffAttachment";
      const entityId = this.signOffDocumentId; // Make sure this.signOffDocumentId is defined
      const entityName = "SignOffDocument"; // Assuming entityName is a string literal

      // Construct the payload to request the signed URL
      const payload = {
        fileName: file.name,
        fileSize: file.size,
        contentType: file.type,
        moduleName: "design",
        fileCategory: fileCategory,
        entityId: entityId,
        entityName: entityName,
      };

      console.log("Payload for signed URL request:", payload);

      // Request the signed URL from the server
      axios
        .post(`${this.$apiEndPoint}/company/putFileSignedURL`, payload)
        .then((response) => {
          const signedUrl = response.data.s3URL;

          console.log("Signed URL received:", signedUrl);

          // Use XMLHttpRequest to upload the file to S3 using the signed URL
          const xhr = new XMLHttpRequest();
          xhr.open("PUT", signedUrl, true);
          xhr.setRequestHeader("Content-Type", file.type);

          xhr.onreadystatechange = () => {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                console.log("File uploaded successfully to S3:", xhr.responseText);
              } else {
                console.error("Error uploading file to S3:", xhr.responseText);
              }
            }
          };

          // Send the file data to S3
          xhr.send(file);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    getGreeting() {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();

      if (currentHour < 12) {
        return "Good Morning";
      } else if (currentHour < 17) {
        return "Good Afternoon";
      } else {
        return "Good Evening";
      }
    },
    reverseDateFormat(date) {
      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },

    setCurrentDate() {
      const date = new Date();
      this.currentDay = new Intl.DateTimeFormat("en-US", { weekday: "long" }).format(
        date
      );
      const options = { day: "numeric", month: "long", year: "numeric" };
      this.formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
    },
    async rejectDocument() {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/designFiles/${this.signOffDocumentId}`,
          {
            status: "Rejected",
            comment: this.rejectionRemarks,
          }
        );

        if (response.status === 200) {
          this.featchPendingDesignProjectsDocuments();
          // Handle successful response
          console.log("Document Rejected successfully:", response.data);
          this.$toast("Document Rejected successfully", "success");
        }
        this.showRejectionModal = false;
      } catch (error) {
        this.$handleError(error);
      }
      // Handle rejection logic here, including sending rejection remarks if needed
    },
    async approveDocument() {
      try {
        const response = await axios.put(
          `${this.$apiEndPoint}/designFiles/${this.signOffDocumentId}`,
          {
            status: "Verified",
          }
        );

        if (response.status === 200) {
          this.featchPendingDesignProjectsDocuments();
          // Handle successful response

          console.log("Document Verified successfully:", response.data);
          this.$toast("Document Verified successfully", "success");
        }
        this.showModal = false;
      } catch (error) {
        this.$handleError(error);
      }
    },
    // Method to open the modal
    openApprovalModal(Id) {
      this.signOffDocumentId = Id;
      this.showModal = true;
    },
    openRejectModal(Id) {
      this.signOffDocumentId = Id;
      this.showRejectionModal = true;
    },
    navigateToProjectProfile(project) {
      // Navigate to ProjectProfile page with project ID as parameter
      this.$router.push({
        name: "DesignProjectProfile",
        params: {
          Id: project.id,

          projectName: project.projectName,
        },
      });
    },
    navigateToProjectProfilee(project) {
      // Navigate to ProjectProfile page with project ID as parameter
      this.$router.push({
        name: "DesignProjectProfile",
        params: {
          Id: project["designProjects.id"],
          projectName: project.projectName,
        },
      });
    },

    featchDesignProjectTeamMembers() {
      axios.get(`${this.$apiEndPoint}/employee/myTeam/Designing`).then((response) => {
        this.Employees = response.data;
        console.log(this.Employees);
      });
    },
    featchDesignProjects() {
      axios
        .get(
          `${this.$apiEndPoint}/designProjects/inProgressProjects/${this.selectedEmployeeId}`
        )
        .then((response) => {
          this.designProjects = response.data;
          console.log(this.designProjects);
        });
    },

    featchPendingDesignProjectsDocuments() {
      axios
        .get(
          `${this.$apiEndPoint}/designFiles/getFileForApproval/${this.selectedEmployeeId}`
        )
        .then((response) => {
          this.PendingdesignProjectsDocuments = response.data;
          console.log(this.PendingdesignProjectsDocuments);
        });
    },

    goToDesignProjectTable(stageName) {
      this.$router.push({
        name: "DesignProjectTable",
        params: { stageName: stageName, selectedEmployeeId: this.selectedEmployeeId },
      });
    },
    goToDesignProjectTableByStatus(statusName) {
      // Corrected logic for checking isDesignExecutive
      if (statusName === "New" && this.isDesignExecutive) {
        console.log("Design Executive Access");
        return; // Exit function early if Design Executive has access
      }

      // Logic when statusName is "New" but not a Design Executive
      if (statusName === "New") {
        this.selectedEmployeeId = "All";
      }

      // Navigate to the DesignProjectTableByStatus route with params
      this.$router.push({
        name: "DesignProjectTableByStatus",
        params: {
          statusName: statusName,
          selectedEmployeeId: this.selectedEmployeeId,
        },
      });
    },

    async fetchCountsData() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/designProjects/counts/${this.selectedEmployeeId}`
        );
        this.projectStatusCounts = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    getStatusCount(status) {
      const statusCount = this.projectStatusCounts.find(
        (item) => item.projectStatus === status
      );
      return statusCount ? statusCount.count : 0; // Return count if found, otherwise 0
    },

    // Method to get count for a specific design stage
    getStageCount(stage) {
      const stageCount = this.projectStatusCounts.find(
        (item) => item.designStage === stage
      );
      return stageCount ? stageCount.count : 0; // Return count if found, otherwise 0
    },
  },
};
</script>
<style scoped>
.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 5px;
  overflow: hidden;
  margin: 5px 0px;
}

.progress-bar {
  height: 10px;
}

.table-responsive thead th {
  background-color: #ccc;
  font-size: 12px;
  font-weight: 600;
  padding: 4px 6px;
  text-align: left;
}
.table-responsive tbody td {
  font-size: 12px;
  padding: 4px 6px;
  text-align: left;
}

.blueactive-SubHeading2 {
  font-size: 14px;

  color: #000000;
  font-weight: 500;
  /* Set left 10% background color here */
  background-image: linear-gradient(to right, #2381f3 4px, transparent 3px);
  background-size: 100% 100%;
  /* Ensure gradient fills entire width */
  /* You can adjust other styles as needed */
}

.btn-priority-low {
  background-color: #f4e8fb;
  color: #ba69e2;
}

.btn-priority-medium {
  background-color: #ecebec;
  color: #7e7882;
}

.btn-priority-high {
  background-color: #feabab;
  color: #fd0000;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

label {
  font-weight: 500;
  color: black;
}

.transaction-details {
  position: fixed;
  display: block;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
  width: 400px;
  min-height: 250px;
  height: auto;
  background-color: #f4f4f4;
  color: #000000;
  border: 1px solid #b1c5df;
  border-radius: 10px;
}

#blackThreeDOtDashBoard {
  width: 15px;
  height: 13px;
  position: relative;
  cursor: pointer;
  display: flex;
}

.sales-dashboard {
  margin: 0.5% 2%;
  box-sizing: border-box;
}

.section-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  position: relative;
  background-color: #f4f4f4;
  /* Relative positioning for the wrapper */
}

.contentOfWhiteHeadSection {
  position: relative;
  width: 100%;
}

.contentOfWhiteHeadSection2 {
  position: relative;
  width: 100%;
  height: auto;
}

.LeadChart {
  max-height: 350px;
  min-height: 350px;
  background-color: #ffffff;
  border-radius: 5px;
}

.LeadChart2 {
  max-height: 350px;
  min-height: 350px;
  background-color: #ffffff;
  border-radius: 5px;
  overflow-y: scroll;
}

.otherChart {
  max-height: 250px;
  display: flex;
  justify-content: space-between;
}

.otherChart section {
  max-height: 350px;
  min-height: 350px;
  width: 300px;
  background-color: #ffffff;
  overflow-y: auto;

  border-radius: 5px;
}

.otherChart section::-webkit-scrollbar {
  display: none;
}

.lead-count-chart {
  display: flex;
  flex-direction: column;

  margin-top: 20px;
}

.chart-bar {
  margin-right: 10px;
  text-align: center;
  display: flex;
}

.bar-label {
  font-size: 12px;
  margin-bottom: 5px;
  width: 150px;
  text-align: left;
}

.DashboardSection2 {
  background-color: #000000;
}

.card2 {
  width: 400px;
  height: 100px;
  background-color: #ffffff;
}

.card3 {
  width: 400px;
  min-height: 200px;
  max-height: fit-content;
  background-color: #ffffff;
  border-radius: 0 5px 5px 0;
  overflow-y: auto;
}

.card3::-webkit-scrollbar {
  display: none;
}
#approve {
  width: 15px;
  margin-right: 10px;
  cursor: pointer;
}

#textColorOfCard {
  color: rgb(4, 4, 4);
  font-weight: 500;
}
.flex-container {
  display: flex;
  justify-content: space-between; /* Distributes space between flex items */
  align-items: center;
  padding-top: 10px;
  /* padding-right: 30px; */
}
#textColorOfCardHeading1,
#textColorOfCardHeading2,
#textColorOfCardHeading3 {
  color: #bcbfc6;
  font-size: 10px;
}
#textColorOfCardContent1 {
  color: #bcbfc6;
  font-size: 10px;
  padding-left: 15px;
}
#textColorOfCardContent2 {
  color: #bcbfc6;
  font-size: 10px;
}
#textColorOfCardContent3 {
  color: #bcbfc6;
  font-size: 10px;
  padding-right: 20px;
}

.collaborator-avatar {
  position: relative;
  display: flex;
  justify-content: left;
  align-items: left;
  margin-right: 2px;

  /* Adjust margin to control the overlap */
}
.initials {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #4884f7; /* Blue background color */
  color: #ffffff; /* White text color */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 8px;
  cursor: pointer;
  z-index: 1;
  margin-right: -4px; /* Adjust margin to control the overlap */
  position: relative; /* Ensure z-index works correctly */
}
/* Mobile View Adjustments */
@media (max-width: 768px) {
  .middleHeadSection {
    display: none;
  }

  .LeadChart,
  .LeadChart2,
  .otherChart section {
    min-height: auto;
    max-height: auto;
  }

  .section-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-height: 100%;
    max-width: 100%;
    position: relative;
    padding: 10px;
    overflow-y: scroll !important;
  }

  .contentOfWhiteHeadSection,
  .contentOfWhiteHeadSection2 {
    padding: 10px;
  }

  .otherChart {
    flex-direction: column;
    justify-content: space-around;
  }

  .otherChart section {
    width: 100%;
    margin-bottom: 10px;
  }

  .LeadChart {
    margin-bottom: 10px;
  }

  .lead-count-chart {
    margin-top: 10px;
  }

  .table-responsive thead th {
    font-size: 12px;
    padding: 4px;
  }

  .welcome {
    font-size: 18px;
  }

  .progress-bar-container {
    margin: 5px 0;
  }

  .bar-label {
    width: auto;
  }

  .WhiteHeadSection {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow-y: scroll;
  }

  .contentOfWhiteHeadSectiondiv,
  .contentOfWhiteHeadSection2div {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .content-container,
  .container-child,
  .section-wrapper {
    flex-direction: column;
  }

  .black-head-section,
  .middle-head-section,
  .white-head-section {
    height: auto;
    overflow-y: auto;
  }

  .lead-chart,
  .other-chart,
  .lead-chart2 {
    margin-top: 10px;
  }
  .WhiteHeadSection {
    display: none;
   }
}
/*

.bar {
  width: 50px;
  height: 20px;
  background-color: #007bff; 
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}*/
</style>
