<template>
  <div
    class="modal fade"
    id="OnSiteStockConsumptionModal"
    tabindex="-1"
    aria-labelledby="OnSiteStockConsumptionModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-custom">
      <div class="modal-content">
        <div class="modal-header d-flex justify-content-between align-items-center">
          <div class="modal-title">Stock Consumption</div>

          <div class="d-flex align-items-center">
            <label for="date" class="col-form-label text-nowrap me-2 WEB">Date:</label>
            <input type="date" class="form-control p-1" v-model="form.date" />
          </div>
        </div>
        <div class="modal-body">
          <div class="table-responsive">
            <table class="table files-table">
              <thead>
                <tr>
                  <th>S/N</th>
                  <th>Product Name</th>
                  <th>Qty</th>
                  <th>Unit Type</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, index) in stocks" :key="index">
                  <td>{{ index + 1 }}</td>
                  <td>
                    <input
                      class="w-100 form-control"
                      list="products"
                      v-model="row.productName"
                      @focus="activeRow = index"
                      @input="searchProduct(row.productName, index)"
                      @change="fillProductDetails(index)"
                      :ref="'productNameInput' + index"
                    />
                    <ul
                      v-if="row.filteredProducts.length > 0 && activeRow === index"
                      class="dropdown-menu show"
                    >
                      <li
                        v-for="product in row.filteredProducts"
                        :key="product.id"
                        @click="selectProduct(product, index)"
                        class="p-2"
                      >
                        {{ product["products.productName"] }} ({{
                          product.availableQuantity
                        }})
                      </li>
                    </ul>
                  </td>
                  <td>
                    <input
                      type="number"
                      class="form-control"
                      v-model.number="row.quantity"
                      @input="checkQuantityLimit(index)"
                    />
                  </td>
                  <td>
                    <input type="text" class="form-control" v-model="row.unitType" />
                  </td>
                  <td>
                    <img
                      style="width: 13px; height: 13px; cursor: pointer"
                      src="../../assets/icons/redDelete.png"
                      alt="Delete"
                      @click="removeRow(index)"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <button @click="addRow" class="btn btn-sm btn-primary mt-3">Add Row</button>
        </div>
        <div class="modal-footer justify-content-center">
          <button
            v-if="this.localStockEntryId === 'null'"
            type="button"
            class="btn btn-primary"
            @click="CreateStockConsumption"
            data-bs-dismiss="modal"
          >
            Save
          </button>
          <button
            v-else
            type="button"
            class="btn btn-primary"
            @click="updateStock"
            data-bs-dismiss="modal"
          >
            Update
          </button>
          <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "OnSiteStockConsumptionModal",
  props: {
    CompanyprojectId: {
      type: Number,
      required: true,
    },
    StockNumber: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      activeRow: null,
      localStockEntryId: null,
      Products: [],
      form: {
        date: new Date().toISOString().slice(0, 10),
      },
      stocks: [
        {
          id: null,
          stockNumber: this.StockNumber,
          productName: "",
          quantity: 0,
          unitType: "",
          projectId: this.CompanyprojectId,
          date: new Date().toISOString().slice(0, 10),
          filteredProducts: [],
          rowProducts: [],
        },
      ],
    };
  },

  watch: {
    CompanyprojectId(newValue) {
      this.stocks.forEach((stock) => (stock.projectId = newValue));
    },
    StockNumber(newValue) {
      this.localStockEntryId = newValue;
      if (newValue !== "null") {
        this.fetchEntriesById(newValue);
      } else {
        this.stocks = [];
        this.resetStocks();
      }
    },
  },
  methods: {
    checkQuantityLimit(index) {
      const row = this.stocks[index];
      console.log(row);
      // Find the availableQuantity of the selected product
      const selectedProduct = row.rowProducts.find(
        (product) => product["products.id"] === row.productId
      );
      console.log(selectedProduct);
      if (selectedProduct) {
        // If the quantity exceeds availableQuantity, set it to availableQuantity
        if (row.quantity > selectedProduct.availableQuantity) {
          row.quantity = selectedProduct.availableQuantity;
        }
      }
    },

    async fetchEntriesById(entryId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/stockConsumption/${entryId}`
        );
        this.form = response.data;
        this.stocks = response.data.productList.map((item) => ({
          ...item,
          filteredProducts: [],
        }));
      } catch (error) {
        console.error("Error fetching stock entry:", error);
      }
    },
    fetchProductList(searchString) {
      axios
        .post(
          `${this.$apiEndPoint}/inwardOutwardEntries/getProducts/${this.CompanyprojectId}`,
          { searchString }
        )
        .then((response) => {
          this.Products = response.data;
        })
        .catch((error) => {
          console.error("Error fetching products:", error);
        });
    },
    searchProduct(searchString, index) {
      if (searchString.length > 0) {
        axios
          .post(
            `${this.$apiEndPoint}/inwardOutwardEntries/getProducts/${this.CompanyprojectId}`,
            { searchString }
          )
          .then((response) => {
            this.stocks[index].filteredProducts = response.data;
            this.stocks[index].rowProducts = response.data;
          })
          .catch((error) => {
            console.error("Error searching products:", error);
          });
      } else {
        this.stocks[index].filteredProducts = [];
      }
    },
    fillProductDetails(index) {
      const product = this.stocks[index].filteredProducts.find(
        (p) => p["products.productName"] === this.stocks[index].productName
      );
      if (product) {
        this.stocks[index].unitType = product.unitType;
        this.stocks[index].productId = product["products.id"];
      }
    },
    selectProduct(product, index) {
      this.stocks[index].productName = product["products.productName"];
      this.stocks[index].productId = product["products.id"];
      this.stocks[index].unitType = product.unitType || "";

      this.stocks[index].filteredProducts = [];
    },
    addRow() {
      const lastRow = this.stocks[this.stocks.length - 1];
      if (lastRow.productName.trim() !== "") {
        this.stocks.push({
          id: null,
          stockNumber: this.StockNumber,
          productName: "",
          quantity: 0,
          unitType: "",
          projectId: this.CompanyprojectId,
          date: new Date().toISOString().slice(0, 10),
          filteredProducts: [],
        });
      }
    },
    resetStocks() {
      this.stocks = [
        {
          id: null,
          stockNumber: this.StockNumber,
          productName: "",
          quantity: 0,
          unitType: "",
          projectId: this.CompanyprojectId,
          date: new Date().toISOString().slice(0, 10),
          filteredProducts: [],
        },
      ];
    },
    removeRow(index) {
      const stockId = this.stocks[index].id;
      this.stocks.splice(index, 1);
      if (stockId) {
        axios
          .delete(`${this.$apiEndPoint}/stockConsumption/deleteLineitem/${stockId}`)
          .then((response) => {
            console.log("Entry deleted successfully:", response.data);
          })
          .catch((error) => {
            console.error("Error deleting line item:", error);
          });
      }
    },
    updateStock() {
      axios
        .put(`${this.$apiEndPoint}/stockConsumption`, { stocks: this.stocks })
        .then((response) => {
          this.$emit("fetchStockConsumptionEntries");
          console.log(response);
          this.$toast("Stock consumption Updated successfully.", "success");

          this.resetStocks();
        })
        .catch((error) => {
          console.error("Error updating stock:", error);
        });
    },
    CreateStockConsumption() {
      this.form.stocks = [...this.stocks];
      console.log(this.form);
      axios
        .post(`${this.$apiEndPoint}/stockConsumption`, this.form)
        .then((response) => {
          this.$toast("Stock consumption created successfully.", "success");
          this.$emit("fetchStockConsumptionEntries");
          console.log(response.data);
          this.resetStocks();
        })
        .catch((error) => {
          console.error("Error creating stock consumption:", error);
        });
    },
  },
};
</script>
<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none;
  box-shadow: none;
}
label {
  font-weight: 500;
  color: black;
}
.modal-body {
  max-height: 500px;
  overflow-y: auto;
}
.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}
.files-table th,
.files-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}
.files-table th {
  background-color: #bcbfc6;
}
.files-table tr:hover {
  background-color: #f5f5f5;
}
.dropdown-menu {
  max-height: 200px;
  overflow-y: scroll;
}

/* Center the modal for desktop and make full-width on mobile */
.modal-custom {
  min-width: 60%;
  overflow: auto;
}
@media (max-width: 768px) {
  .modal-custom {
    min-width: 100%;
    overflow: auto;
  }
}
</style>
