<template>
    <Header />
    <div class="content-Container">
        <Nav />
        <div class="containerChild">
            <div class="SalesProjectRegister">
                <header>
                    <h3>Create Project</h3>
                    <img src="../../../assets/icons/crossIcon.png" alt="" @click="goBack">
                </header>
                <section class="salesProjectForm">
                    <div class="formField">
                        <label for="projectName">Property Name :</label>
                        <input type="text" name="projectName" id="projectName" v-model="propertyData.name">
                    </div>
                    <div class="formField">
                        <label for="propertyAddress">Property Address :</label>
                        <input type="text" name="propertyAddress" id="propertyAddress" v-model="propertyData.address">
                    </div>
                    <div class="formField">
                        <label for="city">City :</label>
                        <input type="text" name="city" id="city" v-model="propertyData.city">
                    </div>
                    <div class="formField">
                        <label for="state">State:</label>
                        <input type="text" name="state" id="state" v-model="propertyData.state">
                    </div>
                </section>
                <footer>
                    <button @click="submitForm()">SUBMIT</button>
                    <button @click="resetForm()">RESET</button>
                </footer>
            </div>
        </div>
    </div>
</template>
    
<script>
import axios from 'axios';
import Header from '../../Header.vue';
import Nav from '../../Nav.vue';

export default {
    name: 'CreateProperty',
    components: {
        Header,
        Nav
    },
    data() {
        return {
            propertyData: {
                name: '',
                address: '',
                city: '',
                state: '',
                country: '',
                pincode: '',
                location: ''
            }
        };
    },
    methods: {
        async submitForm() {
            try {
                const response = await axios.post(`${this.$apiEndPoint}/property`, this.propertyData);
                console.log('Property Created:', response.data);
                this.resetForm();
            } catch (error) {
                console.error('Error submitting form:', error);
            }
        },
        resetForm() {
            this.propertyData = {
                name: '',
                address: '',
                city: '',
                state: '',

            };
        },

        goBack() {
            this.$router.go(-1); 
        }
    }
};
</script>
<!-- 
<style>
@import url('https://db.onlinewebfonts.com/c/405f05e6bf58471a25729ed060cda2d8?family=Glacial+Indifference+Regular');

.SalesProjectRegister {
    margin: 0%;
    padding: 0%;
    box-sizing: border-box;
    top: 30%;
    left: 40%;
    position: fixed;
    width: 30%;
    height: 40%;
    border: 1px solid black;
    background-color: #396e76;
    letter-spacing: 2px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 2);



}

.SalesProjectRegister header img {
    width: 5%;
    top: 2%;
    right: 1%;
    position: absolute;
    cursor: pointer;

}

.SalesProjectRegister header {
    display: flex;
    align-items: center;
    padding: 2%;

}

.SalesProjectRegister header h3 {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 35%;
}


.salesProjectForm {
    display: flex;
    flex-direction: column;
    margin-top: 2%;
}

.formField {
    margin: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

}

.formField label {
    white-space: nowrap;
    left: 0;
    margin-left: 10%;
    position: absolute;
}

.formField input {
    white-space: nowrap;
    right: 0;
    margin-right: 10%;
    position: absolute;
    outline: none;
    border: none;
    padding: 1% 6%;
    background-color: #084f5b;
    white-space: nowrap;
}

.SalesProjectRegister footer {
    margin-top: 4%;

}

.SalesProjectRegister footer button {
    letter-spacing: 2px;
    background-color: #ffbd59;
    margin: 0 3%;
    outline: none;
    border: none;
    padding: 1% 2%;
}
</style> -->