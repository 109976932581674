<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <header class="">
          <div class="header-left SubheadingUnderLine">
            <h4>Calendar</h4>
          </div>
          <div class="header-center">
            <img src="../../assets/icons/leftmove.png" alt="" @click="previousYear" />
            <select
              v-model="selectedYear"
              @change="updateCalendar"
              style="
                margin: 0 3%;
                border: none;
                border-bottom: 3px solid blueviolet;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                font-size: 18px;
                font-weight: bold;
                outline: none;
                width: 80px;
                text-align: center;
                overflow-y: auto;
              "
            >
              <option v-for="year in years" :key="year" :value="year" class="">
                {{ year }}
              </option>
            </select>
            <img src="../../assets/icons/rightmove.png" alt="" @click="nextYear" />
          </div>

          <div class="header-right" style="font-weight: bold">
            <span
              @click="changeView('year')"
              :class="{ active: currentView === 'year' }"
              style="cursor: pointer"
              >Year</span
            >
            <span @click="changeView('month')" style="margin: 0 20px; cursor: pointer"
              >Month</span
            >
            <span @click="changeView('day')" style="cursor: pointer">Day</span>
          </div>
        </header>
        <div class="month-view">
          <div
            v-for="(month, index) in months"
            :key="index"
            class="month"
            @click="goToMonthView(index + 1)"
            style="cursor: pointer"
          >
            <h6 class="text-center" style="font-weight: bold">{{ month }}</h6>
            <div class="week-days">
              <div class="week-day" v-for="day in weekDays" :key="day">{{ day }}</div>
            </div>
            <div class="days">
              <div
                class="empty-day font-color"
                v-for="(n, emptyIndex) in startDayOfMonth(selectedYear, index)"
                :key="`empty-${emptyIndex}`"
              >
                {{ getPreviousYearDays(index, emptyIndex) }}
              </div>
              <div
                class="day"
                :class="{ 'current-day': isCurrentDay(selectedYear, index, day) }"
                v-for="day in getDaysInMonth(selectedYear, index)"
                :key="`day-${day}`"
              >
                {{ day }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";

export default {
  name: "Calendar",
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      selectedEmployeeId:this.$LocalUser.user.id,
      years: [],
      currentView: "year",
      selectedYear: new Date().getFullYear(),
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
      weekDays: ["S", "M", "T", "W", "T", "F", "S"],
    };
  },
  mounted() {
    this.selectedEmployeeId=this.$LocalUser.user.id;
    this.populateYears();
    console.log(this.selectedEmployeeId);
  },
  methods: {
    populateYears() {
      const currentYear = new Date().getFullYear();
      for (let i = currentYear - 10; i <= currentYear + 10; i++) {
        this.years.push(i);
      }
    },
    isCurrentDay(year, monthIndex, day) {
      const currentDate = new Date();
      return (
        year === currentDate.getFullYear() &&
        monthIndex === currentDate.getMonth() &&
        day === currentDate.getDate()
      );
    },
    updateCalendar() {
      // Loop through each month to populate the calendar for the selected year
      for (let i = 0; i < 12; i++) {
        // Get the number of days in the current month
        const daysInMonth = this.getDaysInMonth(this.selectedYear, i);
        // Get the start day of the week for the current month
        const startDay = this.startDayOfMonth(this.selectedYear, i);
        // Create an object to store month data
        const monthData = {
          days: Array.from({ length: daysInMonth }, (_, index) => index + 1), // Generate an array of days from 1 to the number of days in the month
          startDay: startDay, // Set the start day of the month
        };
        // Push the month data object to the months array
        this.months.push(monthData);
      }
    },
    goToMonthView(selectedMonth) {
      this.$router.push({
        name: "Month",
        params: {
          year: this.selectedYear,
          month: selectedMonth,
          selectedEmployeeId: this.selectedEmployeeId, // Pass employee ID here
        },
      });
    },
    changeView(view) {
      this.currentView = view;
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
      const currentDay = currentDate.getDate();

      if (view === "year") {
        this.$router.push({
          name: "Calendar",
          params: { year: currentYear, selectedEmployeeId: this.selectedEmployeeId },
        });
      } else if (view === "month") {
        this.$router.push({
          name: "Month",
          params: {
            year: currentYear,
            month: currentMonth,
            selectedEmployeeId: this.selectedEmployeeId, // Pass employee ID here
          },
        });
      } else if (view === "day") {
        this.$router.push({
          name: "Day",
          params: {
            year: currentYear,
            month: currentMonth,
            day: currentDay,
            selectedEmployeeId: this.selectedEmployeeId, // Pass employee ID here
          },
        });
      }
    },

    getPreviousYearDays(currentMonthIndex, emptyIndex) {
      // Get the start day of the current month
      const startDay = this.startDayOfMonth(this.selectedYear, currentMonthIndex);
      // Calculate the number of days from the previous year's months to display in the empty days
      const previousYear = this.selectedYear - 1;
      const previousMonthIndex = currentMonthIndex === 0 ? 11 : currentMonthIndex - 1;
      const previousMonthDays = this.getDaysInMonth(previousYear, previousMonthIndex);
      // Calculate the correct day number for the empty days
      const dayNumber = previousMonthDays - (startDay - emptyIndex) + 1;
      // Return the day number or null for invalid cases
      return dayNumber > 0 ? dayNumber : null;
    },

    nextYear() {
      this.selectedYear++;
    },
    previousYear() {
      this.selectedYear--;
    },
    getDaysInMonth(year, monthIndex) {
      return new Date(year, monthIndex + 1, 0).getDate();
    },
    startDayOfMonth(year, monthIndex) {
      // Get the day of the week (0-6, 0 = Sunday) for the first day of the month
      return new Date(year, monthIndex, 1).getDay();
    },
  },
};
</script>

<style scoped>
.calendar-container {
  font-family: Arial, sans-serif;
  max-width: 100%;
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 2.5%;
  position: sticky;
  top: 0;
  background-color: #ffffff;
}

.header-left {
  display: flex;
  align-items: center;
}

.month-view {
  display: flex;
  flex-wrap: wrap;
  margin: 1% 2.5%;
}

.month {
  flex: 0 0 calc(23% - 10px);
  margin: 15px 15px;
  padding: 5px 4px;
  border-radius: 5px;
  background-color: #f8f8f8;
}

.month h5 {
  margin: 0;
}

.week-days {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ccc;
}

.week-day {
  width: calc(100% / 7);
  padding: 5px;
  text-align: center;
  color: #ccc;
  font-size: 10px;
}

.days {
  display: flex;
  flex-wrap: wrap;
}

.day {
  flex: 0 0 calc(100% / 7);
  padding: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
}
.month:hover {
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.15); /* Stronger shadow on hover */
  cursor: pointer;
}
.empty-day {
  flex: 0 0 calc(100% / 7);
  padding: 5px;
  text-align: center;
  font-size: 10px;
}

.font-color {
  color: #ccc; /* Adjust the color as needed */
}
.current-day {
  background-color: blue; /* Adjust the color as needed */
  color: white; /* Adjust the text color as needed */
  border-radius: 5px;
  /* To make the day cell round */
}

.active {
  border-bottom: 2px solid blue;
}
.header-center {
  margin-left: 20%;
}
.header-center img {
  width: 5%;
  cursor: pointer;
}

@media (max-width: 767px) {
  .header-center {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .header-center img {
    width: 10%;
  }

  .calendar-container {
    font-family: Arial, sans-serif;
    max-width: 100%;
    margin-bottom: 10%;
  }

  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 2.5%;
  }

  .header-left {
    display: flex;
    align-items: center;
  }

  .month-view {
    display: flex;
    flex-wrap: wrap;
    margin: 1% 2.5%;
  }

  .month {
    flex: 0 0 100%; /* Change to occupy full width */
    margin: 0 0 20px; /* Adjust margin */
    border-radius: 5px;
  }

  .week-days {
    display: flex;
    justify-content: space-between;
  }

  .week-day {
    width: calc(100% / 7);
    padding: 5px;
    text-align: center;
  }

  .days {
    display: flex;
    flex-wrap: wrap;
  }

  .day {
    flex: 0 0 calc(100% / 7);
    padding: 5px;
    text-align: center;
    font-size: 12px;
  }

  .empty-day {
    flex: 0 0 calc(100% / 7);
    padding: 5px;
    text-align: center;
    font-size: 10px;
  }
}
</style>
