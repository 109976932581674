<template>
  <div>
    <!-- Bootstrap modal -->
    <div
      class="modal fade"
      id="reminderModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="reminderModalLabel"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="font-size: 14px"
      >
        <div class="modal-content">
          <!-- Modal header -->
          <div class="modal-header">
            <h5
              class="modal-title d-flex justify-content-center align-content-center"
              style="margin-left: 40%"
            >
              <img
                src="../../assets/icons/reminder.png.png"
                alt=""
                style="width: 25px; height: 25px; object-fit: contain; margin-right: 10px"
              />
              Reminder
            </h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <!-- Modal body -->
          <div class="modal-body">
            <!-- Reminder modal content -->
            <div class="mb-3 row">
              <label for="title" class="col-sm-2 col-form-label text-black">Title:</label>
              <div class="col-sm-10">
                <input
                  type="text"
                  v-model="reminderData.title"
                  name="title"
                  id="title"
                  class="form-control"
                />
              </div>
            </div>
            <div class="mb-3 row">
              <label for="date" class="col-sm-2 col-form-label text-black">Date:</label>
              <div class="col-sm-10">
                <input
                  type="date"
                  v-model="reminderData.date"
                  name="date"
                  id="date"
                  class="form-control"
                />
              </div>
            </div>
            <div class="mb-3 row">
              <label for="fromTime" class="col-sm-2 col-form-label text-black"
                >Time:</label
              >
              <div class="col-sm-10 d-flex align-items-center">
                <input
                  type="time"
                  v-model="reminderData.fromTime"
                  name="fromTime"
                  id="fromTime"
                  class="form-control me-2"
                />
                <p style="letter-spacing: 2px; font-weight: lighter">To</p>
                <input
                  type="time"
                  v-model="reminderData.toTime"
                  name="toTime"
                  id="toTime"
                  class="form-control ms-2"
                />
              </div>
            </div>
            <div class="mb-3 row">
              <label for="description" class="col-sm-2 col-form-label text-black"
                >Description:</label
              >
              <div class="col-sm-10">
                <textarea
                  v-model="reminderData.description"
                  name="description"
                  id="description"
                  class="form-control"
                  rows="3"
                ></textarea>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div
            class="modal-footer d-flex justify-content-center"
            v-if="!this.reminderData.id"
          >
            <button
              type="button"
              class="btn btn-sm btn-primary"
              @click="postReminder"
              :disabled="!reminderData.title || !reminderData.date"
              data-bs-dismiss="modal"
            >
              Done
            </button>

            <button
              type="button"
              class="btn btn-sm btn-secondary"
              @click="resetForm"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
          <div class="modal-footer d-flex justify-content-center" v-else>
            <button
              type="button"
              class="btn btn-sm btn-success"
              @click="postReminder"
              data-bs-dismiss="modal"
            >
              Update
            </button>
            <button
              type="button"
              class="btn btn-sm btn-danger"
              @click="deleteReminder"
              data-bs-dismiss="modal"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateReminderModal",
  props: {
    selectedReminderId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      reminderData: {
        id: null,
        title: "",
        date: new Date().toISOString().slice(0, 10),  
        fromTime: null,
        toTime: null,
        description: "", // Added description field
      },
      // Replace with your actual API endpoint
    };
  },
  watch: {
    selectedReminderId(newValue) {
      if (newValue !== null) {
        this.reminderData.id = newValue;
        this.fetchReminderDataById();
      } else {
        this.reminderData.id = null;
        this.resetForm();
      }
    },
  },
  methods: {
    async fetchReminderDataById() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/reminders/${this.selectedReminderId}`
        );
        this.reminderData = response.data;
        console.log(this.reminderData);
      } catch (error) {
        this.$handleError(error);
      }
    },
    // Method to post or update a reminder
    postReminder() {
      console.log("Posting Reminder:", this.reminderData);
      if (!this.reminderData.id) {
        axios
          .post(`${this.$apiEndPoint}/reminders`, this.reminderData)
          .then((response) => {
            console.log("Reminder added successfully:", response.data);
            this.$emit("fetchData");
            this.resetForm(); // Reset the form data
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } else {
        axios
          .put(
            `${this.$apiEndPoint}/reminders/${this.reminderData.id}`,
            this.reminderData
          )
          .then((response) => {
            console.log("Reminder updated successfully:", response.data);
            this.$emit("fetchData");
            this.resetForm(); // Reset the form data
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    // Method to delete a reminder
    deleteReminder() {
      if (this.reminderData.id) {
        axios
          .delete(`${this.$apiEndPoint}/reminders/${this.selectedReminderId}`)
          .then((response) => {
            console.log("Reminder deleted successfully:", response.data);
            this.$emit("fetchData");
            this.resetForm(); // Reset the form data
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    // Method to reset the form
    resetForm() {
      this.reminderData = {
        id: null,
        title: "",
        date: null,
        fromTime: null,
        toTime: null,
        description: "",
      };
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
input,
textarea,
select,
label {
  font-size: 14px;
}
</style>
