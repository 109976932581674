import { createApp } from 'vue';
import App from './App.vue';
import router from './routers';
import './assets/sass/style.scss';
import setAuthHeader from './utilToken/setAuthHeader';
import { showToast } from './utilToken/toast';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import UserObject from './utilToken/UserObject';
import ApexCharts from 'apexcharts';
import capitalizeFirstLetter from './utilToken/capitalizeFirstLetter.js';
import axios from 'axios';

// Set Auth Header if token is available
if (localStorage.jwtToken) {
    setAuthHeader(localStorage.jwtToken);
} else {
    setAuthHeader(false);
}

const app = createApp(App);

// Global Configuration
app.config.globalProperties.$LocalUser = UserObject;
app.config.globalProperties.$apiEndPoint = process.env.VUE_APP_BACKEND_URL;
app.config.globalProperties.$toast = showToast;
app.config.globalProperties.$apexcharts = ApexCharts;
app.config.globalProperties.$handleError = handleGlobalError;
app.config.globalProperties.$isExecutive = isExecutive;
app.config.globalProperties.$isManager = isManager;
app.config.globalProperties.$formatToIndianCurrency = formatToIndianCurrency;
app.config.globalProperties.$formatDateDMY = formatDateDMY;

// Custom Directive for Capitalizing First Letter
app.directive('capitalize-first-letter', capitalizeFirstLetter);

// Utility Functions
function isExecutive(team) {
    const roles = UserObject.employeeRoles;
    return roles.some((role) => role.team === team && role.role === "Executive");
}

function isManager(team) {
    const roles = UserObject.employeeRoles;
    return roles.some((role) => role.team === team && role.role === "Manager");
}

function handleGlobalError(error) {
    let errorMessage;
    if (error.response) {
        if (error.response.status === 500) {
            errorMessage = "Something went wrong, Please try again later.";
        } else if (error.response.data && error.response.data.message) {
            errorMessage = error.response.data.message;
        }
    } else if (error.message) {
        errorMessage = error.message;
    }
    if (errorMessage) {
        app.config.globalProperties.$toast(errorMessage, "error");
    }
}

function formatToIndianCurrency(amount) {
    if (amount == null || isNaN(amount)) return '₹ 0.00';
    amount = typeof amount !== "number" ? parseFloat(amount) : amount;

    // Round off to two decimal places
    amount = Math.round(amount * 100) / 100;

    return new Intl.NumberFormat('en-IN', {
        style: 'currency',
        currency: 'INR',
        minimumFractionDigits: 2,
    }).format(amount);
}

function formatDateDMY(dateString) {
    if (!dateString) return '-';
    const date = new Date(dateString);
    if (isNaN(date.getTime())) return '-';
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
}

app.config.globalProperties.$convertImageToBase64 = async function(imageUrl) {
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", imageUrl, true);
        xhr.responseType = "blob";
        xhr.onload = function() {
            if (xhr.status === 200) {
                const reader = new FileReader();
                reader.readAsDataURL(xhr.response);
                reader.onloadend = () => resolve(reader.result);
            } else {
                reject(new Error("Error fetching image"));
            }
        };
        xhr.onerror = () => reject(new Error("Network error"));
        xhr.send();
    });
};

// Fetch Company Data and Logo, setting them as global properties
async function fetchCompanyData() {
    const user = UserObject;
    if (!user) return;

    const companyId = user.user.companyId;

    try {
        // Fetch company data
        const companyResponse = await axios.get(`${app.config.globalProperties.$apiEndPoint}/company/${companyId}`);
        app.config.globalProperties.$CompanyData = companyResponse.data;

        const payload = { entityId: companyId, fileCategory: "logo", moduleName: "company" };
        const logoResponse = await axios.post(`${app.config.globalProperties.$apiEndPoint}/company/getFileSignedURL/`, payload);
        const logoUrl = Object.values(logoResponse.data)[0];
        
        if (logoUrl) {
            const xhr = new XMLHttpRequest();
            xhr.open("GET", logoUrl, true);
            xhr.responseType = "blob";
            xhr.onload = function() {
                if (xhr.status === 200) {
                    const reader = new FileReader();
                    reader.readAsDataURL(xhr.response);
                    reader.onloadend = () => {
                        app.config.globalProperties.$CompanyLogoUrl = reader.result;
                    };
                }
            };
            xhr.send();
        }
    } catch (error) {
        console.error("Error fetching company data or logo:", error);
    }
}

fetchCompanyData();

// File Handling Methods
app.config.globalProperties.$downloadFile = function (AwsFileName, FileName, FileCategory, ModuleName) {
    const payload = { awsFileName: AwsFileName, fileCategory: FileCategory, moduleName: ModuleName, fileName: FileName };

    axios.post(`${app.config.globalProperties.$apiEndPoint}/company/getFileSignedURL/`, payload)
        .then(response => {
            const fileUrl = Object.values(response.data)[0];
            if (fileUrl) {
                const xhr = new XMLHttpRequest();
                xhr.open("GET", fileUrl);
                xhr.responseType = "blob";
                xhr.onload = function () {
                    if (xhr.status === 200) {
                        const link = document.createElement("a");
                        link.href = window.URL.createObjectURL(xhr.response);
                        link.download = FileName;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                };
                xhr.send();
            }
        });
};

app.config.globalProperties.$viewFile = function (AwsFileName, FileName, FileCategory, ModuleName) {
    const payload = { awsFileName: AwsFileName, fileCategory: FileCategory, moduleName: ModuleName, fileName: FileName };

    axios.post(`${app.config.globalProperties.$apiEndPoint}/company/getFileSignedURL/`, payload)
        .then(response => {
            const fileUrl = Object.values(response.data)[0];
            if (fileUrl) window.open(fileUrl, "_blank");
        });
};

app.use(router).mount('#app');
