<template>
  <div
    class="modal fade"
    id="taskModal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="taskModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <!-- Modal header -->
        <div class="modal-header">
          <h5 class="modal-title" style="margin-left: 40%">
            <img
              class="taskImage"
              src="../../assets/icons/Task.png.png"
              alt="Task Icon"
              style="width: 25px; height: 25px; object-fit: contain; margin-right: 10px"
            />
            Task
          </h5>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <!-- Modal body -->
        <div class="modal-body">
          <!-- Task modal content -->
          <div class="mb-3 row">
            <label for="title" class="col-sm-2 col-form-label">Title:</label>
            <div class="col-sm-10">
              <input
                type="text"
                v-model="taskData.title"
                name="title"
                id="title"
                class="form-control"
                @blur="fetchEmployees()"
              />
            </div>
          </div>
          <div class="mb-3 row">
            <label for="date" class="col-sm-2 col-form-label">Date:</label>
            <div class="col-sm-10">
              <input
                type="date"
                v-model="taskData.date"
                name="date"
                id="date"
                class="form-control"
              />
            </div>
          </div>
          <div class="mb-3 row">
            <label for="time" class="col-sm-2 col-form-label">Time:</label>
            <div class="col-sm-10 d-flex align-items-center">
              <input
                type="time"
                v-model="taskData.fromTime"
                name="fromTime"
                id="fromTime"
                class="form-control me-2"
              />
              <p style="letter-spacing: 2px; font-weight: lighter; margin: 0 5px">To</p>
              <input
                type="time"
                v-model="taskData.toTime"
                name="toTime"
                id="toTime"
                class="form-control ms-2"
              />
            </div>
          </div>
          <!-- Inside the modal body -->
          <div class="mb-3 row">
            <label for="assignedTo" class="col-sm-2 col-form-label">Assign:</label>
            <div class="col-sm-10 position-relative">
              <input
                type="search"
                v-model="searchEmployee"
                @input="searchEmployees"
                name="assignedTo"
                id="assignedTo"
                class="form-control"
              />
              <div v-if="showEmployeeDropdown" class="employee-dropdown">
                <ul>
                  <li
                    v-for="employee in filteredEmployees()"
                    :key="employee.employeeUserId"
                    :value="employee.employeeUserId"
                    @click="selectEmployee(employee)"
                    style="cursor: pointer"
                    class="ms-1"
                  >
                    {{ employee.firstName }} {{ employee.lastName }}
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="mb-3 row">
            <label for="description" class="col-sm-2 col-form-label">Description:</label>
            <div class="col-sm-10">
              <textarea
                id="description"
                name="description"
                v-model="taskData.description"
                rows="4"
                class="form-control"
              ></textarea>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer d-flex justify-content-center" v-if="!this.taskData.id">
          <button
            type="button"
            class="btn btn-sm btn-primary"
            @click="postTask"
            data-bs-dismiss="modal"
            :disabled="!taskData.title || !taskData.date"
          >
            Done
          </button>
          <button
            type="button"
            class="btn btn-sm btn-secondary"
            @click="resetForm"
            data-bs-dismiss="modal"
          >
            Cancel
          </button>
        </div>
        <div class="modal-footer d-flex justify-content-center" v-else>
          <button
            type="button"
            class="btn btn-sm btn-success"
            @click="postTask"
            data-bs-dismiss="modal"
          >
            Update
          </button>
          <button
            type="button"
            class="btn btn-sm btn-danger"
            @click="deleteTask"
            data-bs-dismiss="modal"
          >
            Delete
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "CreateTaskModal",
  props: {
    selectedTaskId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      taskData: {
        id: null,
        title: "",
        date: new Date().toISOString().slice(0, 10),  
        fromTime: null,
        toTime: null,
        assignedTo: null,
        description: "",
      },
      taskModal: false, // Flag to control the visibility of the task modal
      employees: [], // Store fetched employees
      searchEmployee: "", // Input for searching employees
      showEmployeeDropdown: false, // Flag to show/hide employee dropdown
    };
  },
  mounted() {
    this.fetchEmployees();
  },
  watch: {
    selectedTaskId(newValue) {
      if (newValue !== null) {
        this.taskData.id = newValue;
        this.fetchTaskDataById();
      } else {
        this.taskData.id = null;
        this.resetForm();
      }
    },
  },
  methods: {
    // Method to post a task
    postTask() {
      console.log("Posting Task:", this.taskData);
      if (!this.taskData.id) {
        axios
          .post(`${this.$apiEndPoint}/tasks`, this.taskData)
          .then((response) => {
            this.$emit("fetchData");
            this.resetForm(); // Reset the form data
            console.log("Task added successfully:", response.data);
            this.taskModal = false; // Close the modal after successful submission
          })
          .catch((error) => {
            this.$handleError(error);
          });
      } else {
        axios
          .put(`${this.$apiEndPoint}/tasks/updateTask/${this.taskData.id}`, this.taskData)
          .then((response) => {
            this.$emit("fetchData");
            this.resetForm(); // Reset the form data
            console.log("Task added successfully:", response.data);
            this.taskModal = false; // Close the modal after successful submission
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    async fetchTaskDataById() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/tasks/${this.selectedTaskId}`
        );
        this.taskData = response.data;
        this.mapAssignedEmployeeName();
        console.log(this.taskData);
      } catch (error) {
        this.$handleError(error);
      }
    },
    mapAssignedEmployeeName() {
      const assignedEmployee = this.employees.find(
        (employee) => employee.employeeUserId === this.taskData.assignedTo
      );
      if (assignedEmployee) {
        this.searchEmployee = `${assignedEmployee.firstName} ${assignedEmployee.lastName}`;
      } else {
        this.searchEmployee = ""; // Clear the input if no employee is found
      }
    },
    deleteTask() {
      if (this.taskData.id) {
        axios
          .delete(`${this.$apiEndPoint}/tasks/${this.selectedTaskId}`)
          .then((response) => {
            console.log("Task deleted successfully:", response.data);
            this.$emit("fetchData");
            this.resetForm(); // Reset the form data
          })
          .catch((error) => {
            this.$handleError(error);
          });
      }
    },
    fetchEmployees() {
      axios
        .get(`${this.$apiEndPoint}/employee/allEmployees/All`)
        .then((response) => {
          // Filter out employees with null first name or last name
          this.employees = response.data.filter(
            (employee) => employee.firstName && employee.lastName
          );

          console.log(this.employees);
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },

    // Method to search employees based on input
    searchEmployees() {
      this.showEmployeeDropdown = true;
    },
    // Method to filter employees based on search input
    filteredEmployees() {
      console.log("searchEmployee:", this.searchEmployee);
      if (this.searchEmployee && typeof this.searchEmployee === "string") {
        return this.employees.filter(
          (employee) =>
            employee.firstName.includes(this.searchEmployee) ||
            employee.lastName.includes(this.searchEmployee)
        );
      } else {
        return this.employees;
      }
    },

    // Method to select an employee
    selectEmployee(employee) {
      this.searchEmployee = `${employee.firstName} ${employee.lastName}`;
      this.taskData.assignedTo = employee.employeeUserId;
      this.showEmployeeDropdown = false;
    },

    // Method to reset the form
    resetForm() {
      this.taskData = {
        id: null,
        title: "",
        date: null,
        fromTime: null,
        toTime: null,
        assignedTo: null,
        description: "",
      };
      this.searchEmployee = ""; // Reset the search input
      this.showEmployeeDropdown = false; // Hide the employee dropdown
    },
  },
};
</script>

<style scoped>
input:focus,
textarea:focus,
select:focus {
  outline: none; /* Remove default outline */
  box-shadow: none; /* Add a custom focus shadow */
}
input,
textarea,
select,
label {
  font-size: 14px;
}

.employee-dropdown {
  position: absolute;
  display: block;
  background-color: #fff;
  border: 1px solid #ccc;
  max-height: 200px;
  border-radius: 5px;
  overflow-y: auto;
  z-index: 1000; /* Ensure the dropdown appears above other elements */
  width: 93%; /* Adjust width to match the input */
  margin-left: 15px;
  color: #000000;
  top: 100%; /* Position the dropdown below the input */
  left: 0;
}

.employee-dropdown ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.employee-dropdown ul li {
  padding: 8px 10px;
  font-size: 14px;
  cursor: pointer;
}

.employee-dropdown ul li:hover {
  background-color: #f0f0f0;
  font-size: 14px;
}
</style>
