<template>
  <div>
    <Header />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <h5 class="title">EndPoint Access</h5>
        <hr class="line-break" style="border-bottom: 2px solid black" />

        <div class="table-section">
          <div class="button-row">
            <button @click="displayData('Admin')" :class="{ active: activeButton === 'Admin' }" class="btn btn-m">
              Admin
            </button>
            <button @click="displayData('Sales')" :class="{ active: activeButton === 'Sales' }" class="btn btn-m">
              Sales
            </button>
            <button @click="displayData('Designing')" :class="{ active: activeButton === 'Designing' }"
              class="btn btn-m">
              Design
            </button>
            <button @click="displayData('Procurement')" :class="{ active: activeButton === 'Procurement' }"
              class="btn btn-m">
              Procurement
            </button>
            <button @click="displayData('Onsite')" :class="{ active: activeButton === 'Onsite' }"
              class="btn btn-m">
              Onsite
            </button>
            <button @click="displayData('Accounts')" :class="{ active: activeButton === 'Accounts' }" class="btn btn-m">
              Accounts
            </button>
            <button @click="displayData('HR')" :class="{ active: activeButton === 'HR' }" class="btn btn-m">
              HR
            </button>
            <button @click="displayData('Service')" :class="{ active: activeButton === 'Service' }" class="btn btn-m">
              Service
            </button>
          </div>

          <div class="table-wrapper">
            <table class="custom-table">
              <thead>
                <tr>
                  <th>API/Teams</th>
                  <th>Admin</th>
                  <th>Sales</th>
                  <th>Designing</th>
                  <th>Procurement</th>
                  <th>Onsite</th>
                  <th>Accounts</th>
                  <th>HR</th>
                  <th>Service</th>
                </tr>
              </thead>
              <tbody>
                <!-- "All" row for controlling access across all endpoints -->
                <tr>
                  <td>All</td>
                  <td><input type="checkbox" @change="toggleAll(0, 'Admin')" /></td>
                  <td><input type="checkbox" @change="toggleAll(1, 'Sales')" /></td>
                  <td><input type="checkbox" @change="toggleAll(2, 'Designing')" /></td>
                  <td><input type="checkbox" @change="toggleAll(3, 'Procurement')" /></td>
                  <td><input type="checkbox" @change="toggleAll(4, 'Onsite')" /></td>
                  <td><input type="checkbox" @change="toggleAll(5, 'Accounts')" /></td>
                  <td><input type="checkbox" @change="toggleAll(6, 'HR')" /></td>
                  <td><input type="checkbox" @change="toggleAll(7, 'Service')" /></td>
                </tr>

                <!-- Dynamic row for each endpoint -->
                <tr v-for="endpoint in checkboxData[activeButton]" :key="endpoint.id">
                  <td>{{ endpoint.endPointName }}</td>
                  <td><input type="checkbox" v-model="endpoint.permissions[0]" @change="autoSave(endpoint.id)" /></td>
                  <td><input type="checkbox" v-model="endpoint.permissions[1]" @change="autoSave(endpoint.id)" /></td>
                  <td><input type="checkbox" v-model="endpoint.permissions[2]" @change="autoSave(endpoint.id)" /></td>
                  <td><input type="checkbox" v-model="endpoint.permissions[3]" @change="autoSave(endpoint.id)" /></td>
                  <td><input type="checkbox" v-model="endpoint.permissions[4]" @change="autoSave(endpoint.id)" /></td>
                  <td><input type="checkbox" v-model="endpoint.permissions[5]" @change="autoSave(endpoint.id)" /></td>
                  <td><input type="checkbox" v-model="endpoint.permissions[6]" @change="autoSave(endpoint.id)" /></td>
                  <td><input type="checkbox" v-model="endpoint.permissions[7]" @change="autoSave(endpoint.id)" /></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "../Header.vue";
import Nav from "../Nav.vue";
import axios from "axios";

export default {
  components: {
    Header,
    Nav,
  },
  data() {
    return {
      activeButton: 'Admin', // Default active team
      checkboxData: {}, // Will hold the data for each team
    };
  },

  mounted() {
    this.fetchCheckboxData(this.activeButton);
  },

  methods: {
    async displayData(team) {
      this.activeButton = team; // Set the active button to the clicked team
      await this.fetchCheckboxData(team); // Fetch data for the selected team
    },

    async fetchCheckboxData(team) {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/endPointAccess/${team}`);

        // Map the response to include permissions arrays
        this.checkboxData[team] = response.data.map(endpoint => ({
          ...endpoint,
          permissions: [
            endpoint.teamsHavingAccess.includes('Admin'),
            endpoint.teamsHavingAccess.includes('Sales'),
            endpoint.teamsHavingAccess.includes('Designing'),
            endpoint.teamsHavingAccess.includes('Procurement'),
            endpoint.teamsHavingAccess.includes('Onsite'),
          ]
        }));
        console.log(this.checkboxData);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    },

    async autoSave(endpointId) {
      // Existing autoSave function for individual checkbox changes
      const endpointData = this.checkboxData[this.activeButton].find(item => item.id === endpointId);
      if (endpointData) {
        try {
          const payload = {
            endPointName: endpointData.endPointName,
            teamsHavingAccess: endpointData.permissions
              .map((permission, index) => permission ? this.getTeamName(index) : null)
              .filter(teamName => teamName !== null),
          };

          await axios.put(`${this.$apiEndPoint}/endPointAccess/${endpointId}`, payload);
          console.log("Auto-saved:", payload);
          this.$toast("Saved Successfully", "success");
        } catch (error) {
          console.error("Error saving data:", error);
          this.$handleError(error);
        }
      }
    },

    getTeamName(index) {
      const teamNames = ['Admin', 'Sales', 'Designing', 'Procurement', 'Onsite', 'Accounts', 'HR', 'Service'];
      return teamNames[index];
    },

    async toggleAll(columnIndex, teamName) {
      // Check the current state of all checkboxes in the column to determine if we should check or uncheck
      const shouldCheck = !this.checkboxData[this.activeButton].every(endpoint => endpoint.permissions[columnIndex]);

      // Update each endpoint's permissions for the specified column index
      this.checkboxData[this.activeButton].forEach(endpoint => {
        endpoint.permissions[columnIndex] = shouldCheck;
      });

      // Make the API call to update all access
      await this.updateAllAccess(teamName, shouldCheck);
    },

    async updateAllAccess(teamName, accessGranted) {
      try {
        // Define payload according to whether access should be granted or revoked
        const payload = {
          teamsHavingAccess: teamName,
          accessGranted: accessGranted // Assuming the API can handle this property to either add or remove access
        };

        // Call the API
        await axios.put(`${this.$apiEndPoint}/endPointAccess/updateAll/${this.activeButton}`, payload);
        console.log(`Updated all access for ${teamName}:`, accessGranted);

        this.$toast("All access updated", "success");
      } catch (error) {
        console.error("Error updating all access:", error);
        this.$handleError(error);
      }
    },

  },
};
</script>

<style scoped>
.line-break {
  border: 1px solid rgb(95, 95, 95);
  width: 98%;
  display: block;
  justify-content: center;
  margin: 0 auto;
  margin-top: 5px;
  /* Centers the element horizontally */
}

.title {
  font-weight: bold;
  padding-left: 20px;
  padding-top: 12px;
}

.table-section {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.button-row {
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  margin-bottom: 15px;
  width: 100%;
  padding: 1%;
}

.btn-m {
  position: relative;
  padding: 10px 20px;
  color: #181a1d;
  background: none;
  border: none;
  /* No border */
  transition: background-color 0.3s ease, color 0.3s ease;
}

.btn-m.active {
  color: #0d6efd;
  /* Change text color for contrast */
  border-bottom: #0d6efd;
}

.btn-m::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  /* Thickness of the bottom line */
  background-color: #0d6efd;
  /* Bottom line color */
  transition: width 0.3s ease, left 0.3s ease;
  /* Smooth animation */
}

.btn-m:hover::after,
.btn-m.active::after {
  width: 100%;
  left: 0;
}

.btn-m:hover {
  color: #0d6efd;
}

.table-wrapper {
  width: 100%;
  overflow-y: auto;
  max-height: 450px;
  /* Adjust this height as needed */
  border-radius: 4px;
  padding-left: 1%;
}

.custom-table {
  width: 100%;
  max-width: 90%;
  /* Adjusted for larger screens */
  border-collapse: collapse;
  text-align: center;
}

.custom-table thead th {
  position: sticky;
  top: 0;
  background-color: #dfdfdf;
  /* Background color for the header */
  z-index: 1;
  font-weight: 400;
  padding: 8px 4px;
}

.custom-table th,
.custom-table td {
  border: 1px solid #ddd;
  padding: 6px 4px;
  max-width: 160px;
  word-wrap: break-word;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Styling for larger checkboxes */
.custom-table input[type="checkbox"] {
  transform: scale(1.2);
}

.custom-table th {
  background-color: #bebab5;
  font-weight: 400;
}


@media (max-width: 1024px) {
  .btn-m {
    min-width: 100px;
    padding: 8px 12px;
  }

  .table-wrapper {
    padding: 1%;
  }

  .button-row {
    display: block;
    margin-bottom: 15px;
    width: 100%;
    padding: 1%;
  }

  .custom-table th,
  .custom-table td {
    padding: 8px 6px;
  }
}

@media (max-width: 768px) {
  .button-row {
    justify-content: center;
    /* Center align on smaller screens */
  }

  .btn-m {
    padding: 8px 16px;
    /* Reduce padding on smaller screens */
    font-size: 0.9rem;
    /* Slightly smaller font size */
  }

  .custom-table th,
  .custom-table td {
    padding: 10px;
    width: auto;
  }
}
</style>
