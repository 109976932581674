<template>
  <div class="EmployeesignupGroup">
    <div class="row d-flex align-items-center">
      <div class="col-md-7 col-sm-7 col-lg-7">
        <div class="loginImg d-flex justify-content-center">
          <img src="../assets/icons/loginImage.png" alt="" />
        </div>
      </div>
      <div class="col-md-5 col-sm-5 col-lg-5" v-if="loginPage">
        <div class="login align-items-center">
          <div class="logo d-flex justify-content-center">
            <h1>ARIVU</h1>
          </div>
          <div class="Employeesignup" @keydown="handleKeydown">
            <span class="input-wrapper mb-2">
              <label :for="authMethod === 'otp' ? 'email' : 'phone'"
                >&nbsp;{{ authMethod === "otp" ? "Email ID" : "Email ID/Phone No" }}&nbsp;
              </label>
              <input type="text" v-model="email" @click="clearValidationMessage" />
              <p style="color: red" class="validation-message">
                {{ emailValidationMessage }}
              </p>
            </span>
            <div class="radio-group mt-4">
              <label>
                <input type="radio" v-model="authMethod" value="password" />
                Password
              </label>
              <label>
                <input type="radio" v-model="authMethod" value="otp" />
                OTP
              </label>
            </div>
            <span class="input-wrapper" v-if="authMethod === 'password'">
              <label for="password-input">&nbsp;Password&nbsp;</label>

              <input
                id="password-input"
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
                @click="clearValidationMessage"
              />
              <img
                :src="
                  showPassword
                    ? require('@/assets/icons/eye-open.jpg')
                    : require('@/assets/icons/eye-closed.jpg')
                "
                alt="Toggle Password Visibility"
                class="toggle-password-icon"
                @click="togglePasswordVisibility"
              />

              <p
                class="d-flex justify-content-end"
                style="color: #4884f7; cursor: pointer"
                @click="forgetPassword"
              >
                Forgot Password?
              </p>
            </span>

            <span class="input-wrapper" v-if="authMethod === 'otp'">
              <label for="">&nbsp;OTP&nbsp;</label>
              <input type="text" v-model="otp" @click="clearOtpValidationMessage" />

              <p
                class="d-flex justify-content-end"
                style="color: #4884f7; cursor: pointer"
                @click="generateOTP"
                :style="{
                  pointerEvents: generatingOTP || countdownTimer < 0 ? 'none' : 'auto',
                }"
              >
                {{
                  generatingOTP
                    ? `Regenerate OTP in ${countdownTimer} seconds`
                    : otpGenerated
                    ? "Regenerate OTP"
                    : "Generate OTP"
                }}
              </p>
            </span>
            <p style="color: red" class="validation-message mt-2">
              {{ otpValidationMessage }}
            </p>
            <button
              type="button"
              class="confirmationButton"
              id="EmployeeSignButton"
              @click="login"
              :disabled="loading"
            >
              <span class="confirmationButton__text">Sign In</span>
            </button>

            <div class="signUp d-flex justify-content-center align-items-center">
              Create new Account? &nbsp;
              <router-link to="/sign-up" style="text-decoration: none"
                >Sign Up</router-link
              >
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5 col-sm-5 col-lg-5" v-if="forgetPasswordPage">
        <div class="login forgetPassword align-items-center">
          <div class="logo d-flex justify-content-center">
            <h1>ARIVU</h1>
          </div>
          <div class="Employeesignup">
            <span> Enter your Email ID to receive a link to reset the password. </span>
            <span class="input-wrapper forgetPassword d-flex mt-3">
              <label for="">&nbsp;Email ID&nbsp;</label>
              <input
                type="email"
                v-model="forgetPasswordEmail"
                style="border-right: none; border-radius: 5px 0 0 5px"
              />

              <p class="forgetButton" style="margin-right: 30px" @click="sendResetLink">
                <img src="../assets/icons/forgetPassword.png" alt="" />
              </p>
            </span>
            <small
              v-if="this.forgetPasswordEmail && !isValidEmail(this.forgetPasswordEmail)"
              class="text-danger mt-2"
              >Please enter a valid email address.</small
            >
            <p
              class="d-flex justify-content-right mt-3"
              style="color: #4884f7; cursor: pointer"
              @click="log"
            >
              Back to login
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import setAuthHeader from "../utilToken/setAuthHeader";
import axios from "axios";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      email: this.$route.params.email || "",
      password: "",
      otp: "",
      authMethod: "password", // Default authentication method is password
      loginPage: true,
      forgetPasswordPage: false,
      forgetPasswordEmail: "",
      generatingOTP: false,
      countdownTimer: 30,
      otpGenerated: false,
      timer: null,
      emailValidationMessage: "",
      otpValidationMessage: "",
      showPassword: false,
    };
  },
  mounted() {
    let user = localStorage.getItem("user-info");
    if (user) {
      this.$router.push({ name: "Home" });
    } else {
      this.$router.push({ name: "Login" });
    }
  },
  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword;
    },
    isValidEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    },
    handleKeydown(event) {
      if (event.key === "Enter") {
        this.login();
      }
    },
    log() {
      this.loginPage = true;
      this.forgetPasswordPage = false;
    },

    forgetPassword() {
      this.loginPage = false;
      this.forgetPasswordPage = true;
    },

    async login() {
      const btn = document.querySelector(".confirmationButton");

      btn.classList.add("confirmationButton--loading");

      this.loading = true;
      const credentials = {
        email: this.email,
        password: this.password,
        otp: this.otp,
      };

      try {
        if (!this.authMethod) {
          alert("Please select an authentication method.");
          return;
        }

        if (this.authMethod === "otp") {
          this.validateOTP(); // Call validateOTP method for OTP authentication
        } else {
          // Handle Password-based login logic
          const response = await axios.post(
            `${this.$apiEndPoint}/users/login`,
            credentials
          );
          btn.classList.remove("confirmationButton--loading");
          if (
            response.status === 200 &&
            response.data.user &&
            response.data.employeeRoles
          ) {
            this.$toast("Login successful.", "success");
            localStorage.setItem("user-info", JSON.stringify(response.data));
            localStorage.setItem("jwtToken", response.data.accessToken);
            setAuthHeader(response.data.accessToken);
            this.$router.push({ name: "Home" });
            window.location.reload();
          } else {
            this.$toast("Login failed. Please contact Arivu team .", "error");
          }
        }
      } catch (error) {
        this.$handleError(error);
        btn.classList.remove("confirmationButton--loading");
      } finally {
        this.email = "";
        this.password = "";
        this.otp = "";
        this.loading = false; // Reset loading state after login request completes
      }
    },

    //Forget password
    sendResetLink() {
      // Check if email is not empty
      if (this.forgetPasswordEmail.trim() !== "") {
        // Call your emailSend API with the entered email
        axios
          .post(`${this.$apiEndPoint}/users/passwordResetInvitation`, {
            email: this.forgetPasswordEmail,
          })
          .then((response) => {
            // Handle success response here
            this.$toast("Reset link sent successfully!.", "success");
            console.log(response.data);
            this.forgetPasswordEmail = "";
          })
          .catch((error) => {
            // Handle error response here
            this.$handleError(error);
          });
      } else {
        // Handle case where email field is empty
        alert("Please enter your email.");
      }
    },

    clearValidationMessage() {
      this.emailValidationMessage = ""; // Set emailValidationMessage to an empty string
    },

    clearOtpValidationMessage() {
      this.otpValidationMessage = "";
    },

    generateOTP() {
      // Check if OTP is already being generated
      if (this.generatingOTP) return;

      if (!this.email.trim()) {
        this.emailValidationMessage = "Please enter your email ID."; // Set validation message
        return;
      } else {
        this.emailValidationMessage = ""; // Clear validation message
      }

      this.generatingOTP = true;
      this.countdownTimer = 30;
      this.otpGenerated = false;

      this.timer = setInterval(() => {
        if (this.countdownTimer > 0) {
          this.countdownTimer--;
        } else {
          clearInterval(this.timer);
          this.generatingOTP = false; // Reset generatingOTP flag after countdown finishes
        }
      }, 1000);

      axios
        .post(`${this.$apiEndPoint}/users/generateOTP`, { email: this.email })
        .then((response) => {
          // Handle success response here
          console.log(response.data);
          alert("OTP sent successfully! Check your email");
          this.otpGenerated = true;
        })
        .catch((error) => {
          // Handle error response here
          this.$handleError(error);
        });
    },

    beforeDestroy() {
      // Clear the timer when the component is destroyed
      clearInterval(this.timer);
    },

    validateOTP() {
      const btn = document.querySelector(".confirmationButton");

      btn.classList.add("confirmationButton--loading");
      const otpData = {
        email: this.email,
        otp: this.otp,
      };

      axios
        .post(`${this.$apiEndPoint}/users/validateOTP`, otpData)
        .then((response) => {
          btn.classList.remove("confirmationButton--loading");
          if (response.status === 200) {
            localStorage.setItem("user-info", JSON.stringify(response.data));
            localStorage.setItem("jwtToken", response.data.accessToken);
            setAuthHeader(response.data.accessToken);
            alert("Logged In Successfully!");
            // Display success message
            // Redirect to the Home page or any other page after successful validation
            this.$router.push({ name: "Home" });
          } else {
            alert(response.data); // Display error message from the backend
          }
        })
        .catch((error) => {
          btn.classList.remove("confirmationButton--loading");
          if (error.response.status === 400) {
            this.otpValidationMessage = error.response.data;
          } else {
            this.$handleError(error);
            alert("Error validating OTP. Please try again."); // Display generic error message
          }
        });
    },
    beforeUnmount() {
      clearInterval(this.timer);
    },
  },
};
</script>

<style scoped>
.loginImg img {
  max-width: 80%;
  max-height: 80%;
}

.logo h1 {
  margin-bottom: 8%;
}

.radio-group {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.radio-group label {
  margin-right: 20px;
  margin-bottom: 20px;
}

.EmployeesignupGroup {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #ffffff;
}

#EmployeeSignupHeading {
  margin-top: 5%;
  font-size: 2rem;
}

.Employeesignup {
  display: flex;
  margin-top: 2%;
  flex-direction: column;
}

.Employeesignup p {
  color: #fff;
}

#EmployeeSignButton {
  margin: 10% 30%;
  margin-top: 10%;
  padding: 5px;
  background-color: #4884f7;
  color: #fff;
  font-size: 1rem;
  border-radius: 5px;
  outline: none;
  border: none;
  cursor: pointer;
}

.signUp {
  margin-top: -5%;
}

.forgetPassword {
  margin-top: -40%;
}

.EmployeeSignUpFooter a {
  color: #4884f7;
  cursor: pointer;
}

.input-wrapper {
  min-width: 400px;
  height: 40px;
  position: relative;
}

/* .input-wrapper p {
  margin-left: 55%;
  margin-top: 2%;
} */

.input-wrapper input {
  padding: 5% 2%;
  height: 100%;
  width: 100%;
  /* z-index: 99; */
  border: 1.5px solid black;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.3s ease, background-color 0.3s ease;
  background-color: #ffffff;
  font-weight: bolder;
  /* Set input text color */
  font-size: 1rem;
}

.forgetButton img {
  padding: 18%;
  width: 130%;
  height: 200%;
  background-color: #a2bff6;
  border: 1.5px solid #000000;
  border-left: none;
  border-radius: 0 5px 5px 0;
  z-index: 1;
  cursor: pointer;
}

.input-wrapper label {
  white-space: nowrap;
  text-align: left;
  /* Adjusted to align left */
  position: absolute;
  margin-top: -13px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  pointer-events: none;
  z-index: 1;
  font-size: 1rem;
  transition: all 0.4s ease;
  color: #000000;
  font-weight: 500;
  background-color: #ffffff;
}

.input-wrapper input:focus {
  border-color: #4884f7;
}

.input-wrapper input:focus ~ label,
.input-wrapper input:not(:placeholder-shown) ~ label {
  margin-top: -20px;
  /* Adjusted to move label up */
  left: 10px;
  font-size: 16px;
  background-color: #084f5b;
}

.input-wrapper input:not(:focus):not(:placeholder-shown) ~ label {
  font-size: 1.1rem;
  margin-top: -10px;
  /* Adjusted to overlap with input when empty */
  left: 10px;
  background-color: #084f5b;
}

.password-wrapper {
  position: relative;
}

.toggle-password-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  width: 20px; /* Adjust as needed */
}

@media (max-width: 768px) {
  .login {
    margin-top: -30%;
  }

  .loginImg img {
    display: none;
  }

  .EmployeesignupGroup h2 {
    margin-bottom: 10%;
  }

  #EmployeeSignupHeading {
    margin-top: -40%;
    font-size: 1.5rem;
  }

  .input-wrapper {
    min-width: 320px;
    height: 40px;
    position: relative;
  }

  .forgetPassword {
    margin-left: 3%;
  }

  .Employeesignup span {
    margin-left: 3%;
  }

  .forgetButton img {
    padding: 18%;
    width: 130%;
    height: 168%;
    background-color: #a2bff6;
    border: 1.5px solid #000000;
    border-left: none;
    border-radius: 0 5px 5px 0;
    z-index: 1;
    cursor: pointer;
  }
}
</style>
