// directives/capitalizeFirstLetter.js
export default {
    beforeMount(el) {
      el.addEventListener('input', (event) => {
        let value = event.target.value;
        if (value) {
          event.target.value = value.charAt(0).toUpperCase() + value.slice(1);
          // Trigger input event to update v-model
          const inputEvent = new Event('input', { bubbles: true });
          event.target.dispatchEvent(inputEvent);
        }
      });
    },
  };
  