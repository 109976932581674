<template>
  <div>
    <Header />
    <ProcurementProductList />
    <div class="content-Container">
      <Nav />
      <div class="containerChild">
        <div class="ProcurementComponent">
          <div class="ProcurementProjectComponentHeader p-2">
            <img
              src="../../../assets/icons/black_Designing.png"
              alt=""
              id="ProcurementProjectHeaderImage"
            />
            <div class="ProcurementProjectHeader">
              <section class="ProcurementProjectHeaderRow1">
                <input
                  type="text"
                  name=""
                  class="mainHeading mb-1"
                  style="letter-spacing: 1px"
                  placeholder="Write Company Name here..." 
                  v-model="VendorDetails.companyName"
                
                  ref="companyNameInput"
                />
                <!-- @blur="saveVendorDetails" -->
              </section>
              <section class="ProcurementProjectHeaderRow2 mt-2 ms-1">
                <h6
                  @click="toggleVendorDetailsPage"
                  :class="{
                    'active-SubHeading': showVendorDetails,
                    'inactive-SubHeading': !showVendorDetails,
                  }"
                >
                  Vendor Details
                </h6>
                <h6
                  @click="toggleBillingDetailsPage"
                  :class="{
                    'active-SubHeading': showBillingDetails,
                    'inactive-SubHeading': !showBillingDetails,
                  }"
                >
                  Billing Details
                </h6>
                <h6
                  @click="togglePurchaseOrderListPage"
                  :class="{
                    'active-SubHeading': showPurchaseOrder,
                    'inactive-SubHeading': !showPurchaseOrder,
                  }"
                >
                  Purchase Orders
                </h6>
                <h6
                  @click="toggleInvoicePage"
                  :class="{
                    'active-SubHeading': showPurchaseInvoice,
                    'inactive-SubHeading': !showPurchaseInvoice,
                  }"
                >
                  Purchase Invoice
                </h6>
                <h6
                  @click="toggleRemarkPage"
                  :class="{
                    'active-SubHeading': showRemark,
                    'inactive-SubHeading': !showRemark,
                  }"
                >
                  Remark
                </h6>
              </section>
            </div>
          </div>
          <div class="headerbelowcontent overflow-scroll">
            <div v-if="showVendorDetails" class="ProcurementProjectCustomerDetails">
              <div class="ProcurementProjectCustomerDetailsSections">
                <div class="ProcurementProjectCustomerProfileThings mt-2">
                  <div class="container-fluid col-md-12">
                    <div class="row mx-1 mt-2">
                      <div class="col-md-6">
                        <div class="row mb-3">
                          <label for="VendorId" class="col-lg-5 col-form-label"
                            >Vendor Id</label
                          >
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg w-100"
                              id="VendorId"
                              placeholder="Auto Generate"
                              v-model="VendorDetails.vendorNumber"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="Category" class="col-lg-5 col-form-label"
                            >Category</label
                          >
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg"
                              id="Category"
                              v-model="VendorDetails.category"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="CompanyUrl" class="col-lg-5 col-form-label"
                            >Company URL</label
                          >
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg"
                              id="CompanyUrl"
                              v-model="VendorDetails.companyUrl"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="City" class="col-lg-5 col-form-label">City</label>
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg"
                              id="City"
                              v-model="VendorDetails.city"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="ZipCode" class="col-lg-5 col-form-label"
                            >Zip Code</label
                          >
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg"
                              id="ZipCode"
                              v-model="VendorDetails.pincode"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row mb-3">
                          <label for="GstNumber" class="col-lg-5 col-form-label"
                            >GST Number</label
                          >
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg"
                              id="GstNumber"
                              v-model="VendorDetails.gstNumber"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="PanNumber" class="col-lg-5 col-form-label"
                            >Pan Number</label
                          >
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg"
                              id="PanNumber"
                              v-model="VendorDetails.panNumber"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="Address" class="col-lg-5 col-form-label"
                            >Address</label
                          >
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg"
                              id="Address"
                              v-model="VendorDetails.address"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="State" class="col-lg-5 col-form-label">State</label>
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg"
                              id="State"
                              v-model="VendorDetails.state"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="row mb-2 col-md-12 position-relative ProcurementCustomerDetailsHeader"
                      >
                        <div class="col-md-2 d-flex">
                          <h6 style="color: goldenrod; white-space: nowrap">
                            Primary Contact Details
                          </h6>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row mb-3">
                          <label for="ContactPerson" class="col-lg-5 col-form-label"
                            >Contact Person</label
                          >
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg w-100"
                              id="ContactPerson"
                              v-model="VendorDetails.primaryContactName"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label for="ContactNumber" class="col-lg-5 col-form-label"
                            >Contact Number</label
                          >
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg w-100"
                              id="ContactNumber"
                              v-model="VendorDetails.primaryContactNo"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row mb-3">
                          <label for="PrimaryEmailId" class="col-lg-5 col-form-label"
                            >Email ID</label
                          >
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg"
                              id="PrimaryEmailId"
                              v-model="VendorDetails.primaryEmailId"
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        class="row mb-2 col-md-12 position-relative ProcurementCustomerDetailsHeader"
                      >
                        <div class="col-md-2 d-flex">
                          <h6 style="color: goldenrod; white-space: nowrap">
                            Secondary Contact Details
                          </h6>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row mb-3">
                          <label
                            for="SecondaryContactPerson"
                            class="col-lg-5 col-form-label"
                            >Contact Person</label
                          >
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg w-100"
                              id="SecondaryContactPerson"
                              v-model="VendorDetails.secondaryContactName"
                            />
                          </div>
                        </div>
                        <div class="row mb-3">
                          <label
                            for="SecondaryContactNumber"
                            class="col-lg-5 col-form-label"
                            >Contact Number</label
                          >
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg w-100"
                              id="SecondaryContactNumber"
                              v-model="VendorDetails.secondaryContactNo"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row mb-3">
                          <label for="SecondaryEmailId" class="col-lg-5 col-form-label"
                            >Email ID</label
                          >
                          <div class="col-lg-7">
                            <input
                              type="text"
                              class="form-control inputBg"
                              id="SecondaryEmailId"
                              v-model="VendorDetails.secondaryEmailId"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <footer class="footer">
                <button
                  class="btn btn-sm btn-primary"
                  @click="saveVendorDetails()"
                  :disabled="!VendorDetails.companyName"
                >
                  Save
                </button>
                <button class="btn btn-sm btn-secondary" @click="goBack">Cancel</button>
              </footer>
            </div>

            <div v-if="showBillingDetails" class="EstimateContent mb-5">
              <div class="row mb-2 col-md-12 position-relative"></div>
              <div class="row mx-1 mt-3">
                <div
                  class="row mb-2 col-md-12 position-relative ProcurementCustomerDetailsHeader"
                >
                  <div class="col-md-2 d-flex">
                    <h6 style="color: goldenrod; white-space: nowrap">Billing Address</h6>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mb-3">
                    <label for="CompanyName" class="col-lg-5 col-form-label"
                      >Company Name</label
                    >
                    <div class="col-lg-7">
                      <input
                        type="text"
                        class="form-control inputBg w-100"
                        id="CompanyName"
                        v-model="billingAddressData.name"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="ContactNumber" class="col-lg-5 col-form-label"
                      >Contact Number</label
                    >
                    <div class="col-lg-7">
                      <input
                        type="text"
                        class="form-control inputBg w-100"
                        id="ContactNumber"
                        v-model="billingAddressData.contactNo"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="City" class="col-lg-5 col-form-label">City</label>
                    <div class="col-lg-7">
                      <input
                        type="text"
                        class="form-control inputBg w-100"
                        id="City"
                        v-model="billingAddressData.city"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="ZipCode" class="col-lg-5 col-form-label">Zip Code</label>
                    <div class="col-lg-7">
                      <input
                        type="text"
                        class="form-control inputBg w-100"
                        id="ZipCode"
                        v-model="billingAddressData.pincode"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-lg-7"></div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mb-3">
                    <label for="EmailId" class="col-lg-5 col-form-label">Email Id</label>
                    <div class="col-lg-7">
                      <input
                        type="text"
                        class="form-control inputBg w-100"
                        id="EmailId"
                        v-model="billingAddressData.email"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="Address" class="col-lg-5 col-form-label">Address</label>
                    <div class="col-lg-7">
                      <input
                        type="text"
                        class="form-control inputBg w-100"
                        id="Address"
                        v-model="billingAddressData.address"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="State" class="col-lg-5 col-form-label">State</label>
                    <div class="col-lg-7">
                      <input
                        type="text"
                        class="form-control inputBg w-100"
                        id="State"
                        v-model="billingAddressData.state"
                      />
                    </div>
                  </div>
                </div>
                <hr />
                <div
                  class="row mb-2 col-md-12 position-relative ProcurementCustomerDetailsHeader"
                >
                  <div class="col-md-2 d-flex">
                    <h6 style="color: goldenrod; white-space: nowrap">Bank Details</h6>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mb-3">
                    <label for="BeneficiaryName" class="col-lg-5 col-form-label"
                      >Beneficiary Name</label
                    >
                    <div class="col-lg-7">
                      <input
                        type="text"
                        class="form-control inputBg w-100"
                        id="BeneficiaryName"
                        v-model="bankDetails.beneficiaryName"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="AccountNumber" class="col-lg-5 col-form-label"
                      >Account Number</label
                    >
                    <div class="col-lg-7">
                      <input
                        type="text"
                        class="form-control inputBg w-100"
                        id="AccountNumber"
                        v-model="bankDetails.accountNumber"
                      />
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mb-3">
                    <label for="IFSCCode" class="col-lg-5 col-form-label"
                      >IFSC Code</label
                    >
                    <div class="col-lg-7">
                      <input
                        type="text"
                        class="form-control inputBg w-100"
                        id="IFSCCode"
                        v-model="bankDetails.IFSC"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <label for="BranchName" class="col-lg-5 col-form-label"
                      >Branch Address</label
                    >
                    <div class="col-lg-7">
                      <input
                        type="text"
                        class="form-control inputBg w-100"
                        id="BranchName"
                        v-model="bankDetails.branchAddress"
                      />
                    </div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-lg-7"></div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-lg-7"></div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mb-3">
                    <div class="col-lg-7"></div>
                  </div>
                  <div class="row mb-3">
                    <div class="col-lg-7"></div>
                  </div>
                </div>
              </div>
              <footer class="footer">
                <button class="btn btn-sm btn-primary" @click="saveBankDetails()">
                  Save  Details
                </button>
                <button class="btn btn-sm btn-secondary" @click="goBack">Cancel</button>
              </footer>
            </div>

            <div v-if="showPurchaseOrder">
              <div class="table-container mt-2">
                <section class="">
                  <table class="">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead class="subHeaderHeadingBgColor">
                      <tr>
                        <th>S/N</th>
                        <th>PO Number</th>
                        <th>Reference</th>
                        <th>Date</th>
                        <th>Created By</th>
                        <th>Project Name</th>
                        <th>Status</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(row, index) in PurchaseOrders" :key="index">
                        <td>{{ index + 1 }}</td>
                        <td class="text-nowrap text-left">
                          {{ row.poNumber }}
                        </td>
                        <td>{{ row.poNumber }}</td>
                        <td>
                          {{ row.date }}
                        </td>
                        <td>{{ row["employees.firstName"] }}</td>
                        <td>{{ row["projects.projectName"] }}</td>
                        <td>{{ row.poStatus }}</td>

                        <td class="text-center">
                          <div class="ActionImage">
                            <span
                              @click="openPOModal(row.id)"
                              data-bs-toggle="modal"
                              data-bs-target="#PODetailsModal"
                            >
                              <img
                                src="../../../assets/icons/view.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="Edit"
                                alt=""
                              />
                            </span>
                            <span>
                              <img
                                @click="handleDownloadPO(row.id)"
                                src="../../../assets/icons/pinkDownold.png"
                                data-toggle="tooltip"
                                data-placement="top-start"
                                title="downold"
                                alt=""
                              />
                            </span>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
            </div>
            <div v-if="showPurchaseInvoice">
              <div class="d-flex justify-content-end" style="margin: 0.5% 2.9%">
                <button
                  class="btn btn-sm btn-primary"
                  data-bs-toggle="modal"
                  data-bs-target="#CreatePurchaseInvoice"
                >
                  + Add Purchase Invoice
                </button>
              </div>
              <div class="table-container mt-2">
                <section class="">
                  <table class="">
                    <caption class="d-none">
                      Table
                    </caption>
                    <thead class="subHeaderHeadingBgColor">
                      <tr>
                        <th>S/N</th>
                        <th>Project Name</th>
                        <th>Invoice Date</th>
                        <th>Document No.</th>
                        <th>Amount</th>
                        <th>PO Number</th>
                        <th>Status</th>
                        <th class="text-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(data, index) in PurchaseInvoice" :key="index">
                        <td>
                          {{ index + 1 }}
                        </td>
                        <td>
                          {{ data["purchaseOrders.projects.projectName"] }}
                        </td>
                        <td class="text-left">{{ data.date }}</td>
                        <td>
                          {{ data.documentNumber }}
                        </td>
                        <td>
                          {{ data.amount }}
                        </td>

                        <td class="text-left">{{ data["purchaseOrders.poNumber"] }}</td>

                        <td :class="statusClass(data.invoiceStatus)">
                          <select
                            v-model="data.invoiceStatus"
                            @change="handleStatusChangeOfPI(data.id, data.invoiceStatus)"
                            class="form-select form-select-sm"
                            :class="statusClass(data.invoiceStatus)"
                            style="
                              width: max-content;
                              color: inherit;
                              background-color: inherit;
                            "
                          >
                            <option
                              value="Payment Pending"
                              :style="optionStyle('Payment Pending')"
                            >
                              Payment Pending
                            </option>
                            <option
                              value="Payment Done"
                              :style="optionStyle('Payment Done')"
                            >
                              Payment Done
                            </option>
                            <!-- Add more status options here if needed -->
                          </select>
                        </td>
                        <td class="text-center">
                          <img
                            @click="openModalPI(data.id)"
                            src="../../../assets/icons/yellowEdit.png"
                            class="ms-3"
                            style="width: 17px; height: 17px; cursor: pointer"
                            data-bs-toggle="modal"
                            data-bs-target="#CreatePurchaseInvoice"
                            alt=""
                          />

                          <img
                            class="ms-3"
                            src="../../../assets/icons/view.png"
                            alt=""
                            style="width: 17px; height: 17px; cursor: pointer"
                            @click="
                              $viewFile(
                                data['awsFiles.awsFileName'],
                                data['awsFiles.fileName'],
                                'purchaseInvoice',
                                'procurement'
                              )
                            "
                          />
                          <img
                            class="ms-3"
                            src="../../../assets/icons/pinkDownold.png"
                            alt=""
                            style="width: 17px; height: 17px; cursor: pointer"
                            @click="
                              $downloadFile(
                                data['awsFiles.awsFileName'],
                                data['awsFiles.fileName'],
                                'purchaseInvoice',
                                'procurement'
                              )
                            "
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </section>
              </div>
            </div>

            <div v-if="showRemark" class="RemarksContent mb-5">
              <div>
                <div class="sectionComments">
                  <section
                    class="d-flex flex-column mt-4"
                    style="width: 90%; margin: 0 5%"
                  >
                    <textarea
                      name="leadComments"
                      id="leadComments"
                      class="form-control mb-2"
                      style="height: 120px"
                      cols="20"
                      rows="5"
                      placeholder="Add Text....."
                      v-model="this.remark"
                    ></textarea>
                    <button class="browse align-self-end" @click="sendProjectComment()">
                      Done
                    </button>
                  </section>
                </div>
              </div>
              <div class="sectionOther col-md-12" style="width: 90%; margin: 0 3%">
                <div class="row">
                  <div
                    class="col-md-10"
                    style="word-break: break-all; white-space: pre-wrap"
                  >
                    <ol>
                      <li
                        v-for="(comment, index) in projectComments"
                        :key="index"
                        class="m-3"
                      >
                        <span style="font-size: 16px" v-if="comment.remark">
                          <span class="comment-marker"> </span>
                          {{ comment.remark }}</span
                        >
                        <pre
                          v-if="comment.remark"
                          style="font-size: 12px; letter-spacing: 2px"
                          class="text-muted d-flex justify-content-between col-md-6"
                        >
                          <span>{{ comment.created_at }}</span>
                        </pre>
                      </li>
                    </ol>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <CreatePurchaseInvoice :SelectedPIId="selectedPIId" @fetchpurchaseInvoice="fetchData" />
  <PODetailsModal :PurchaseOrderId="selectedPRId" ref="PODetailsModal" />
</template>

<script>
import CreatePurchaseInvoice from "../../Modal/CreatePurchaseInvoice.vue";
import PODetailsModal from "../../Modal/PODetailsModal.vue";
import Header from "../../Header.vue";
import Nav from "../../Nav.vue";
import axios from "axios";
import ProcurementProductList from "../../Modal/ProcurementProductList.vue";
export default {
  name: "ProcurementVendorDetails",
  components: {
    Header,
    Nav,
    ProcurementProductList,
    PODetailsModal,
    CreatePurchaseInvoice,
  },

  data() {
    return {
      selectedPIId: null,
      selectedPRId: null,
      PurchaseInvoice: [],
      PurchaseOrders: [],
      VendorId: null,
      showVendorDetails: true,
      showBillingDetails: false,
      showAddress: false,
      showBankdetails: false,
      showPurchaseOrder: false,
      showPurchaseInvoice: false,
      showAddProductList: false,
      showCustomerRemark: false,
      productListData: [],
      createProduct: {
        productName: "",
        description: "",
        HSNCode: "",
        category: "",
        subCategory: "",
        discount: "",
        GST: "",
        unitPrice: "",
        status: "",
      },
      billingAddressData: {
        id: null,
        name: "", // Your initial companyName value
        email: "",
        address: "",
        city: "",
        state: "",
        country: "India",
        pincode: "",
        contactNo: "",
        gstNumber: null,
        addressType: "Billing", // Or use a variable if it varies
        entityId: this.VendorId,
        entityName: "Vendor",
      },
      bankDetails: {
        id: null,
        beneficiaryName: "",
        accountName: "",
        accountNumber: "",
        bankName: "",
        IFSC: "",
        branchAddress: "",
        entityId: this.VendorId,
        entityName: "Vendor",
      },
      projectComments: [],
      VendorDetails: {
        id: null,
        companyName: "",
        vendorNumber: "",
        category: "",
        companyUrl: "",
        gstNumber: "",
        panNumber: "",
        address: "",
        city: "",
        state: "",
        pincode: " ",
        primaryContactName: "",
        primaryContactNo: "",
        primaryEmailId: "",
        secondaryContactName: "",
        secondaryContactNo: "",
        secondaryEmailId: "",
      },
      remark: null,
    };
  },
  mounted() {
    this.productList();
    this.VendorId = this.$route.params.vendorId;
    console.log(this.VendorId);
    if (this.VendorId) {
      this.fetchVendorDetails(this.VendorId);
    } else {
      // If VendorId is not present, focus the company name input
      this.$nextTick(() => {
        this.$refs.companyNameInput.focus(); // Focus the input
      });
    }
  },

  created() {},
  methods: {
    statusClass(status) {
      switch (status) {
        case "Approved":
          return "approvedText";
        case "Semi Ordered":
          return "semi-deliveredText";
        case "Ordered":
          return "orderedText";
        case "Delivered":
          return "deliveredText";
        case "Rejected":
          return "rejectedText";
        case "Pending":
          return "pendingText";
        case "Payment Pending":
          return "pendingText";
        case "Payment Done":
          return "approvedText";
        default:
          return "";
      }
    },
    optionStyle(status) {
      switch (status) {
        case "Payment Pending":
          return "color: #ffd159;"; // Yellow color
        case "Payment Done":
          return "color: #38b525;"; // Green color
        case "Delivered":
          return "color: #32587a;"; // Dark blue color
        case "Approved":
          return "color: #38b525;"; // Green color
        case "Semi Ordered":
          return "color: #6d756b;"; // Gray color
        case "Ordered":
          return "color: #2381f3;"; // Blue color
        case "Semi Delivered":
          return "color: #6d756b;"; // Gray color

        default:
          return "";
      }
    },
    async handleStatusChangeOfPI(id, newStatus) {
      try {
        const response = await axios.put(`${this.$apiEndPoint}/purchaseInvoice/invoiceStatus/${id}`, {
          invoiceStatus: newStatus,
        });

        if (response.status === 200) {
          console.log("Status updated successfully:", response.data);
          this.$toast(`Status updated to ${newStatus}`, "success");
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    openModalPI(PIId) {
      console.log(PIId);
      this.selectedPIId = PIId;
      console.log(PIId); // Set the selected lead ID when a lead is clicked
    },
    handleDownloadPO(id) {
      this.selectedPRId = id;
      // Ensure the modal is fully loaded before triggering download
      setTimeout(() => {
        this.$refs.PODetailsModal.downloadPDF();
      }, 1000); // Adjust the timeout as needed
    },
    openPOModal(leadId) {
      console.log(this.selectedPRId);
      this.selectedPRId = leadId;
      console.log(this.selectedPRId); // Set the selected lead ID when a lead is clicked
    },
    async sendProjectComment() {
      try {
        const payload = {
          entityId: this.VendorId,
          entityName: "Vendor",
          remark: this.remark,
        };
        this.projectComments.push(payload);

        await axios.post(`${this.$apiEndPoint}/procurementRemark`, payload);
        this.remark = "";

        this.fetchProjectComments();
      } catch (error) {
        this.$handleError(error);
      }
    },
    fetchProjectComments() {
      axios
        .get(`${this.$apiEndPoint}/procurementRemark/${this.VendorId}/Vendor/`)
        .then((response) => {
          this.projectComments = response.data; // Assuming the API returns the comments as an array under the key 'comments'
        })
        .catch((error) => {
          this.$handleError(error);
        });
    },
    goBack() {
      this.$router.go(-1); // Navigate back one step in the history stack
    },
    async saveVendorDetails() {
      console.log(this.VendorDetails);
      try {
        if (this.VendorDetails.id) {
          const response = await axios.put(
            `${this.$apiEndPoint}/vendor/${this.VendorId}`,
            this.VendorDetails
          );
          console.log("Update vendor :", response.data);
          this.$toast(`Vendor Data Updated  successfully.`, "success");
        } else {
          const response = await axios.post(
            `${this.$apiEndPoint}/vendor/`,
            this.VendorDetails
          );
          console.log("Created vendor :", response.data);
          this.$toast(` Vendor created successfully.`, "success");
          this.VendorId = response.data.id;
          this.VendorDetails.id = response.data.id;
          this.VendorDetails.vendorNumber = response.data.vendorNumber;
        }
      } catch (error) {
        this.$handleError(error);
      }
    },
    async saveBankDetails() {
      this.billingAddressData.entityId = this.VendorId;
      this.bankDetails.entityId = this.VendorId;
      console.log(this.bankDetails);
      console.log(this.billingAddressData);
      try {
        if (this.bankDetails.id) {
          // Prepare the promises for concurrent execution
          const addressRequests = [
            axios.put(
              `${this.$apiEndPoint}/address/${this.VendorId}/Vendor/billing`,
              this.billingAddressData
            ),
            axios.put(
              `${this.$apiEndPoint}/bankDetails/${this.VendorId}/Vendor`,
              this.bankDetails
            ),
          ];
          // Execute all address requests concurrently
          await Promise.all(addressRequests);
          this.$toast(` data Updated successfully:.`, "success");
        } else {
          const addressRequests = [
            axios.post(`${this.$apiEndPoint}/address/`, this.billingAddressData),
            axios.post(`${this.$apiEndPoint}/bankDetails/`, this.bankDetails),
          ];

          // Execute all address requests concurrently
          await Promise.all(addressRequests);
          this.$toast(`Bank and Address data Created successfully:.`, "success");
        }

        this.$emit("fetchData");
        // Reset the lead data and fetch updated data
      } catch (error) {
        this.$handleError(error);
        this.$emit("fetchData");
      }
    },
    async fetchVendorDetails(vendorId) {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/vendor/getById/${vendorId}`
        );
        this.VendorDetails = response.data;
        console.log(this.createVendor);
      } catch (error) {
        this.$handleError(error);
      }
    },
    closeRemarks() {
      this.showCustomerRemark = false;
    },
    toggleCustomerRemark() {
      this.showAddress = false;
      this.showBankdetails = false;
      this.otherDetails = false;
      this.showPurchaseOrder = false;
      this.showPurchaseInvoice = false;
      this.showAddProductList = false;
      this.showCustomerRemark = !this.showCustomerRemark;
    },

    closeShowAddProductList() {
      this.showPurchaseOrder = true;
      this.showAddProductList = !this.showAddProductList;
    },

    toggleaddPurchaseInvoice() {
      this.showPurchaseOrder = false;
      this.showAddProductList = !this.showAddProductList;
    },

    toggleAddress() {
      this.showCustomerRemark = false;
      this.showPurchaseOrder = false;
      this.showPurchaseInvoice = false;
      this.otherDetails = false;
      this.showAddProductList = false;
      this.showAddress = !this.showAddress;
      this.showBankdetails = false;
    },
    toggleOtherDetails() {
      this.showCustomerRemark = false;
      this.showPurchaseOrder = false;
      this.showPurchaseInvoice = false;
      this.showAddress = false;
      this.showAddProductList = false;
      this.otherDetails = !this.otherDetails;
      this.showBankdetails = false;
    },
    toggleVendorDetailsPage() {
      this.showBillingDetails = false;
      this.showPurchaseOrder = false;
      this.showRemark = false;
      this.showPurchaseInvoice = false;
      this.showVendorDetails = true;
    },
    toggleBillingDetailsPage() {
      this.fetchVendorAddress();
      this.fetchVendorBankDetails();
      this.showVendorDetails = false;
      this.showPurchaseOrder = false;
      this.showPurchaseInvoice = false;
      this.showRemark = false;
      this.showBillingDetails = true;
    },
    toggleRemarkPage() {
      this.fetchProjectComments();
      this.showVendorDetails = false;
      this.showBillingDetails = false;
      this.showPurchaseInvoice = false;
      this.showPurchaseOrder = false;
      this.showRemark = true;
    },
    togglePurchaseOrderListPage() {
      this.fetchpurchaseOrder();
      this.showVendorDetails = false;
      this.showBillingDetails = false;
      this.showPurchaseInvoice = false;
      this.showRemark = false;
      this.showPurchaseOrder = true;
    },
    toggleInvoicePage() {
      this.fetchpurchaseInvoice();
      this.showVendorDetails = false;
      this.showBillingDetails = false;
      this.showRemark = false;
      this.showPurchaseOrder = false;
      this.showPurchaseInvoice = true;
    },
    async fetchpurchaseInvoice() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/purchaseInvoice/getByVendorId/${this.VendorId}`
        );
        this.PurchaseInvoice = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },

    //create Vendor
    async fetchpurchaseOrder() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/PurchaseOrder/getByVendorId/${this.VendorId}`
        );
        this.PurchaseOrders = response.data;
        console.log(this.PurchaseOrders);
      } catch (error) {
        this.$handleError(error);
      }
    },

    async fetchVendorAddress() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/address/Vendor/${this.VendorId}`
        );
        this.billingAddressData = response.data[0];
      } catch (error) {
        this.$handleError(error);
      }
    },
    async fetchVendorBankDetails() {
      try {
        const response = await axios.get(
          `${this.$apiEndPoint}/bankDetails/byEntityId/${this.VendorId}/Vendor`
        );
        this.bankDetails = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
    //Create product
    async createProductList() {
      try {
        const response = await axios.post(
          `${this.$apiEndPoint}/productList/`,
          this.createProduct
        );
        console.log("Created product:", response.data);
        this.createProduct = {
          productName: "",
          description: "",
          HSNCode: "",
          category: "",
          subCategory: "",
          discount: "",
          GST: "",
          unitPrice: "",
          status: "",
        };
      } catch (error) {
        this.$handleError(error);
      }
    },

    //Fetch product list by vendorId
    async productList() {
      try {
        const response = await axios.get(`${this.$apiEndPoint}/productList/`);
        this.productListData = response.data;
      } catch (error) {
        this.$handleError(error);
      }
    },
  },
};
</script>

<style scoped>
.ImportExport {
  width: 35%;
}

#ImportIcon {
  width: 25px;
  margin-right: 30px;
}

.ProductListHeader {
  margin-left: 55%;
}

/*
.tableFormat tbody  tr td{
 border: 1px solid #ccc;
}*/

.table-wrapper .tableFormat tbody tr:not(:first-child):nth-child(even) {
  background-color: #f8f8f8;
}

.tableFormat tbody tr td {
  padding: 0px 8px;
  height: auto;
}

.tableFormat thead tr th {
  padding: 5px 8px;
  height: auto;
  background-color: #bcbfc6;
}

.tableFormat tbody tr {
  height: 92px;
}

.headerbelowcontent {
  width: 100%;
  height: 80%;
}

.RemarktextArea {
  background-color: transparent;
  border: 1px solid black;
  box-shadow: none;
  text-align: left;
  border-radius: 14px;
  width: 50%;
  height: 70%;
}

.addUploadSaveButton button {
  padding: 4% 15%;
  background-color: #6e92f7;
  border-radius: 10px;
  outline: none;
  border: none;
}

.remarksList {
  margin-left: 19%;
  display: flex;
  align-items: flex-start;
  /* Align items at the start of the cross axis */
}

.remarksList p {
  margin-left: 1%;
  margin-top: -1%;
  padding-bottom: 3%;
  width: 90%;
}

.remarksList p span {
  color: #bcbfc6;
}

#remarksListImage {
  width: 2%;
  height: auto;
  margin-right: 1%;
}

.RemarksContent {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  position: relative;
  margin-bottom: 15%;
}

.RemarksContent::-webkit-scrollbar {
  display: none;
}

.PurchaseInvoiceContent {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  position: relative;
}

.PurchaseInvoiceContent::-webkit-scrollbar {
  display: none;
}

.PurchaseOrdersContent {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  position: relative;
}

.PurchaseOrdersContent::-webkit-scrollbar {
  display: none;
}

.footer {
  position: fixed;
  bottom: 12px;
  border-radius: 0 0 15px 15px;
  width: calc(100% - 76px);
  background: white;
  box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
  /* Box shadow at the top */
  padding: 10px 0px;
  gap: 10px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.table-container {
  width: 100%;
  max-height: 76vh;
  overflow-y: auto;
  overflow-x: hidden;
  margin-top: 20px;
  padding: 0px 20px;
}

.table-container table {
  width: 100%;
  border-collapse: collapse;
}

.table-container thead {
  position: sticky;
  top: 0;
  background-color: #bcbfc6; /* Match the header color */
  z-index: 2; /* Ensure it stays above scrolling content */
}

.table-container th:first-child,
.table-container td:first-child {
  width: 50px;
}
.table-container th,
.table-container td {
  font-size: 14px;
  padding: 5px 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.table-container th {
  background-color: #bcbfc6; /* Header background color */
}

.table-container tbody {
  display: block;
  overflow-y: auto;
}

.table-container tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-container thead tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.table-container tbody tr:hover {
  background-color: #f5f5f5;
}

.table-container th:first-child {
  border-radius: 5px 0 0 5px;
}

.table-container th:last-child {
  border-radius: 0px 5px 5px 0px;
}

/* Action buttons hover effect */
.ActionImage img {
  padding: 5px;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.ActionImage img:hover {
  background-color: #868686;
  border-radius: 50%;
}

@media (max-width: 768px) {
  .footer {
    position: fixed;
    bottom: 0px;
    border-radius: 0 0 15px 15px;
    width: calc(100%);
    background: white;
    box-shadow: 0 -5px 10px rgba(0, 0, 0, 0.1);
    /* Box shadow at the top */
    padding: 10px;
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 10px;
  }

  .addPurchaseInvoiceButton {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
  }
}

.purchaseRequestImage {
  justify-content: space-between;
}

.EstimateContent {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  position: relative;
}

.EstimateContent::-webkit-scrollbar {
  display: none;
}

.EstimateContentSection {
  display: flex;
  flex-direction: column;
}

.PurchaseRequestContent {
  display: flex;
  flex-direction: column;
  overflow: scroll;
  position: relative;
}

.PurchaseRequestContent::-webkit-scrollbar {
  display: none;
}

.PurchaseRequestContentSection {
  display: flex;
  flex-direction: column;
}

.ProcurementProjectCustomerProfileThings .row.mb-3 {
  margin-bottom: 1rem;
}

.ProcurementProjectCustomerProfileThings label {
  display: flex;
  align-items: center;
  font-size: 14px;
}

.form-control {
  border: 1px solid #000000;
  height: 35px;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
  /* Remove default outline */
  box-shadow: none;
  /* Add a custom focus shadow */
}

.ProcurementProjectCustomerProfileThings .col-lg-5,
.ProcurementProjectCustomerProfileThings .col-lg-7 {
  display: flex;
  align-items: center;
}

.ProcurementCustomerDetailsHeader label {
  margin-left: 10px;
  font-size: 10px;
}

.ProcurementCustomerDetailsHeader input[type="checkbox"] {
  margin-left: 10px;
}

.ProcurementCustomerDetailsHeader {
  display: flex;
  align-items: center;
}

.ProcurementCustomerDetailsHeader .col-md-2,
.ProcurementCustomerDetailsHeader .col-md-3,
.ProcurementCustomerDetailsHeader .col-md-1,
.ProcurementCustomerDetailsHeader .col-md-4 {
  display: flex;
  align-items: center;
}

.ProcurementCustomerDetailsHeader h6 {
  margin-left: 2%;
}

.ProcurementComponent {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  letter-spacing: 2px;
  width: 100%;
  height: 100%;
  font-family: "Inter", sans-serif;
}

.ProcurementComponent .ProcurementProjectComponentHeader {
  display: flex;
  align-items: center;
  padding: 0.5% 0;
  position: sticky;
  box-shadow: 10px 10px 20px rgba(0, 0, 0, 0.1);
  overflow-x: scroll;
  z-index: 999;
  top: 0;
  background-color: #ffffff;
  font-family: "Inter", sans-serif;
}

.ProcurementComponent .ProcurementProjectComponentHeader::-webkit-scrollbar {
  display: none;
}

.ProcurementProjectHeader {
  display: flex;
  flex-direction: column;
  position: relative;
}

#ProcurementProjectHeaderImage {
  width: 45px;
  height: 45px;
  margin-left: 0.5%;
  display: flex;
}

.ProcurementProjectHeader .ProcurementProjectHeaderRow1 {
  display: flex;
  align-items: center;
  justify-content: left;
  margin-left: 3%;
  position: relative;
}

.ProcurementProjectHeader .ProcurementProjectHeaderRow1 input {
  display: flex;
  width: auto;
  padding: 1%;
  border: none;
  outline: none;
  color: black;
  font-size: 18px;
}

.ProcurementProjectHeader .ProcurementProjectHeaderRow1 img {
  display: flex;
  margin: 0 2% 0% 2%;
  width: 2%;
  cursor: pointer;
  position: relative;
}

.ProcurementProjectHeader .ProcurementProjectHeaderRow2 {
  display: flex;
  align-items: center;
}

.ProcurementProjectHeader .ProcurementProjectHeaderRow2 h6 {
  margin: 0 3%;
  white-space: nowrap;
  cursor: pointer;
  font-size: 14px;
}

.ProcurementProjectHeader .ProcurementProjectHeaderRow1 input:hover {
  outline: 1px solid #ccc;
  border-radius: 5px;
}

.ProcurementProjectHeaderUsers {
  margin-left: 32%;
  display: flex;
  margin-bottom: 20px;
}

.ProcurementProjectHeaderUsers img {
  width: 25px;
  height: 25px;
  align-items: center;
}

.ProcurementProjectHeaderUsers #PlusIconProcurementProject {
  margin-right: 40%;
  cursor: pointer;
  width: 20%;
  /* width: 1.2%;
  margin-left: 0.1%;
  align-items: center;
  position: absolute;
  vertical-align: center;
  margin-top: 0.5%;
  cursor: pointer; */
}

.headerbelowcontent::-webkit-scrollbar {
  display: none;
}

.ProcurementProjectCustomerDetails {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
}

.ProcurementProjectCustomerProfileThings {
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: left;
}

.ProcurementProjectCustomerDetailsSection {
  width: 49%;
  display: flex;
  flex-direction: column;
  margin: 1%;
}

.ProcurementProjectCustomerDetails .ProcurementProjectCustomerDetailsSections {
  display: flex;
  flex-direction: column;
}

.ProcurementProjectCustomerDetails .ProcurementProjectCustomerDetailsSections h5 {
  display: flex;
  margin: 1% 1%;
  color: #000000;
}
</style>
